import { Box, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { InputTextField } from "../../../../Inputs/InputTextField";
import { ExternalGovernanceSessionContext } from "../../../../../context/governanceContext/externalSessionContext";

const GetBillOTherSubjects = ({ values, otherSubjectsArray, setOtherSubjectsArray }) => {
   useEffect(() => {
      const valuesFromSocketKeys = Object.keys(values);
      const valuesFromSocket = valuesFromSocketKeys.filter((values) => values.includes("otherSubject"));
      if (otherSubjectsArray.length === 1 && valuesFromSocket.length === 0) return;
      const lastInSubjectArray = otherSubjectsArray[otherSubjectsArray.length - 1];
      if (
         valuesFromSocket.length !== otherSubjectsArray.length &&
         valuesFromSocket.length !== otherSubjectsArray[otherSubjectsArray.length - 1]
      ) {
         setOtherSubjectsArray((current) => [...current, lastInSubjectArray]);
      }
   }, [values]);
};

export const BillOtherSubjectsExternal = () => {
   const { colors, governingBody, usersOnline } = useContext(ExternalGovernanceSessionContext);
   const [otherSubjectsArray, setOtherSubjectsArray] = useState([0]);

   const { values } = useFormikContext();
   GetBillOTherSubjects({ values, otherSubjectsArray, setOtherSubjectsArray });
   const handleClick = (e, index: number) => {
      if (index + 1 === otherSubjectsArray.length && e.key === "Enter" && values["otherSubject" + index]) {
         setOtherSubjectsArray((current) => [...current, index + 1]);
      }
   };

   const allMembersOnline = useMemo(() => {
      if (!Array.isArray(governingBody?.users) || !Array.isArray(usersOnline)) return false;
      const onlineSet = new Set(usersOnline);
      return governingBody.users.every((user) => onlineSet.has(user.user));
   }, [governingBody?.users, usersOnline]);

   return (
      allMembersOnline && (
         <Box>
            <Typography
               sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  borderBottom: 3,
                  borderColor: colors.secondary,
                  textAlign: "center",
               }}
            >
               OTROS ASUNTOS
            </Typography>
            <Stack rowGap={0.4}>
               {otherSubjectsArray.map((_otherSubject, index) => (
                  <Box
                     key={"otherSubjects" + index}
                     sx={{ display: "flex", bgcolor: "#EFEFEF", px: 2, gap: 3, alignItems: "center" }}
                     onKeyDown={(e) => handleClick(e, index)}
                  >
                     <Typography sx={{ fontWeight: 600, fontSize: 14, textAlign: "right" }}>{index + 1}.</Typography>
                     <InputTextField
                        id={"otherSubject" + index}
                        name={"otherSubject" + index}
                        type={"text"}
                        unstyled
                        placeholder="[ASUNTOS VISTOS QUE NO ESTABAN CONTEMPLADOS EN EL ORDEN DEL DÍA]"
                        fullWidth
                        sx={{ my: 0, fontSize: 12, ".Mui-disabled": { "-webkit-text-fill-color": "black !important" } }}
                        disabled={true}
                     />
                  </Box>
               ))}
            </Stack>
         </Box>
      )
   );
};
