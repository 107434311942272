import { Typography, Stack, Box, Button, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import { InputCheckBox } from "../../Inputs/InputCheckBox";
import { Formik, FormikProps } from "formik";
import { Form } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { UserContext } from "../../../context/userContext";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";
import { legalario } from "../../../lib/Legalario/legalario";
import { uploadFileToS3, UseGetPresignedURLUpload } from "../../../lib/s3Client";
import { base64ToBlob, extractBase64Data, getMimeType } from "../../../const/globalConst";

export const SignLegalarioModal = ({ setOpenModalToSign, session }) => {
   const [alertFrom, setAlertFrom] = useState(false);
   const { user } = useContext(UserContext);
   const { setAlreadySign, alreadySign, socket, valuesFromBill, fetchSignArrayList } =
      useContext(GovernanceSessionContext);

   const userCommitments = valuesFromBill["commitmentsArray"].filter((commitments) =>
      commitments.users.some((userInCommitment) => userInCommitment._id === user.id)
   );

   const commitments = userCommitments.map((commitment) => commitment.activity);

   const handleSubmit = async (values) => {
      let allCommitmentsAccepted = true;
      commitments.forEach((commitment, index) => {
         if (values["commitmentSign" + index] === false) {
            allCommitmentsAccepted = false;
         }
      });
      if (!allCommitmentsAccepted) setAlertFrom(true);
      else {
         const legalarioSDK = legalario();

         const signerKeyId = Object.keys(valuesFromBill).find((key) => key === `${user.id}-signerId`);
         const signerId = valuesFromBill[signerKeyId];
         legalarioSDK.signature({
            signerId: signerId,
            callbacks: {
               onFinish: async (data) => {
                  const mimeType = getMimeType(data.documents[0]?.base64);
                  const base64Data = extractBase64Data(data.documents[0]?.base64);
                  const blob = base64ToBlob(base64Data, mimeType);

                  const urlReceipt1 = await UseGetPresignedURLUpload({
                     bucket: "files-lecosy",
                     folder: `gc/companies/${session.company}/governing-body/sessions/${session._id}`,
                     name: `sign-${user.id}.png`,
                  });

                  await uploadFileToS3(urlReceipt1.data, blob);

                  const arrayList = await fetchSignArrayList();

                  socket.emit("verify-sign", { sessionId: session._id, signArray: arrayList });
                  setAlreadySign(true);
                  setOpenModalToSign(false);
               },
            },
         });
      }
   };

   let initialValues = {};

   commitments.forEach((commitment, index) => {
      initialValues = { ...initialValues, ["commitmentSign" + index]: false };
   });

   return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
         {(formProps: FormikProps<any>) => (
            <Form>
               <Box
                  sx={{
                     py: 1,
                     bgcolor: "#152c44",
                     borderTopLeftRadius: 15,
                     borderTopRightRadius: 15,
                     display: "flex",
                     justifyContent: "space-between",
                     alignItems: "center",
                  }}
               >
                  <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>Firmar minuta</Typography>
                  {alreadySign && (
                     <IconButton
                        sx={{ mr: 2 }}
                        onClick={() => {
                           setOpenModalToSign(false);
                        }}
                     >
                        <ClearIcon sx={{ color: "white" }} />
                     </IconButton>
                  )}
               </Box>
               <Stack
                  spacing={3}
                  sx={{
                     bgcolor: "background.paper",
                     py: 3,
                     px: 6,
                     borderBottomLeftRadius: 15,
                     borderBottomRightRadius: 15,
                  }}
               >
                  <Stack spacing={1}>
                     <Stack>
                        <Box sx={{ bgcolor: "#EBEBEB", py: 1 }}>
                           <Typography sx={{ fontWeight: 600, textAlign: "center" }}>
                              Acuerdos y compromisos establecidos
                           </Typography>
                        </Box>
                        <Stack spacing={1} sx={{ border: 1, borderColor: "#EBEBEB", py: 1 }}>
                           {commitments.map((commitment, index) => (
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                 <InputCheckBox
                                    id={"commitmentSign" + index}
                                    name={"commitmentSign" + index}
                                    label={""}
                                    sx={{ p: 0, ml: 3 }}
                                    size="medium"
                                 />
                                 <Typography>{commitment}</Typography>
                              </Box>
                           ))}
                        </Stack>
                     </Stack>
                     {alertFrom && (
                        <Typography sx={{ color: "red", fontSize: 12 }}>
                           Favor de aceptar todos los compromisos
                        </Typography>
                     )}
                     <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Button
                           type="submit"
                           variant="contained"
                           sx={{ bgcolor: "#2D4357", color: "white", alignSelf: "flex-end" }}
                           onClick={() => formProps.submitForm()}
                        >
                           Firma minuta
                        </Button>
                     </Box>
                  </Stack>
               </Stack>
            </Form>
         )}
      </Formik>
   );
};
