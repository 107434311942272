import { Navigate, Outlet, useLocation } from "react-router-dom";
import { NotificationBar } from "../../components/notificationsBar";
import { Box, Container } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../context/userContext";

export const MyLecosyScreen = () => {
   const {
      user,
      companySelected,
      isCompanyAdmin,
      isloadingCompanies,
      companies,
      groups,
      isLoadingUser,
      isImplementationUser,
   } = useContext(UserContext);
   const location = useLocation();

   if (
      location.pathname === `/mi-lecosy` &&
      !isLoadingUser &&
      !isCompanyAdmin &&
      !isloadingCompanies &&
      !isImplementationUser()
   ) {
      return <Navigate to={"/inicio"} />;
   } else if (location.pathname === `/mi-lecosy` && user.group) {
      return <Navigate to={"/mi-lecosy/grupo/inicio"} />;
   } else if (location.pathname === `/mi-lecosy` && companySelected._id !== "") {
      return <Navigate to={"/mi-lecosy/empresa/inicio"} />;
   } else if (location.pathname === `/mi-lecosy` && (companies.length > 1 || groups.length > 1)) {
      return <Navigate to={"/mi-lecosy/empresas"} />;
   }

   const currentPath = location.pathname;

   return (
      <Box p={2}>
         <NotificationBar
            content={<></>}
            head={
               currentPath === "/mi-lecosy/servicios/configuracion-documental"
                  ? "Configuración documental"
                  : "Mi Lecosy"
            }
         />
         <Container maxWidth="xl">
            <Outlet />
         </Container>
      </Box>
   );
};
