const legalarioValidation = (session: { company; type; assembly; group }, governanceTitle) => {
   const productionEnviroment = process.env.NODE_ENV === "production" ? true : false;

   return (
      productionEnviroment &&
      ["66514d0dc2c8e7bc389448e3", "66bcf203ffc60aa77d98f774", "66be4916ca22c07a547f0486"].includes(session.company) &&
      session.type !== "Sesión de acuerdos" &&
      (session.assembly || (!session.group && governanceTitle === "Consejo de administración"))
   );
};

export default legalarioValidation;
