import { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../context/snackBarContext";
import { FileWithDateInterface } from "../../types/BaseTypes";
import {
   getUrlS3,
   updateCompanyDetails,
   useDisableCompany,
   useDisableGroup,
   useUpdateCompanyPersonDetails,
   useUpdateGroup,
} from "../../lib/usersBEClient";
import { UserContext } from "../../context/userContext";
import { uploadFileToS3, UseGetPresignedURLUpload } from "../../lib/s3Client";
import {
   Box,
   Button,
   Collapse,
   Divider,
   IconButton,
   Popover,
   Stack,
   Toolbar,
   Tooltip,
   Typography,
} from "@mui/material";
import { ChevronRight, Close, MoreVert } from "@mui/icons-material";
import { Form, Formik, FormikProps } from "formik";
import { companyLegalSchema, groupSchema } from "../../lib/validations/inputSchemas";
import { InputTextField } from "../Inputs/InputTextField";
import { ChromePicker } from "react-color";
import { InputFile } from "../Inputs/InputFile";
import { InputImage } from "../Inputs/InputImage";
import { LoadingButton } from "../LoadingButton";
import { ConfirmationModal } from "../ConfirmationModal";
import LoginModal from "../LoginModal";

interface AddCompanyDrawerProps {
   open: boolean;
   setOpen: Function;
   companyInfo?: any;
   groupInfo?: any;
}

export const AddModifyCompanyDrawer = (props: AddCompanyDrawerProps) => {
   const [contractFile, setContractFile] = useState<FileWithDateInterface>();
   const [logo, setLogo] = useState<FileWithDateInterface>();
   const { showSnackBar } = useContext(SnackBarContext);
   const { groupSelected: group, refetch: refreshClientInfo, companySelected: clientInfo } = useContext(UserContext);
   const { mutate: updateMutateC, isLoading: isLoadingUpdate } = useUpdateCompanyPersonDetails();
   const { mutate: updateMutateG, isLoading: isLoadingUpdateG } = useUpdateGroup();
   const [isLoadingUpload, setIsLoadingUpload] = useState(false);
   const [openConfirmation, setOpenConfirmation] = useState(false);
   const [openLoginModal, setOpenLoginModal] = useState(false);
   const [primaryColor, setPrimaryColor] = useState<string>(
      props.groupInfo ? props.groupInfo.primaryColor || "#162c44" : props.companyInfo?.company_details?.primaryColor
   );
   const [secondaryColor, setSecondaryColor] = useState<string>(
      props.groupInfo ? props.groupInfo.secondaryColor || "#162c44" : props.companyInfo?.company_details?.secondaryColor
   );
   const [selectedColorPicker, setSelectedColorPicker] = useState("");
   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
   const open = Boolean(anchorEl);
   const popoverId = open ? "simple-popover" : undefined;
   const { mutate: disable } = useDisableCompany();
   const { mutate: disableGroup } = useDisableGroup();

   function handleOpenAddCompanyDrawer() {
      props.setOpen(!props.open);
   }

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedColorPicker(event.currentTarget.id);
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   useEffect(() => {
      if (props.groupInfo) {
         getLogo(group?._id);
         getContract(group?._id);
      } else {
         getLogo(clientInfo._id);
         getContract(clientInfo._id);
      }
      // eslint-disable-next-line
   }, [clientInfo]);

   const getLogo = async (id: string) => {
      if (props.groupInfo) {
         const logoUrl: string = await getUrlS3("images-lecosy", { folder: `group/${id}` }, "logo.png");
         await fetch(logoUrl).then(async (data) => {
            const newBlob = await data.blob();
            const file = new File([newBlob], "logo.png", { type: "image/png" });
            setLogo({ file: file, lastDateModified: new Date() });
         });
      } else {
         const logoUrl: string = await getUrlS3("images-lecosy", { folder: id }, "logo.png");
         await fetch(logoUrl).then(async (data) => {
            const newBlob = await data.blob();
            const file = new File([newBlob], "logo.png", { type: "image/png" });
            setLogo({ file: file, lastDateModified: new Date() });
         });
      }
   };
   const getContract = async (Id: string) => {
      if (props.groupInfo) {
         const contractUrl: string = await getUrlS3(
            "files-lecosy",
            { folder: `contracts/group/${Id}` },
            "contract.pdf"
         );
         await fetch(contractUrl).then(async (data) => {
            const newBlob = await data.blob();
            const file = new File([newBlob], "contrato.pdf", { type: "application/pdf" });
            setContractFile({ file: file, lastDateModified: new Date() });
         });
      } else {
         const contractUrl: string = await getUrlS3("files-lecosy", { folder: `contracts/${Id}` }, "contract.pdf");
         await fetch(contractUrl).then(async (data) => {
            const newBlob = await data.blob();
            const file = new File([newBlob], "contrato.pdf", { type: "application/pdf" });
            setContractFile({ file: file, lastDateModified: new Date() });
         });
      }
   };

   const handleChangeComplete = (color) => {
      if (selectedColorPicker === "color-picker-1") setPrimaryColor(color.hex);
      else setSecondaryColor(color.hex);
   };

   const disableCompany = async () => {
      if (props.groupInfo) {
         disableGroup(props.groupInfo._id, {
            onSuccess: () => {
               showSnackBar(
                  `Grupo empresarial ${!props.groupInfo.disabled ? "inhabilitada" : "habilitada"} con éxito`,
                  false
               );
               refreshClientInfo();
               props.setOpen(false);
            },
            onError: () => {
               showSnackBar(
                  `Error al ${!props.groupInfo.disabled ? "inhabilitar" : "habilitar"} el grupo empresarial`,
                  true
               );
            },
         });
      } else {
         disable(props.companyInfo._id, {
            onSuccess: () => {
               showSnackBar(`Empresa ${!props.companyInfo.disabled ? "inhabilitada" : "habilitada"} con éxito`, false);
               refreshClientInfo();
               props.setOpen(false);
            },
            onError: () => {
               showSnackBar(`Error al ${!props.companyInfo.disabled ? "inhabilitar" : "habilitar"} la empresa`, true);
            },
         });
      }
   };

   async function handleSubmit(values) {
      if (!contractFile || !logo) {
         return showSnackBar("Favor de adjuntar archivos correspondientes", true);
      }
      if (props.groupInfo) {
         updateMutateG(
            {
               name: values.name,
               phoneNumber: values.phone,
               _id: group._id,
               primaryColor: primaryColor,
               secondaryColor: secondaryColor,
            },
            {
               onSuccess: async () => {
                  await handleUploadFiles(group._id);
                  showSnackBar("Datos actualizados correctamente", false);
                  setTimeout(() => {
                     refreshClientInfo();
                     props.setOpen(false);
                  }, 1000);
               },
               onError: () => {
                  showSnackBar("Error al modificar los datos", true);
               },
            }
         );
      } else {
         updateMutateC(
            {
               person_details: {
                  comercialName: values.comercialName,
                  businessName: values.businessName,
                  activity: values.activity,
               },
               invoice_details: {
                  phone: values.phone,
               },
               companyId: clientInfo._id,
               company_details: {
                  id: clientInfo.company_details._id,
                  primaryColor: primaryColor,
                  secondaryColor: secondaryColor,
               },
            },
            {
               onSuccess: async () => {
                  await updateCompanyDetails(clientInfo._id, {
                     businessName: values.businessName,
                     primaryColor,
                     secondaryColor,
                  });
                  await handleUploadFiles(clientInfo._id);
                  showSnackBar("Datos actualizados correctamente", false);
                  setTimeout(() => {
                     refreshClientInfo();
                     props.setOpen(false);
                  }, 1000);
               },
               onError: () => {
                  showSnackBar("Error al modificar los datos", true);
               },
            }
         );
      }
   }

   const handleUploadFiles = async (id: string) => {
      if (props.groupInfo) {
         setIsLoadingUpload(true);
         const urlContract = await UseGetPresignedURLUpload({
            bucket: "files-lecosy",
            folder: `contracts/group/${id}`,
            name: "contract.pdf",
         });
         const urlLogo = await UseGetPresignedURLUpload({
            bucket: "images-lecosy",
            folder: `group/${id}`,
            name: "logo.png",
         });
         await uploadFileToS3(urlLogo.data, logo.file);
         await uploadFileToS3(urlContract.data, contractFile.file);
         setIsLoadingUpload(false);
      } else {
         setIsLoadingUpload(true);
         const urlContract = await UseGetPresignedURLUpload({
            bucket: "files-lecosy",
            folder: `contracts/${id}`,
            name: "contract.pdf",
         });
         const urlLogo = await UseGetPresignedURLUpload({
            bucket: "images-lecosy",
            folder: id,
            name: "logo.png",
         });
         await uploadFileToS3(urlLogo.data, logo.file);
         await uploadFileToS3(urlContract.data, contractFile.file);
         setIsLoadingUpload(false);
      }
   };

   return (
      <Box display="flex" maxWidth={450} width={420} overflow={"auto"}>
         <Box
            height="100%"
            position="fixed"
            display="flex"
            alignItems="center"
            onClick={handleOpenAddCompanyDrawer}
            sx={{
               "&:hover": {
                  bgcolor: "#2d435710",
               },
            }}
         >
            <ChevronRight fontSize="small" />
         </Box>
         <Box flex={1} ml={2.5} mt={1}>
            <Toolbar />
            <Box
               px={2}
               pt={1}
               bgcolor="white"
               width="100%"
               zIndex={1}
               sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
            >
               <Typography variant="h5" py={2}>
                  {props.groupInfo ? "Modificar grupo empresarial" : "Modificar empresa"}
               </Typography>
            </Box>

            <Divider sx={{ mr: 1 }} />
            <Box pr={1} pl={0.5}>
               <Formik
                  initialValues={
                     props.groupInfo
                        ? {
                             name: props.groupInfo.name,
                             phoneNumber: props.groupInfo.phoneNumber,
                             primaryColor: props.groupInfo.primaryColor || "#162c44",
                             secondaryColor: props.groupInfo.secondaryColor || "#162c44",
                          }
                        : {
                             businessName: props.companyInfo.person_details.businessName,
                             comercialName: props.companyInfo.person_details.comercialName,
                             activity: props.companyInfo.person_details.activity,
                             phone: props.companyInfo.invoice_details ? props.companyInfo.invoice_details.phone : "",
                             primaryColor: props.companyInfo.company_details.primaryColor,
                             secondaryColor: props.companyInfo.company_details.secondaryColor,
                          }
                  }
                  onSubmit={async (values) => {
                     try {
                        await handleSubmit(values);
                     } catch (error) {
                        console.log(error);
                     }
                  }}
                  validationSchema={props.groupInfo ? groupSchema : companyLegalSchema}
               >
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        {props.companyInfo && (
                           <Box m={2}>
                              <InputTextField
                                 variant="standard"
                                 multiline={true}
                                 size="small"
                                 name="businessName"
                                 id="businessName"
                                 type="text"
                                 label="Denominación o razón social"
                                 fullWidth={true}
                                 value={formProps.values.businessName}
                              />
                           </Box>
                        )}
                        <Box m={2}>
                           <InputTextField
                              variant="standard"
                              multiline={true}
                              size="small"
                              name={props.groupInfo ? "name" : "comercialName"}
                              id={props.groupInfo ? "name" : "comercialName"}
                              type="text"
                              label={props.groupInfo ? "Denominación o razón social" : "Nombre comercial"}
                              fullWidth={true}
                              value={props.groupInfo ? formProps.values.name : formProps.values.comercialName}
                           />
                        </Box>
                        {!props.groupInfo && (
                           <Box m={2}>
                              <InputTextField
                                 variant="standard"
                                 multiline={true}
                                 size="small"
                                 name="activity"
                                 id="activity"
                                 type="text"
                                 label="Actividad y/o giro de la empresa"
                                 fullWidth={true}
                                 value={formProps.values.activity}
                              />
                           </Box>
                        )}
                        <Stack m={2} spacing={2}>
                           <InputTextField
                              variant="standard"
                              size="small"
                              name={"phone"}
                              id={"phone"}
                              type="text"
                              label="Teléfono"
                              fullWidth={true}
                              disabled={!props.companyInfo?.invoice_details}
                              value={props.groupInfo ? formProps.values.phoneNumber : formProps.values.phone}
                           />
                           <Box>
                              <Typography>Color corporativo:</Typography>

                              <Box display={"flex"} sx={{ columnGap: 2, mt: 0.5 }}>
                                 <Tooltip title={"Color primario"}>
                                    <Box display={"flex"} sx={{ columnGap: 1, alignItems: "center" }}>
                                       <Button
                                          id="color-picker-1"
                                          aria-describedby={popoverId}
                                          sx={{
                                             borderRadius: 1,
                                             width: 35,
                                             height: 20,
                                             bgcolor: primaryColor,
                                             ":hover": { bgcolor: primaryColor },
                                          }}
                                          onClick={handleClick}
                                       />
                                       <Typography>{primaryColor}</Typography>
                                    </Box>
                                 </Tooltip>

                                 <Tooltip title={"Color secundario"}>
                                    <Box display={"flex"} sx={{ columnGap: 1, alignItems: "center" }}>
                                       <Button
                                          id="color-picker-2"
                                          aria-describedby={popoverId}
                                          sx={{
                                             borderRadius: 1,
                                             maxWidth: 35,
                                             height: 20,
                                             bgcolor: secondaryColor,
                                             ":hover": { bgcolor: secondaryColor },
                                          }}
                                          onClick={handleClick}
                                       />
                                       <Typography>{secondaryColor}</Typography>
                                    </Box>
                                 </Tooltip>
                              </Box>
                           </Box>
                        </Stack>
                        <Popover
                           id={popoverId}
                           open={open}
                           anchorEl={anchorEl}
                           onClose={handleClose}
                           anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                           }}
                        >
                           <ChromePicker
                              color={selectedColorPicker === "color-picker-1" ? primaryColor : secondaryColor}
                              onChangeComplete={handleChangeComplete}
                              disableAlpha={true}
                           />
                        </Popover>

                        <Box py={1} mx={2}>
                           <Box display="flex" flexDirection="column" alignItems="flex-start">
                              <Typography mb={1}>Contrato:</Typography>
                              <InputFile file={contractFile?.file} setFile={setContractFile} keyName="contractFile" />
                           </Box>
                        </Box>
                        <Box py={1}>
                           <Box display="flex" flexDirection="column" alignItems="flex-start" mx={3}>
                              <Typography mb={1}>{`Logo ${
                                 props.groupInfo ? "del grupo empresarial" : "de la empresa:"
                              } `}</Typography>
                              <InputImage image={logo?.file} setImage={setLogo} keyName="logo" />
                           </Box>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box
                           sx={{ position: "relative", display: "flex", justifyContent: "space-between", mx: 2, mt: 3 }}
                        >
                           <Box>
                              <Button
                                 variant="outlined"
                                 onClick={() => {
                                    props.setOpen(false);
                                 }}
                              >
                                 Cancelar
                              </Button>
                           </Box>
                           <Box>
                              <LoadingButton
                                 label={"Guardar"}
                                 isLoading={isLoadingUpdate || isLoadingUpload || isLoadingUpdateG}
                              />
                           </Box>
                        </Box>
                     </Form>
                  )}
               </Formik>
               <Divider sx={{ my: 2 }} />
               <Typography color="red">
                  {" "}
                  {`Inhabilitar / habilitar ${props.groupInfo ? "grupo empresarial" : "empresa"}`}
               </Typography>
               <Box sx={{ border: 1, mt: 2, borderColor: "#DADADA", borderRadius: 1, p: 1 }}>
                  <Box sx={{ p: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                     <Typography fontSize={14}>
                        {`Para inhabilitar o habilitar ${
                           props.groupInfo ? "el grupo empresarial" : "la empresa"
                        } se requiere permisos de administrador.`}
                     </Typography>
                     <Button
                        variant="outlined"
                        sx={{ color: "red", mt: 2 }}
                        onClick={() => {
                           setOpenConfirmation(true);
                        }}
                     >
                        {props.groupInfo
                           ? props.groupInfo.disabled
                              ? "Habilitar grupo empresarial"
                              : "Inhabilitar grupo empresarial"
                           : !props.companyInfo?.disabled
                           ? "Inhabilitar empresa"
                           : "Habilitar empresa"}
                     </Button>
                  </Box>
               </Box>
            </Box>
         </Box>
         <ConfirmationModal
            open={openConfirmation}
            setOpen={setOpenConfirmation}
            title={
               props.groupInfo
                  ? props.groupInfo.disabled
                     ? "Habilitar grupo"
                     : "Inhabilitar grupo"
                  : !props.companyInfo?.disabled
                  ? "Inhabilitar empresa"
                  : "Habilitar empresa"
            }
            body={`¿Está seguro de ${
               props.groupInfo
                  ? props.groupInfo.disabled
                     ? "habilitar"
                     : "inhabilitar"
                  : !props.companyInfo?.disabled
                  ? "inhabilitar"
                  : "habilitar"
            } ${props.groupInfo ? "el grupo" : "la empresa"}?`}
            setOpenLogin={setOpenLoginModal}
            onConfirm={() => {}}
         />

         <LoginModal
            open={openLoginModal}
            setOpen={setOpenLoginModal}
            onConfirm={() => {
               disableCompany();
            }}
         />
      </Box>
   );
};
