import { useCallback, useState, useEffect } from "react";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { pdfjs, Document, Page } from "react-pdf";
import JSZip from "jszip";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./Styles.css";
import type { PDFDocumentProxy } from "pdfjs-dist";

// Use the CDN for the worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
   cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
   cMapPacked: true,
   standardFontDataUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/standard_fonts/`,
};

const resizeObserverOptions = {};

const maxWidth = 800;

export default function ZipPDFViewer(props: {
   zipUrl: string;
   zoom?: number;
   currentFileIndex: number;
   setCurrentFileIndex: Function;
   pdfFiles: any[];
   setPdfFiles: Function;
}) {
   const { zoom = 1, zipUrl, currentFileIndex, setCurrentFileIndex, pdfFiles, setPdfFiles } = props;
   const [numPages, setNumPages] = useState<number>();
   const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
   const [containerWidth, setContainerWidth] = useState<number>();

   const onResize = useCallback<ResizeObserverCallback>((entries) => {
      const [entry] = entries;
      if (entry) setContainerWidth(entry.contentRect.width);
   }, []);

   useResizeObserver(containerRef, resizeObserverOptions, onResize);

   useEffect(() => {
      async function fetchAndExtractPDFs() {
         try {
            const response = await fetch(zipUrl);
            if (!response.ok) throw new Error(`Failed to fetch ZIP file: ${response.statusText}`);
            const zipData = await response.blob();
            const zip = new JSZip();
            const loadedZip = await zip.loadAsync(zipData);
            const pdfFilesArray: string[] = [];

            for (const fileName of Object.keys(loadedZip.files)) {
               if (fileName.endsWith(".pdf")) {
                  const fileData = await loadedZip.files[fileName].async("blob");
                  const fileURL = URL.createObjectURL(fileData);
                  pdfFilesArray.push(fileURL);
               }
            }
            setPdfFiles(pdfFilesArray);
            setCurrentFileIndex(0);
         } catch (error) {
            console.error("Error extracting PDFs from ZIP:", error);
         }
      }
      if (zipUrl) fetchAndExtractPDFs();
   }, [zipUrl]);

   function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
      setNumPages(nextNumPages);
   }

   return (
      <div className="Example">
         <div className="Example__container">
            <div className="Example__container__document" ref={setContainerRef}>
               <Document
                  file={pdfFiles[currentFileIndex]}
                  onLoadSuccess={onDocumentLoadSuccess as any}
                  onLoadError={(error) => console.error("Error loading PDF:", error)}
                  options={options}
                  error={<div style={{ textAlign: "center" }}>No se pudo cargar el archivo</div>}
                  loading={<div style={{ textAlign: "center" }}>Cargando documento...</div>}
               >
                  {Array.from(new Array(numPages), (el, index) => (
                     <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={containerWidth ? Math.min(containerWidth * zoom, maxWidth * zoom) : maxWidth * zoom}
                     />
                  ))}
               </Document>
            </div>
         </div>
      </div>
   );
}
