import React, { createContext, useEffect, useCallback, useReducer, useMemo } from "react";
import {
   GetGroupById,
   GetManyGroupsById,
   LogoutUser,
   base_url,
   getAdminCompanyById,
   getAllUserRoles,
   getClientModules,
   getCompaniesById,
   getCompanyById,
   getUrlS3,
} from "../lib/usersBEClient";
import { io } from "socket.io-client";
import { Companies, Group } from "../types/BaseTypes";
import { fetchRoles } from "../const/globalConst";
import { getGoverningBodyNewCharges } from "../lib/gobCorpBEClient";

// Definir tipos de datos
type AppBarLogoInterface = {
   url: string;
   name: string;
};

interface UserC {
   auth: boolean;
   firstName: string;
   lastName: string;
   email: string;
   phoneNumber: string;
   id: string;
   uid: string;
   validPwd: boolean;
   createdAt: string;
   group: any;
   resources: any[];
   modules: any[];
   companies: any[];
   role: any[];
   cordinatorGroups?: any[];
}

interface NewUserContextType {
   user: UserC;
   setUser: Function;
   isLoadingUser: boolean;
   setLoadingUser: Function;
   companies: any[];
   groups: Group[];
   notifications: any[];
   companySelected: Companies;
   setCompanySelected: Function;
   groupSelected: Group;
   setGroupSelected: Function;
   companySelectedUserP: Companies;
   setcompanySelectedUserP: Function;
   userModules: any;
   isLoadingCompany: boolean;
   adminCompanies: any[];
   socket: any;
   selectedResources: {
      name: string;
      _id: string;
      permission: number;
   };
   setSelectedResources: Function;
   isloadingCompanies: boolean;
   isCompanyAdmin: boolean;
   setIsCompanyAdmin: Function;
   setIsLoadingCompany: Function;
   roles: any[];
   resources: any[];
   logoutUser: Function;
   appBarLogo: AppBarLogoInterface;
   setUserProfilePicture: Function;
   userProfilePicture: string;
   editingSection: string[];
   setEditingSection: Function;
   path: string | number;
   setPath: Function;
   handleUpdateEditingSection: Function;
   isImplementationUser: Function;
   verifyAccess: (section: string | string[], module?: boolean) => boolean;
   openCompanySelector: boolean;
   setOpenCompanySelector: Function;
   getCompanyDetails: (company: string) => void;
   loggedIn: boolean;
   setLoggedIn: Function;
   isAuthenticated: boolean;
   setIsAuthenticated: Function;
   isLoadingModules: boolean;
   getPendingCharges: Function;
   pendingCharges: any[];
   skipCharges: boolean;
   setSkipCharges: Function;
   isOffice: Boolean;
   isOfficeAdmin: Boolean;
   refetch: Function;
   getGroupDetails: Function;
}

interface SelectedResources {
   name: string;
   _id: string;
   permission: number;
}

interface UserState {
   user: UserC;
   isLoadingUser: boolean;
   companies: any[];
   groups: Group[];
   notifications: any[];
   companySelected: Companies;
   groupSelected: Group;
   companySelectedUserP: Companies;
   userModules: string[];
   isLoadingCompany: boolean;
   adminCompanies: any[];
   socket: any;
   selectedResources: SelectedResources;
   isloadingCompanies: boolean;
   isCompanyAdmin: boolean;
   roles: any[];
   resources: any[];
   appBarLogo: AppBarLogoInterface | null;
   userProfilePicture: string;
   editingSection: string[];
   path: string | number;
   openCompanySelector: boolean;
   loggedIn: boolean;
   isLoadingModules: boolean;
   error: string | null;
   isAuthenticated: boolean;
   pendingCharges: any[];
   skipCharges: boolean;
   isOfficeAdmin: Boolean;
}

// Estado inicial
const initialState: UserState = {
   user: {
      auth: false,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      id: "",
      uid: "",
      validPwd: false,
      createdAt: "",
      group: null,
      companies: [],
      modules: [],
      resources: [],
      role: [],
   },
   isLoadingUser: false,
   companies: [],
   groups: [],
   notifications: [],
   companySelected: null,
   groupSelected: null,
   companySelectedUserP: null,
   userModules: null,
   isLoadingCompany: false,
   adminCompanies: [],
   socket: null,
   selectedResources: null,
   isloadingCompanies: false,
   isCompanyAdmin: null,
   roles: [],
   resources: [],
   userProfilePicture: "",
   editingSection: [],
   path: "",
   openCompanySelector: false,
   loggedIn: false,
   isLoadingModules: false,
   error: "",
   isAuthenticated: false,
   appBarLogo: null,
   pendingCharges: [],
   skipCharges: false,
   isOfficeAdmin: false,
};

// Reducer para manejar los estados
function userReducer(state: UserState, action) {
   switch (action.type) {
      case "SET_INITIAL_STATE":
         return { ...action.payload };
      case "SET_USER":
         return { ...state, user: action.payload };
      case "SET_LOADING_USER":
         return { ...state, isLoadingUser: action.payload };
      case "SET_COMPANIES":
         return { ...state, companies: action.payload };
      case "SET_GROUPS":
         return { ...state, groups: action.payload };
      case "SET_NOTIFICATIONS":
         return { ...state, notifications: action.payload };
      case "SET_COMPANY_SELECTED":
         return { ...state, companySelected: action.payload };
      case "SET_COMPANY_SELECTED_USERP":
         return { ...state, companySelectedUserP: action.payload };
      case "SET_USER_MODULES":
         return { ...state, userModules: action.payload };
      case "SET_LOADING_COMPANY":
         return { ...state, isLoadingCompany: action.payload };
      case "SET_ADMIN_COMPANIES":
         return { ...state, adminCompanies: action.payload };
      case "SET_SOCKET":
         return { ...state, socket: action.payload };
      case "SET_SELECTED_RESOURCES":
         return { ...state, selectedResources: action.payload };
      case "SET_LOADING_COMPANIES":
         return { ...state, isloadingCompanies: action.payload };
      case "SET_COMPANY_ADMIN":
         return { ...state, isCompanyAdmin: action.payload };
      case "SET_ROLES":
         return { ...state, roles: action.payload };
      case "SET_RESOURCES":
         return { ...state, resources: action.payload };
      case "SET_USER_PROFILE_PICTURE":
         return { ...state, userProfilePicture: action.payload };
      case "SET_EDITING_SECTION":
         return { ...state, editingSection: action.payload };
      case "SET_PATH":
         return { ...state, path: action.payload };
      case "SET_OPEN_COMPANY_SELECTOR":
         return { ...state, openCompanySelector: action.payload };
      case "SET_LOGGED_IN":
         return { ...state, loggedIn: action.payload };
      case "SET_LOADING_MODULES":
         return { ...state, isLoadingModules: action.payload };
      case "SET_ERROR":
         return { ...state, error: action.payload };
      case "SET_APP_BAR_LOGO":
         return { ...state, appBarLogo: action.payload };
      case "SET_AUTHENTICATED":
         return { ...state, isAuthenticated: action.payload };
      case "SET_PENDING_CHARGES":
         return { ...state, pendingCharges: action.payload };
      case "SET_SKIP_CHARGES":
         return { ...state, skipCharges: action.payload };
      case "SET_IS_OFFICE_ADMIN":
         return { ...state, isOfficeAdmin: action.payload };
      case "SET_GROUP_SELECTED":
         return { ...state, groupSelected: action.payload };
      default:
         return state;
   }
}

// Crear contexto
export const UserContext = createContext<NewUserContextType | undefined>(undefined);

export const UserProvider = ({ children }) => {
   const [state, dispatch] = useReducer(userReducer, initialState);

   //#region Dispatch

   const setSkipCharges = useCallback((state: UserState) => {
      dispatch({ type: "SET_SKIP_CHARGES", payload: state });
   }, []);

   const setInitialState = useCallback((state: UserState) => {
      dispatch({ type: "SET_INITIAL_STATE", payload: state });
   }, []);

   const setUser = useCallback((user: UserC) => {
      dispatch({ type: "SET_USER", payload: user });
   }, []);

   const setLoadingUser = useCallback((isLoading: boolean) => {
      dispatch({ type: "SET_LOADING_USER", payload: isLoading });
   }, []);

   const setIsLoadingCompany = useCallback((isLoading: boolean) => {
      dispatch({ type: "SET_LOADING_COMPANY", payload: isLoading });
   }, []);

   const setCompanies = useCallback((companies: Companies[]) => {
      dispatch({ type: "SET_COMPANIES", payload: companies });
   }, []);

   const setGroups = useCallback((groups: Group[]) => {
      dispatch({ type: "SET_GROUPS", payload: groups });
   }, []);

   const setAdminCompanies = useCallback((companies: Companies[]) => {
      dispatch({ type: "SET_ADMIN_COMPANIES", payload: companies });
   }, []);

   const setIsLoadingCompanies = useCallback((isLoading: boolean) => {
      dispatch({ type: "SET_LOADING_COMPANIES", payload: isLoading });
   }, []);

   const setCompanySelected = useCallback((company: Companies) => {
      dispatch({ type: "SET_COMPANY_SELECTED", payload: company });
   }, []);

   const setGroupSelected = useCallback((company: Companies) => {
      dispatch({ type: "SET_GROUP_SELECTED", payload: company });
   }, []);

   const setcompanySelectedUserP = useCallback((company: Companies) => {
      dispatch({ type: "SET_COMPANY_SELECTED_USERP", payload: company });
   }, []);

   const setSelectedResources = useCallback((selectedResources: SelectedResources) => {
      dispatch({ type: "SET_SELECTED_RESOURCES", payload: selectedResources });
   }, []);

   const setIsCompanyAdmin = useCallback((isCompanyAdmin: boolean) => {
      dispatch({ type: "SET_COMPANY_ADMIN", payload: isCompanyAdmin });
   }, []);

   const setUserProfilePicture = useCallback((userProfilePicture: string) => {
      dispatch({ type: "SET_USER_PROFILE_PICTURE", payload: userProfilePicture });
   }, []);

   const setEditingSection = useCallback((editingSection: string[]) => {
      dispatch({ type: "SET_EDITING_SECTION", payload: editingSection });
   }, []);

   const setPath = useCallback((path: string) => {
      dispatch({ type: "SET_PATH", payload: path });
   }, []);

   const setOpenCompanySelector = useCallback((openCompany: boolean) => {
      dispatch({ type: "SET_OPEN_COMPANY_SELECTOR", payload: openCompany });
   }, []);

   const setLoggedIn = useCallback((loggedIn: boolean) => {
      dispatch({ type: "SET_LOGGED_IN", payload: loggedIn });
   }, []);

   const setError = useCallback((error: string) => {
      dispatch({ type: "SET_ERROR", payload: error });
   }, []);

   const setSocket = useCallback((socket: any) => {
      dispatch({ type: "SET_SOCKET", payload: socket });
   }, []);

   const setNotifications = useCallback((notifications: any[]) => {
      dispatch({ type: "SET_NOTIFICATIONS", payload: notifications });
   }, []);

   const setAppBarLogo = useCallback((appBarLogo: AppBarLogoInterface) => {
      dispatch({ type: "SET_APP_BAR_LOGO", payload: appBarLogo });
   }, []);

   const setUserModules = useCallback((modules: string[]) => {
      dispatch({ type: "SET_USER_MODULES", payload: modules });
   }, []);

   const setRoles = useCallback((roles: any[]) => {
      dispatch({ type: "SET_ROLES", payload: roles });
   }, []);

   const setResources = useCallback((resources: any[]) => {
      dispatch({ type: "SET_RESOURCES", payload: resources });
   }, []);

   const setIsLoadingModules = useCallback((isLoadingModules: boolean) => {
      dispatch({ type: "SET_LOADING_MODULES", payload: isLoadingModules });
   }, []);

   const setIsAuthenticated = useCallback((auth: boolean) => {
      dispatch({ type: "SET_AUTHENTICATED", payload: auth });
   }, []);

   const setPendingCharges = useCallback((charges: any[]) => {
      dispatch({ type: "SET_PENDING_CHARGES", payload: charges });
   }, []);

   const setIsOfficeAdmin = useCallback((isOfficeAdmin: Boolean) => {
      dispatch({ type: "SET_IS_OFFICE_ADMIN", payload: isOfficeAdmin });
   }, []);

   //#end region

   //#region Functions

   const logoutUser = useCallback(async () => {
      await LogoutUser(state.user.email);
      setInitialState(initialState);
   }, [setUser, state.user.email]);

   const getCompanyDetails = async (companyId: string) => {
      setIsLoadingCompany(true);
      try {
         const response = await getCompanyById(companyId);
         setCompanySelected(response.data);
      } catch (error) {
         console.error("Error fetching company details:", error);
      } finally {
         setIsLoadingCompany(false);
      }
   };

   const getGroupDetails = async (companyId: string) => {
      setIsLoadingCompany(true);
      try {
         const response = await GetGroupById(companyId);
         setGroupSelected(response);
      } catch (error) {
         console.error("Error fetching group:", error);
      } finally {
         setIsLoadingCompany(false);
      }
   };

   const handleUpdateEditingSection = useCallback(
      (section: string, isDeleting = false) => {
         const exists = state.editingSection.includes(section);
         if (!exists) {
            if (!isDeleting) return setEditingSection([...state.editingSection, section]);
         }
         if (!isDeleting) return;
         const indexOfDeleting = state.editingSection.indexOf(section);
         const data = state.editingSection.filter((sSection) => sSection !== section);
         if (indexOfDeleting > -1) setEditingSection(data);
      },
      [setEditingSection, state.editingSection]
   );

   const isImplementationUser = useCallback(() => {
      const includedRoles = [
         "Usuario de implementación",
         "Coordinador de gobierno corporativo",
         "Coordinador de beneficiario controlador",
      ];
      return state.user && state.roles.every((role) => includedRoles.includes(role)) && state.roles.length > 0;
   }, [state.roles, state.user]);

   const verifyAccess = useCallback(
      (section: string | string[], module: boolean) => {
         if (!state.user.auth || !state.userModules?.length) return false;
         const sectionCheck = (access: string) =>
            typeof section === "string" ? section === access : section.includes(access);
         if (module) return state.userModules.some(sectionCheck);
         return state.resources.some((resource) => sectionCheck(resource.name));
      },
      [state.user.auth, state.userModules, state.resources]
   );

   const fetchCompaniesIfNecessary = useCallback(async () => {
      if (!state.user.auth || state.companies.length > 0) return;
      setIsLoadingCompanies(true);
      try {
         if (state.user.group || state?.user?.cordinatorGroups?.length > 0) {
            const groupCompanies = state.user.group.companies.map((company) => company);
            const userCompanies = state.user.role.map((rol) => rol.company);

            let coordinatorCompanies = [];
            if (state.user.cordinatorGroups) {
               coordinatorCompanies = state.user.cordinatorGroups.flatMap((g) => g.companies.map((c) => c._id));
            }

            const companiesResponse = await getCompaniesById([
               ...groupCompanies,
               ...userCompanies,
               ...coordinatorCompanies,
            ]);

            const officeCompanies = [];
            for (const company of companiesResponse.data) {
               if (company.company_details.office) {
                  officeCompanies.push(...company.company_details.office.companies);
               }
            }
            const officeResponse = await getCompaniesById(officeCompanies);

            setCompanies([...companiesResponse.data, ...officeResponse.data]);
         } else {
            const companiesResponse = await getCompaniesById(state.user.role.map((rol) => rol.company));

            const officeCompanies = [];
            for (const company of companiesResponse.data) {
               if (company.company_details.office) {
                  officeCompanies.push(...company.company_details.office.companies);
               }
            }
            const officeResponse = await getCompaniesById(officeCompanies);
            setCompanies([...companiesResponse.data, ...officeResponse.data]);
         }
         setIsLoadingCompanies(false);
      } catch (error) {
         setError(error.message);
      } finally {
         setIsLoadingCompanies(false);
      }
   }, [
      state.user.auth,
      state.user.group,
      state.user.role,
      state.companies.length,
      setCompanies,
      setIsLoadingCompanies,
      setError,
   ]);

   useEffect(() => {
      fetchCompaniesIfNecessary();
   }, [fetchCompaniesIfNecessary]);

   const fetchGroups = useCallback(async () => {
      if (!state.user.auth) return;
      try {
         const officeGroups = [];
         for (const company of state.companies) {
            if (company.company_details.office) {
               officeGroups.push(...company.company_details.office.companies);
            }
         }
         const officeResponse = await GetManyGroupsById(officeGroups);
         setGroups([...state.user.cordinatorGroups, ...officeResponse]);
      } catch (error) {
         setError(error.message);
      }
   }, [state.user.auth, state.user.cordinatorGroups, setGroups, state.companies]);

   useEffect(() => {
      fetchGroups();
   }, [fetchGroups]);

   const getProfilePicture = useCallback(async () => {
      if (!state.user.auth || state.userProfilePicture) return;
      const url = await getUrlS3("files-lecosy", { folder: `users/${state.user.id}` }, "profile.png");
      setUserProfilePicture(url);
   }, [setUserProfilePicture, state.user.auth, state.userProfilePicture, state.user.id]);

   useEffect(() => {
      getProfilePicture();
   }, [getProfilePicture]);

   const connectToSocket = useCallback(() => {
      if (!state.user.auth || state.socket) return;
      const socketServer = io(base_url, {
         port:
            process.env.NODE_ENV === "production" ||
            window.location.hostname === "test.web.lecosy.com.mx" ||
            window.location.hostname === "www.test.web.lecosy.com.mx"
               ? 80
               : 8002,
         withCredentials: true,
      });
      socketServer.emit("user", state.user.id);
      socketServer.on("notifications", (notificationsA) => {
         setNotifications(notificationsA);
      });
      setSocket(socketServer);
   }, [setSocket, setNotifications, state.user, state.socket]);

   useEffect(() => {
      connectToSocket();
   }, [connectToSocket]);

   const getAppbarPicture = useCallback(async () => {
      try {
         const entity = state.groupSelected || state.companySelected || state.groups[0] || state.companies[0];
         if (!entity) return;
         const folderPath = entity.name ? `group/${entity._id}` : `${entity._id}`;
         const [url, companyName] = await Promise.all([
            getUrlS3("images-lecosy", { folder: folderPath }, "logo.png"),
            entity.name || entity?.person_details?.comercialName,
         ]);
         if (url && companyName) setAppBarLogo({ url, name: companyName });
      } catch (error) {
         console.error("Error fetching app bar picture:", error);
      }
   }, [state.companies, state.companySelected, state.groupSelected]);

   useEffect(() => {
      getAppbarPicture();
   }, [getAppbarPicture]);

   //Admin
   const getAdmin = useCallback(
      async (userId: string) => {
         try {
            setIsLoadingCompanies(true);
            const adminResponse = await getAdminCompanyById(userId);
            if (adminResponse.data) {
               if (adminResponse.data.length > 1) setAdminCompanies(adminResponse.data.flat());
               else setCompanySelected(adminResponse.data.flat()[0]);
               setIsCompanyAdmin(true);
            } else setIsCompanyAdmin(false);
            setIsLoadingCompany(false);
            setIsLoadingCompanies(false);
         } catch (error) {
            console.log(error);
         }
      },
      [setIsLoadingCompanies, setIsCompanyAdmin, setAdminCompanies, setCompanySelected, setIsLoadingCompany]
   );

   const isOffice = useMemo(
      () => state?.companySelected && state?.companySelected?.company_details?.office,
      [state.companySelected]
   );

   const getIfOfficeAdmin = useCallback(async () => {
      const ownerId = state?.companySelected?.owner || state?.groupSelected?.owner;
      if (ownerId) {
         const companyResponse = await getCompanyById(ownerId);
         const companyFound = companyResponse?.data;

         if (companyFound?.company_details) {
            const users = [
               ...(companyFound.company_details.users?.map((u) => u._id) || []),
               companyFound.company_details.admin?._id,
            ].filter(Boolean);

            setIsOfficeAdmin(users.includes(state.user.id));
            return;
         }
      }
      setIsOfficeAdmin(false);
   }, [state?.companySelected?.owner, state?.groupSelected?.owner, state?.user?.id]);

   useEffect(() => {
      getIfOfficeAdmin();
   }, [getIfOfficeAdmin]);

   const preProcessingInfo = useCallback(async () => {
      if (
         state.isLoadingUser ||
         state.companies.length === 0 ||
         !state.user.auth ||
         (state.user.resources && state.user.resources.length > 0) ||
         state.user.role.every((item) => typeof item === "string")
      )
         return;

      const resources = [];
      const roleIds = [];
      const modules = [];
      for (const companyInfo of state.companies) {
         for (const service of companyInfo.company_details.servicesDetails) {
            if (!service.disable) modules.push(service.serviceId);
         }
      }
      const modulesInfo = await getClientModules(modules);
      for (const roleByCompany of state.user.role) {
         for (const role of roleByCompany.roles) roleIds.push(role);
      }
      const roles = await getAllUserRoles(roleIds);
      const roleNames = [];
      for (const roleByCompany of roles) {
         roleNames.push(roleByCompany.name);
         for (const access of roleByCompany.access) {
            for (const resource of access.resources) {
               const res = {
                  _id: resource.resource._id,
                  name: resource.resource.name,
                  permission: resource.permissions,
                  charges: roleByCompany.charges,
               };
               resources.push(res);
            }
         }
      }
      setUserModules(
         modulesInfo
            .map((mod) => {
               const userResources = resources.map((res) => res._id);
               if (!userResources.some((userR) => mod.resources.includes(userR))) return;
               return mod.service;
            })
            .filter(Boolean)
      );
      const newData = {
         ...state.user,
         role: fetchRoles(roleNames),
         resources: resources,
         modules: modulesInfo
            .map((mod) => {
               const userResources = resources.map((res) => res._id);
               if (!userResources.some((userR) => mod.resources.includes(userR))) return;
               return mod.service;
            })
            .filter(Boolean),
         companies: state.companies,
      };
      setUser(newData);
      setRoles(fetchRoles(roleNames));
      setResources(resources);
      setIsLoadingModules(false);
      getAdmin(state.user.id);
   }, [state.companies, state.groups, state.user]);

   useEffect(() => {
      preProcessingInfo();
   }, [preProcessingInfo]);

   const getPendingCharges = useCallback(async (userId: string) => {
      const pendingCharges = await getGoverningBodyNewCharges(userId);
      setPendingCharges(pendingCharges);
      return pendingCharges;
   }, []);

   const refetch = async () => {
      if (state.groupSelected) await getGroupDetails(state.groupSelected._id);
      if (state.companySelected) await getCompanyDetails(state.companySelected._id);
   };

   return (
      <UserContext.Provider
         value={{
            ...state,
            setUser,
            setLoadingUser,
            logoutUser,
            getCompanyDetails,
            setCompanySelected,
            setcompanySelectedUserP,
            setSelectedResources,
            setIsCompanyAdmin,
            setIsLoadingCompany,
            setUserProfilePicture,
            setEditingSection,
            setPath,
            handleUpdateEditingSection,
            isImplementationUser,
            verifyAccess,
            setOpenCompanySelector,
            setLoggedIn,
            setIsAuthenticated,
            getPendingCharges,
            setSkipCharges,
            isOffice,
            refetch,
            getGroupDetails,
         }}
      >
         {children}
      </UserContext.Provider>
   );
};
