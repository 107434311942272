import React, { useMemo } from "react";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { HeadCell, TableComponent } from "../../../components/TableComponent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";

export const DocumentConfiguration = () => {
   const TableHeaders: HeadCell[] = [
      { field: "name", headerName: "Nombre del documento", type: "string", align: "left" },
      {
         field: "type",
         headerName: "Tipo de archivo",
         type: "string",
         align: "center",
      },
      { field: "size", headerName: "Tamaño", type: "string", align: "center" },
      {
         field: "established",
         headerName: "Periodo establecido",
         type: "string",
         align: "center",
      },
      {
         field: "personalize",
         headerName: "Personalizar",
         type: "string",
         align: "center",
      },
   ];

   const formattedData = useMemo(() => {
      return [
         {
            name: "Acta de nacimiento",
            type: "PDF",
            personalize: (
               <Button
                  onClick={() => {
                     // setOpenComplainerModalEditing(true);
                  }}
                  sx={{ borderRadius: "15px" }}
               >
                  <EditIcon />
               </Button>
            ),
         },
         {
            name: "CURP",
            type: "PDF",
            personalize: (
               <Button
                  onClick={() => {
                     // setOpenComplainerModalEditing(true);
                  }}
                  sx={{ borderRadius: "15px" }}
               >
                  <EditIcon />
               </Button>
            ),
         },
         {
            name: "Constancia de Situación Fiscal",
            type: "PDF",
            personalize: (
               <Button
                  onClick={() => {
                     // setOpenComplainerModalEditing(true);
                  }}
                  sx={{ borderRadius: "15px" }}
               >
                  <EditIcon />
               </Button>
            ),
         },
         {
            name: "Registro Federal de Contribuyentes (RFC)",
            type: "PDF",
            personalize: (
               <Button
                  onClick={() => {
                     // setOpenComplainerModalEditing(true);
                  }}
                  sx={{ borderRadius: "15px" }}
               >
                  <EditIcon />
               </Button>
            ),
         },
      ];
   }, []);

   return (
      <>
         <Box sx={{ bgcolor: "#fff", mt: "16px !important" }}>
            <Box
               sx={{
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mx: "30px",
               }}
            >
               <Typography
                  sx={{
                     fontWeight: 600,
                  }}
               >
                  Lista de documentos
               </Typography>
               <Button
                  sx={{
                     bgcolor: "#162c44",
                     width: "180px",
                     height: "30px",
                     color: "#fff",
                     "&:hover": { bgcolor: "#162c44" },
                  }}
               >
                  <AddCircleIcon sx={{ mr: "10px" }} /> Añadir
               </Button>
            </Box>
            <TableComponent
               headers={TableHeaders}
               defaultColumnToOrder={"no"}
               defaultOrder={"asc"}
               defaultRowsPerPage={10}
               rowsPerPageOptions={[10, 15, 20]}
               rows={formattedData}
               loader={false}
               emptyDataText={"No se encontraron registros"}
               onClick={() => {}}
            />
         </Box>
      </>
   );
};
