import { Box, Stack, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import React, { useContext } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { UpdateCompletedSession } from "../../../lib/gobCorpBEClient";
import { UserContext } from "../../../context/userContext";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import legalarioValidation from "../../../lib/Legalario/legalarioValidation";

export const SessionNotification = ({ setManifiestoModalOpen, setHideNotification, setManifiestoModalOpenSign }) => {
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const { setSessionFound, sessionFoundArray, sessionFound } = useContext(GovernanceSessionContext);
   const { companySelected, gobernanceBody, consultiveGroup } = useContext(GovernanceContext);
   const { user } = useContext(UserContext);
   const { mutate } = UpdateCompletedSession();

   const navigate = useNavigate();

   const openCanceledSession = (session = sessionFound) => {
      mutate(
         {
            userNotified: session.userNotified ? [...session.userNotified, user.id] : [user.id],
            sessionId: session._id,
         },
         {
            onError: (error) => {
               console.log(error);
               showSnackBar("Error al cerrar session.", true);
            },
            onSuccess() {
               setSessionFound(null);
               navigate(session.company + `/sesion/${session._id}`);
            },
         }
      );
   };

   const handleClose = (session = null) => {
      if (session.canceled) {
         openCanceledSession(session);
      } else {
         const governance = gobernanceBody.find((gov) => gov._id === session.governance);

         const validationObject = {
            company: session.company,
            type: session.type,
            assembly: session.assembly,
            group: session.group,
         };

         if (session.usersRegistry.find((registry) => registry.attended && registry.user === user.id)) {
            return navigate(companySelected + `/sesion/${session._id}`);
         } else if (legalarioValidation(validationObject, governance.title)) {
            setManifiestoModalOpenSign(true);
         } else {
            setManifiestoModalOpen(true);
         }
         setSessionFound(session);
         setHideNotification(true);
         setAnchorEl(null);
      }
   };

   const handleCloseMenu = () => {
      setAnchorEl(null);
   };

   const ITEM_HEIGHT = 48;
   function showSnackBar(arg0: string, arg1: boolean) {
      throw new Error("Function not implemented.");
   }

   return (
      <Box sx={{ height: "100%", position: "absolute", right: 0, zIndex: 1000 }}>
         <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3, position: "fixed", right: 0, top: "8vh" }}>
            <Box
               sx={{
                  bgcolor: "#bdc8d3",
                  color: "#162C44",
                  fontWeight: 600,
                  borderTopLeftRadius: 4,
                  borderBottomLeftRadius: 4,
                  p: 1.5,
               }}
            >
               <Box sx={{ display: "flex" }}>
                  {sessionFound?.canceled ? (
                     <CancelIcon sx={{ mr: 1, color: "#EE1030" }} />
                  ) : (
                     <InfoIcon sx={{ mr: 1 }} />
                  )}

                  <Stack
                     sx={{ cursor: "pointer" }}
                     onClick={(event) => {
                        if (sessionFoundArray.length !== 0) {
                           handleClick(event);
                        } else {
                           if (sessionFound?.canceled) {
                              openCanceledSession();
                           } else {
                              const governance = gobernanceBody.find((gov) => gov._id === sessionFound.governance);
                              const validationObject = {
                                 company: sessionFound.company,
                                 type: sessionFound.type,
                                 assembly: sessionFound.assembly,
                                 group: sessionFound.group,
                              };
                              if (
                                 sessionFound.usersRegistry.find(
                                    (registry) => registry.attended && registry.user === user.id
                                 )
                              ) {
                                 navigate(companySelected + `/sesion/${sessionFound._id}`);
                              } else if (legalarioValidation(validationObject, governance.title)) {
                                 setManifiestoModalOpenSign(true);
                              } else setManifiestoModalOpen(true);
                              setHideNotification(true);
                           }
                        }
                     }}
                  >
                     <Typography sx={{ color: "#3F5063", fontWeight: 600, textAlign: "left" }}>
                        {sessionFound?.canceled ? "Sesión cancelada" : "Sesión en curso"}
                     </Typography>
                     <Typography sx={{ color: "#3F5063", maxWidth: 300, textAlign: "left" }}>
                        {sessionFound?.canceled
                           ? `La sesión "${sessionFound.title}" ha sido cancelada.`
                           : "Accede a la sesión para confirmar tu participación."}
                     </Typography>
                  </Stack>
                  <IconButton
                     sx={{ alignSelf: "flex-start", m: 0, p: 0.5 }}
                     onClick={() => {
                        if (sessionFound?.canceled) {
                           mutate(
                              {
                                 userNotified: sessionFound.userNotified
                                    ? [...sessionFound.userNotified, user.id]
                                    : [user.id],
                                 sessionId: sessionFound._id,
                              },
                              {
                                 onError: (error) => {
                                    console.log(error);
                                    showSnackBar("Error al cerrar session.", true);
                                 },
                                 onSuccess() {
                                    setSessionFound(null);
                                 },
                              }
                           );
                        } else {
                           setHideNotification(true);
                        }
                     }}
                  >
                     <ClearIcon />
                  </IconButton>
               </Box>
            </Box>
         </Box>
         <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
               style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: 388,
                  backgroundColor: "#CDD3D8",
               },
            }}
            sx={{ mt: 1.5, ml: 2 }}
         >
            {sessionFoundArray.map(
               (
                  option //Change text width
               ) => (
                  <MenuItem
                     sx={{ bgcolor: "#CDD3D8" }}
                     onClick={() => {
                        handleClose(option);
                     }}
                  >
                     {option?.canceled && <CancelIcon sx={{ mr: 1, color: "#EE1030" }} />}
                     <Typography sx={{ textWrap: "wrap" }}>
                        {(option.canceled ? "CANCELADA: " : "") + `${option.billCode}. ${option.title}`}
                     </Typography>
                  </MenuItem>
               )
            )}
         </Menu>
      </Box>
   );
};
