import { Route } from "react-router-dom";

const base_url =
   window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
      ? "https://test.web.lecosy.com.mx/pc"
      : process.env.NODE_ENV === "production"
      ? "https://web.lecosy.com.mx/pc"
      : "http://localhost:3004";

export const useExternPCManagementRoutes = () => {
   return (
      <>
         <Route
            path="materias-juridicas"
            Component={() => {
               window.location.replace(`${base_url}/materias-juridicas`);
               return null;
            }}
         />
         <Route
            path="grado-cumplimiento"
            Component={() => {
               window.location.replace(`${base_url}/grado-cumplimiento`);
               return null;
            }}
         />
         <Route
            path="gestion-documental"
            Component={() => {
               window.location.replace(`${base_url}/gestion-documental`);
               return null;
            }}
         />
      </>
   );
};
