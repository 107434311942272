import React, { useContext, useEffect, useMemo, useState } from "react";
import { SystemThemeConfig } from "../../config/systemTheme.config";
import { Box, Button, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import { ExternalGovernanceSessionContext } from "../../context/governanceContext/externalSessionContext";
import { ManifestoModalExternal } from "../../components/Gobierno corporativo/ExternalBill/ManifestoModalExternal";
import { ExternalBill } from "../../components/Gobierno corporativo/ExternalBill/ExternalBill";
import ScreenHeaderExternal from "../../components/Gobierno corporativo/ExternalBill/ScreenHeaderExternal";
import { ExternalSessionFiles } from "../../components/Gobierno corporativo/ExternalBill/ExternalSessionFiles";
import { SessionMembersExternal } from "../../components/Gobierno corporativo/ExternalBill/SessionMembersExternal";
import { MobileBillMenu } from "../../components/Gobierno corporativo/ExternalBill/MobileBillMenu";
import { InfoOutlined } from "@mui/icons-material";
import { SignModalExternal } from "../../components/Gobierno corporativo/ExternalBill/SignModalExternal";
import { NameInputModalExternal } from "../../components/Gobierno corporativo/ExternalBill/NameInputModalExternal";
import legalarioValidation from "../../lib/Legalario/legalarioValidation";
import ManifestoModalLegalExternal from "../../components/Gobierno corporativo/ExternalBill/Legalario/ManifestoModalLegalExternal";
import SignModalLegalExternal from "../../components/Gobierno corporativo/ExternalBill/Legalario/SignModalLegalExternal";

const GetExternalSessionData = () => {
   const [timer, setTimer] = useState(59);

   useEffect(() => {
      const interval = setInterval(() => {
         if (timer === 0) {
            clearInterval(interval);
         } else {
            setTimer((prevContador) => prevContador - 1);
         }
      }, 1000);
      return () => clearInterval(interval);
   }, [timer]);

   return { timer, setTimer };
};

const ExternalSession = () => {
   const {
      session,
      isLoading,
      isVerified,
      userId,
      isRejected,
      isWaitingToVerify,
      canceledSession,
      completedSession,
      openModalToSign,
      setOpenModalToSign,
      openInputNameModal,
      signArray,
      valuesFromBill,
   } = useContext(ExternalGovernanceSessionContext);
   const { timer, setTimer } = GetExternalSessionData();
   const [manifiestoModal, setManifiestoModal] = useState(true);

   const alreadySigned = useMemo(() => signArray.includes(userId), [signArray?.length]);

   const sessionSignValidation = useMemo(() => {
      //validation to use legalario
      if (!session) return;
      const validationObject = {
         company: session.company,
         type: session.type,
         assembly: session.assembly,
         group: session.group,
      };
      return legalarioValidation(validationObject, (session?.governance as any)?.title);
   }, [session]);

   return (
      <ExternalBase>
         {isLoading ? (
            <Box
               sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 2,
               }}
            >
               <CircularProgress />
            </Box>
         ) : !session ? (
            <Box sx={{ display: "flex" }}>
               <Stack sx={{ display: "flex", alignItems: "center", mt: 5, width: "100%" }}>
                  <InfoOutlined sx={{ color: "#4B5563", fontSize: 100 }} />
                  <Typography sx={{ textAlign: "center", mt: 2, width: 346, fontSize: 16 }}>
                     {`No se encontró asamblea/sesión activa`}
                  </Typography>
               </Stack>
            </Box>
         ) : completedSession ? (
            <Stack
               spacing={1}
               sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 5,
                  width: "100%",
               }}
            >
               <InfoOutlined sx={{ color: "#4B5563", fontSize: 100 }} />
               <Typography sx={{ textAlign: "center", fontSize: 16 }}>
                  {`Esta ${session?.assembly ? "asamblea" : "sesión"} ha sido finalizada.`}
               </Typography>
            </Stack>
         ) : canceledSession ? (
            <Box sx={{ display: "flex" }}>
               <Stack sx={{ display: "flex", alignItems: "center", mt: 5, width: "100%" }}>
                  <InfoOutlined sx={{ color: "#4B5563", fontSize: 100 }} />
                  <Typography sx={{ textAlign: "center", mt: 2, width: 346, fontSize: 16 }}>
                     {`Esta ${session?.assembly ? "asamblea" : "sesión"} ha sido cancelada`}
                  </Typography>
               </Stack>
            </Box>
         ) : isWaitingToVerify ? (
            <>
               <Typography sx={{ textAlign: "center", mt: 5 }}>Esperando verificación</Typography>
               <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <Button
                     variant="contained"
                     disabled={timer > 0}
                     onClick={() => {
                        setManifiestoModal(true);
                        setTimer(59);
                     }}
                  >
                     {timer > 0 && `0:${timer < 10 ? "0" + timer : timer}`} Intentar de nuevo
                  </Button>
               </Box>
               <Modal
                  open={manifiestoModal}
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     px: { xs: 2, lg: 0 },
                  }}
               >
                  {sessionSignValidation ? (
                     <ManifestoModalLegalExternal setManifiestoModalOpen={setManifiestoModal} />
                  ) : (
                     <ManifestoModalExternal session={session} setManifiestoModalOpen={setManifiestoModal} />
                  )}
               </Modal>
               <Modal
                  open={openInputNameModal}
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     px: { xs: 2, lg: 0 },
                  }}
               >
                  <NameInputModalExternal />
               </Modal>
            </>
         ) : isRejected ? (
            <>
               <Stack
                  spacing={2}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 5, width: "100%" }}
               >
                  <Typography sx={{ textAlign: "center", marginTop: 2, px: 2 }}>
                     No se pudo verificar con éxito tu identificación, favor de intentar de nuevo.
                  </Typography>
                  <Button
                     variant="contained"
                     sx={{ bgcolor: "#162c44" }}
                     onClick={() => {
                        setManifiestoModal(true);
                     }}
                  >
                     Intentar de nuevo
                  </Button>
               </Stack>
               <Modal
                  open={manifiestoModal}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "center", px: { xs: 2, lg: 0 } }}
               >
                  {sessionSignValidation ? (
                     <ManifestoModalLegalExternal setManifiestoModalOpen={setManifiestoModal} />
                  ) : (
                     <ManifestoModalExternal session={session} setManifiestoModalOpen={setManifiestoModal} />
                  )}
               </Modal>
               <Modal
                  open={openInputNameModal}
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     px: { xs: 2, lg: 0 },
                  }}
               >
                  <NameInputModalExternal />
               </Modal>
            </>
         ) : !isVerified ? (
            <>
               <Modal
                  open={manifiestoModal}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "center", px: { xs: 2, lg: 0 } }}
               >
                  {sessionSignValidation ? (
                     <ManifestoModalLegalExternal setManifiestoModalOpen={setManifiestoModal} />
                  ) : (
                     <ManifestoModalExternal session={session} setManifiestoModalOpen={setManifiestoModal} />
                  )}
               </Modal>
               <Modal
                  open={openInputNameModal}
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     px: { xs: 2, lg: 0 },
                  }}
               >
                  <NameInputModalExternal />
               </Modal>
            </>
         ) : (
            <Stack
               sx={{
                  alignItems: "center",
                  opacity: alreadySigned ? 0.5 : 1,
                  pointerEvents: alreadySigned ? "none" : "auto",
               }}
            >
               <Stack
                  sx={{
                     width: "100%",
                     display: "flex",
                     flexDirection: { xs: "column", lg: "row" },
                     justifyContent: "space-between",
                     gap: { xs: 0, lg: 3 },
                     maxWidth: { xs: 800, lg: 1440 },
                  }}
               >
                  <Stack sx={{ width: "25%", display: { xs: "none", lg: "block" } }}>
                     <ExternalSessionFiles />
                  </Stack>
                  <Stack sx={{ width: { xs: "100%", lg: "50%" } }}>
                     <ExternalBill />
                  </Stack>
                  <Stack sx={{ width: "25%", display: { xs: "none", lg: "block" } }}>
                     <SessionMembersExternal />
                  </Stack>
                  <Stack sx={{ flex: 1, display: { xs: "block", lg: "none" } }}>
                     <MobileBillMenu />
                  </Stack>
               </Stack>
               {alreadySigned && (
                  <Typography
                     sx={{
                        fontWeight: 600,
                        fontSize: 30,
                        m: "auto",
                        color: "#162c44",
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: 512,
                        textAlign: "center",
                     }}
                  >
                     {`EN ESPERA DE FINALIZACIÓN DE ${session.assembly ? "ASAMBLEA" : "SESIÓN"}`}
                  </Typography>
               )}
               <Modal open={openModalToSign} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  {sessionSignValidation && valuesFromBill.document_id ? (
                     <SignModalLegalExternal />
                  ) : (
                     <SignModalExternal setOpenModalToSign={setOpenModalToSign} session={session} />
                  )}
               </Modal>
            </Stack>
         )}
      </ExternalBase>
   );
};

const ExternalBase = ({ children }) => {
   return (
      <SystemThemeConfig>
         <ScreenHeaderExternal />
         <Box
            sx={{
               height: "100vh",
               overflowY: "auto",
               bgcolor: "#F5F5F5",
               p: { xs: 0, lg: 5 },
               pt: { xs: 8, lg: 12 },
            }}
         >
            {children}
         </Box>
      </SystemThemeConfig>
   );
};
export default ExternalSession;
