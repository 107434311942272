import axios, { AxiosError } from "axios";
import UseCreateComplaintsMap from "../hooks/complaints/useCreateComplaintsMap";
import { licenceValidation } from "../const/globalConst";
import { getBranchById, getCompanyById } from "./usersBEClient";

const base_url =
   window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
      ? "https://test.server.lecosy.com.mx"
      : process.env.NODE_ENV === "production"
      ? "https://server.lecosy.com.mx"
      : "http://localhost:3030";

const host = base_url;
export const BASE_ENDPOINT = "/api";
const DENOUNCE_CHANNEL_URL = "/cd";
const MASTER_ENDPOINT = "/master";
const USERS_ENDPOINT = "/users";
const MAIL_ENDPOINT = "/mail";
const INTERVIEWS_ENDPOINT = "/interviews";
const CLIENT_ENDPOINT = "/client";
const COMPLAINTS_ENDPOINT = "/complaints";

export const catchAxiosError = (error) => {
   const errorDetails: AxiosError = error as AxiosError;
   const errorMessage: any = errorDetails.response.data;
   return errorMessage;
};

const handleInvalidRequest = (error) => {
   const details: AxiosError = error as AxiosError;
   if (!details.response) return null;
   if (details.response.data === "expired") {
      console.log("request session expired");
      return null;
   }
   if (details.response.data === "no user") {
      console.log("request session expired");
      return null;
   }
};

axios.defaults.withCredentials = true;

// ================== SubUser Requests ==========================

export const deleteSubUserById = async (subUserId) => {
   axios.defaults.withCredentials = true;
   return axios.delete(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USERS_ENDPOINT}/${subUserId}`
   );
};

// ================== complaints Requests ==========================

export const changeIncomingComplaint = async (reportNumber) => {
   axios.defaults.withCredentials = true;
   return axios.put(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/modify/incoming/${reportNumber}`,
      {}
   );
};

export const reassignCompetence = async (reportNumber, involved, competentName, involvedName) => {
   const body = {
      competentName,
      involvedName,
   };
   axios.defaults.withCredentials = true;
   return axios.post(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/${reportNumber}/incompetence/${involved}`,
      body
   );
};

export const getComplaintsByReportNumber = async (reportNumber: string, branches, companies) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(
         `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/${reportNumber}`
      );
      if (!data) throw new Error(`No data found`);
      if (data.company) {
         const foundCompany = (await getCompanyById(data.company)).data;
         data.companyBranch = { company: foundCompany };
      } else {
         const branch = await getBranchById(data.companyBranch);
         const company = (await getCompanyById(branch.company)).data;
         data.companyBranch = { name: branch.name, _id: branch._id, company: company };
      }
      return data;
   } catch (err) {
      handleInvalidRequest(err);
   }
};

export const getComplaints = async (branches, companies) => {
   axios.defaults.withCredentials = true;
   const branchesId: string[] = [];
   const enabledServiceBranches = branches.filter((branch) =>
      branch.company.company_details.servicesDetails.some(
         (service) => service.serviceId.service === "Canal de denuncias" && !service.disable
      )
   );
   const licencedBranches = enabledServiceBranches.filter(async (branch) => {
      const selectedCompany = companies.find((company) => company._id === branch.company._id);
      if (await licenceValidation("Canal de denuncias", selectedCompany)) return branch;
      return null;
   });
   const companiesIdsList = companies.map((company) => company._id);

   for (const branch of licencedBranches) {
      branchesId.push(branch._id);
   }

   const body = {
      companies: companiesIdsList,
      branchesId: branchesId,
   };

   const { data } = await axios.put(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}`,
      body
   );

   const newData: any[] = [];
   for (const complaint of data.complaintsFound) {
      if (complaint.company) {
         const foundCompany = companies.find((company) => company._id === complaint.company);
         if (foundCompany) newData.push({ complaint, company: foundCompany });
         continue;
      }
      const branch = branches.find((branch) => branch._id === complaint.companyBranch);
      if (branch) newData.push({ complaint, branch });
   }

   return UseCreateComplaintsMap(newData);
};

export const DocumentationUpload = async (fileKey: string, reportNumber: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(
         `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/documentation/u/${reportNumber}/${fileKey}`
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const uploadFilePut = async (signedURL, file) => {
   const config = {
      headers: {
         "Content-Type": `${file.type}`,
      },
   };
   axios.defaults.withCredentials = false;
   await axios.put(signedURL, file, config);
};

export const updateComplaintStatus = async (reportNumber, status, dia, mes, ano, horas, company, email) => {
   const payload = {
      status,
      dia,
      mes,
      ano,
      horas,
      company,
      email,
   };
   axios.defaults.withCredentials = true;
   return axios.put(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/modify/status/${reportNumber}`,
      payload
   );
};

export const addResolution = async (reportNumber, measures, resolution) => {
   const payload = {
      measures,
      resolution,
   };
   axios.defaults.withCredentials = true;
   return axios.put(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/resolution/${reportNumber}`,
      payload
   );
};

export const sendResolutionNotificationEmail = async (
   reportNumber: string,
   complainerEmail: string,
   offendersEmails: string[]
) => {
   try {
      const body = {
         reportNumber,
         complainerEmail,
         offendersEmails,
      };
      return await axios.post(
         `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${MAIL_ENDPOINT}/send/notification/resolution`,
         body
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getDocumentURLbyName = async (reportNumber, documentName) => {
   const payload = {
      documentName,
   };
   axios.defaults.withCredentials = true;
   return axios.post(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/document/${reportNumber}`,
      payload
   );
};

export const getImageUrlForEvidences = async (reportNumber: string, fileKey: string) => {
   axios.defaults.withCredentials = true;
   return axios.get(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/evidences/${reportNumber}/${fileKey}`
   );
};

// ==================  Interviews Requests ====================

export const createInterview = async (reportNumber, involved, date, place, email, dd, mailSpeech) => {
   try {
      const body = {
         email,
         date,
         place,
         dd,
         mailSpeech,
      };
      axios.defaults.withCredentials = true;
      return await axios.post(
         `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INTERVIEWS_ENDPOINT}/${reportNumber}/${involved}/new`,
         body
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getInterviewDate = async (reportNumber, involved) => {
   axios.defaults.withCredentials = true;
   return axios.get(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INTERVIEWS_ENDPOINT}/${reportNumber}/${involved}`
   );
};

export const getInterviewDateByDate = async (reportNumber, involved, date) => {
   axios.defaults.withCredentials = true;
   return axios.get(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INTERVIEWS_ENDPOINT}/${reportNumber}/${involved}/${date}`
   );
};

// ================== mail Requests ==========================

export const sendMailLink = async (email, message, link) => {
   const body = {
      email,
      message,
      link,
   };
   axios.defaults.withCredentials = true;
   return axios.post(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${MAIL_ENDPOINT}/send/link`,
      body
   );
};

// ================== complaint state tracking Requests ==========================

export const getComplaintStateTracking = async (reportNumber) => {
   axios.defaults.withCredentials = true;
   return axios.get(`${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/tracking/${reportNumber}`);
};

export const updateComplaintStateTracking = async (reportNumber, stateTracking, processState) => {
   const body = {
      stateTracking,
      processState,
   };
   axios.defaults.withCredentials = true;
   return axios.put(
      `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/tracking/update/${reportNumber}`,
      body
   );
};

export const createWitness = async (reportNumber: string, witnessData: any) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.post(
         `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/witness/${reportNumber}`,
         witnessData
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const addAditionalInfo = async (subjectId: string, information: any) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.put(
         `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/ai/${subjectId}`,
         information
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUserPendingSignaturesDocuments = async (
   branchesIds: string[],
   companiesIds: string[],
   userRoles: string[],
   userCompanies: any[],
   branches: any[]
) => {
   try {
      const body = {
         branchesIds: branchesIds,
         companiesIds: companiesIds,
         userRoles: userRoles,
      };
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(
         `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/signatures`,
         body
      );

      const newObject = data.reduce((array, doc) => {
         let foundCompany;
         if (doc.company) foundCompany = userCompanies.find((company) => company._id === doc.company);
         else if (doc.branch) foundCompany = branches.find((branch) => branch._id === doc.branch);
         return [
            ...array,
            {
               name: doc.name,
               rn: doc.rn,
               company: doc.company ? foundCompany.person_details.comercialName : foundCompany.name,
            },
         ];
      }, []);
      return newObject;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const DocumentationFile = async (reportNumber: string, fileKey: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(
         `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPLAINTS_ENDPOINT}/documentation/${reportNumber}/${fileKey}`
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const signComplaintDocument = async (reportNumber: string, fileKey: string, user: string) => {
   try {
      const body = {
         fileKey,
         user,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(
         `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/document/signature/sign`,
         body
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const deleteDocument = async (fileKey: string, reportNumber: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.delete(
         `${host}${DENOUNCE_CHANNEL_URL}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPLAINTS_ENDPOINT}/document/d/${reportNumber}/${fileKey}`
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const createGroupPdf = async (
   groupId: string,
   monthsValue: {
      startDate?: Date | null;
      finishDate?: Date | null;
      monthValue?: Date | null;
      logoUrl: string;
      primaryColor: string;
      secondaryColor: string;
      groupName: string;
      groupData: Array<any>;
   }
) => {
   try {
      const body = monthsValue;

      axios.defaults.withCredentials = true;
      // Llamada al endpoint que genera el PDF con el groupId
      const { data: pdfUrl } = await axios.put(`${host}/cd/api/client/report/group/${groupId}/groupReports`, body);

      // Retornar solo la URL
      return pdfUrl;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const createCompanyPDF = async (
   groupId,
   companyId,
   startDate,
   finishDate,
   logoUrl,
   primaryColor,
   secondaryColor,
   businessName,
   totalComplaints,
   denunciasResueltas,
   denunciasEnProceso,
   totalSuggestions,
   anonymousComplaintsPercentage,
   categoryPercentages,
   complaintStatus1,
   complaintStatus2,
   totalComplaints2,
   complaintClassifications,
   totalClassifications,
   nombresSucursales,
   sucursalComplaintStats,
   totalDenuncias2,
   totalQuejas
) => {
   try {
      const body = {
         startDate,
         finishDate,
         logoUrl,
         primaryColor,
         secondaryColor,
         businessName,
         totalComplaints,
         denunciasResueltas,
         denunciasEnProceso,
         totalSuggestions,
         anonymousComplaintsPercentage,
         categoryPercentages,
         complaintStatus1,
         complaintStatus2,
         totalComplaints2,
         complaintClassifications,
         totalClassifications,
         nombresSucursales,
         sucursalComplaintStats,
         totalDenuncias2,
         totalQuejas,
      };

      axios.defaults.withCredentials = true;

      // Llamada al endpoint que genera el PDF con el companyId
      const { data: pdfUrl } = await axios.put(
         `${host}/cd/api/client/report/company/${groupId}/companyReports/${companyId}`,
         body
      );

      // Retornar solo la URL
      return pdfUrl;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createBranchPDF = async (
   groupId: string,
   companyId: string,
   branchId: string,
   monthsValue: {
      startDate?: Date | null;
      finishDate?: Date | null;
      monthValue?: Date | null;
      logoUrl: string;
      primaryColor: string;
      secondaryColor: string;
      businessName: string;
      branchData: any;
   }
) => {
   try {
      const body = monthsValue;

      axios.defaults.withCredentials = true;

      // Llamada al endpoint que genera el PDF con el branchId
      const { data: pdfUrl } = await axios.put(
         `${host}/cd/api/client/report/branch/${groupId}/companyReports/${companyId}/branchReports/${branchId}`,
         body
      );

      // Retornar solo la URL
      return pdfUrl;
   } catch (error) {
      handleInvalidRequest(error);
   }
};
