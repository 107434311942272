import { Add, Close, Download, NavigateBefore, NavigateNext, Remove } from "@mui/icons-material";
import { Box, Button, Collapse, Divider, IconButton, Modal, Stack, Typography } from "@mui/material";
import Sample from "../../PDFVisualizer/PDFViewer";
import { ExcelViewer } from "./ExcelViewer";
import { VideoPlayer } from "./VideoPlayer";
import { ExcelPDF } from "./ExcelPDF";
import { FilesContext } from "../../../../context/governanceContext/filesContext";
import { useContext, useState } from "react";
import ZipPDFViewer from "../../PDFVisualizer/ZipPDFViewer";

interface IPropsFileVisualizer {
   openPDF?: boolean;
   openZip?: boolean;
   openImage?: boolean;
   openVideo?: boolean;
   openOther?: boolean;
   pdfUrl: string;
   setOpenPDF: Function;
   setOpenZip?: Function;
   setOpenImage: Function;
   setOpenVideo: Function;
   setOpenOther: Function;
   fileNames: string;
   audio: boolean;
   selectedFile: any;
}

export const FileVisualizer = ({
   openPDF,
   openImage,
   openVideo,
   openOther,
   openZip,
   pdfUrl,
   setOpenPDF,
   setOpenZip,
   setOpenImage,
   setOpenVideo,
   setOpenOther,
   fileNames,
   audio,
   selectedFile,
}: IPropsFileVisualizer) => {
   const { permissions } = useContext(FilesContext);
   const fileAccess = permissions(selectedFile ? selectedFile : null);
   const [zoom, setZoom] = useState<number>(1);
   const [currentFileIndex, setCurrentFileIndex] = useState<number>(1);
   const [pdfFiles, setPdfFiles] = useState([]);
   console.log(pdfFiles);
   const handleZoomIn = () => {
      setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2));
   };

   const handleZoomOut = () => {
      setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5));
   };

   function switchToNextFile() {
      setCurrentFileIndex((prevIndex) => (prevIndex + 1) % pdfFiles.length);
   }

   function switchToPreviousFile() {
      setCurrentFileIndex((prevIndex) => (prevIndex - 1 + pdfFiles.length) % pdfFiles.length);
   }

   const downloadFile = () => {
      fetch(pdfUrl, {
         method: "GET",
         headers: {
            "Content-Type": selectedFile.type,
         },
      })
         .then((response) => response.blob())
         .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", selectedFile.originalName + `.` + selectedFile.type.split("/")[1]);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
         });
   };
   return (
      <Modal open={openPDF || openZip || openImage || openVideo || openOther} style={{ backgroundColor: "#00000050" }}>
         <Box sx={{ overflow: "auto", maxHeight: "100vh" }}>
            <Collapse unmountOnExit in={openPDF}>
               <Box
                  sx={{
                     position: "absolute",
                     bottom: 15,
                     left: "50%",
                     transform: "translateX(-50%)",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     bgcolor: "rgba(255, 255, 255, 0.8)",
                     borderRadius: 1,
                     p: 1,
                     boxShadow: 3,
                     zIndex: 1000,
                  }}
               >
                  <Button variant="contained" onClick={handleZoomOut} sx={{ mx: 1 }}>
                     <Remove />
                  </Button>
                  <Typography variant="body1" sx={{ mx: 1 }}>
                     Zoom: {(zoom * 100).toFixed(0)}%
                  </Typography>
                  <Button variant="contained" onClick={handleZoomIn} sx={{ mx: 1 }}>
                     <Add />
                  </Button>
               </Box>

               <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 20, top: 0 }}>
                  {fileAccess >= 2 && (
                     <IconButton onClick={() => downloadFile()}>
                        <Download sx={{ color: "white", fontSize: "37px" }} />
                     </IconButton>
                  )}
                  <IconButton onClick={() => setOpenPDF(false)}>
                     <Close sx={{ color: "white", fontSize: "37px" }} />
                  </IconButton>
               </Box>
               <Sample file={pdfUrl} zoom={zoom} />
            </Collapse>

            <Collapse unmountOnExit in={openZip}>
               <Box
                  sx={{
                     position: "absolute",
                     bottom: 15,
                     left: "50%",
                     transform: "translateX(-50%)",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     bgcolor: "rgba(255, 255, 255, 0.8)",
                     borderRadius: 1,
                     p: 1,
                     boxShadow: 3,
                     zIndex: 1000,
                  }}
               >
                  <Button
                     variant="contained"
                     onClick={switchToPreviousFile}
                     sx={{ mx: 1 }}
                     disabled={pdfFiles.length <= 1}
                  >
                     <NavigateBefore />
                  </Button>
                  <Button variant="contained" onClick={handleZoomOut} sx={{ mx: 1 }}>
                     <Remove />
                  </Button>
                  <Typography variant="body1" sx={{ mx: 1 }}>
                     Zoom: {(zoom * 100).toFixed(0)}%
                  </Typography>
                  <Button variant="contained" onClick={handleZoomIn} sx={{ mx: 1 }}>
                     <Add />
                  </Button>
                  <Button variant="contained" onClick={switchToNextFile} sx={{ mx: 1 }} disabled={pdfFiles.length <= 1}>
                     <NavigateNext />
                  </Button>
               </Box>

               <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 20, top: 0 }}>
                  {fileAccess >= 2 && (
                     <IconButton onClick={() => downloadFile()}>
                        <Download sx={{ color: "white", fontSize: "37px" }} />
                     </IconButton>
                  )}
                  <IconButton onClick={() => setOpenZip(false)}>
                     <Close sx={{ color: "white", fontSize: "37px" }} />
                  </IconButton>
               </Box>
               <ZipPDFViewer
                  zipUrl={pdfUrl}
                  zoom={zoom}
                  currentFileIndex={currentFileIndex}
                  pdfFiles={pdfFiles}
                  setPdfFiles={setPdfFiles}
                  setCurrentFileIndex={setCurrentFileIndex}
               />
            </Collapse>

            <Collapse unmountOnExit in={openImage}>
               <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 20, top: 0 }}>
                  {fileAccess >= 2 ? (
                     <IconButton onClick={() => downloadFile()}>
                        <Download sx={{ color: "white", fontSize: "37px" }} />
                     </IconButton>
                  ) : null}
                  <IconButton onClick={() => setOpenImage(false)}>
                     <Close sx={{ color: "white", fontSize: "37px" }} />
                  </IconButton>
               </Box>
               <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 10 }}>
                  {(selectedFile && selectedFile.type === "image/svg+xml") ||
                  (selectedFile && selectedFile.type === "image/webp") ? (
                     <Stack sx={{ bgcolor: "white", p: 2 }}>
                        <Typography variant="h6">{selectedFile.originalName}</Typography>
                        <Divider sx={{ my: 1 }} />
                        <Box component="img" src={pdfUrl} sx={{ maxWidth: 600, bgcolor: "white" }} />
                     </Stack>
                  ) : (
                     <ExcelViewer excelLink={pdfUrl} style={{ height: 500 }} />
                  )}
               </Box>
            </Collapse>

            <Collapse unmountOnExit in={openVideo}>
               <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 20, top: 0 }}>
                  {fileAccess >= 2 ? (
                     <IconButton onClick={() => downloadFile()}>
                        <Download sx={{ color: "white", fontSize: "37px" }} />
                     </IconButton>
                  ) : null}
                  <IconButton onClick={() => setOpenVideo(false)}>
                     <Close sx={{ color: "white", fontSize: "37px" }} />
                  </IconButton>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     height: "100vh",
                  }}
               >
                  <VideoPlayer video={pdfUrl} name={fileNames} audio={audio} />
               </Box>
            </Collapse>

            <Collapse unmountOnExit in={openOther}>
               <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 20, top: 0 }}>
                  {fileAccess >= 2 ? (
                     <IconButton onClick={() => downloadFile()}>
                        <Download sx={{ color: "white" }} />
                     </IconButton>
                  ) : null}
                  <IconButton onClick={() => setOpenOther(false)}>
                     <Close sx={{ color: "white" }} />
                  </IconButton>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     height: "100vh",
                     width: "100vw",
                  }}
               >
                  <Box sx={{ minWidth: 1200 }}>
                     <ExcelPDF excelLink={pdfUrl} />
                  </Box>
               </Box>
            </Collapse>
         </Box>
      </Modal>
   );
};
