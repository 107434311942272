import React, { useContext, useState } from "react";
import { IServiceDetails } from "../../../../../types/BaseTypes";
import { UserContext } from "../../../../../context/userContext";
import { Box, Stack, Typography } from "@mui/material";
import { PlatformInfoComponent } from "../../../PlatformInfoComponent";
import ModuleCheck from "./ModuleCheck";
import { ModuleInfoGC } from "../../../ModuleInfoGC";
import { GoverningBodiesComponent } from "../../../GoverningBodiesComponent";
import CompanyCoordination from "./CompanyCoordination";
import { UserByModuleComponent } from "../../../UserByModuleComponent";
import { Error } from "@mui/icons-material";
import { ModuleInfoReSi } from "../../../ModuleInfoReSi";

function getService(services: any[], serviceId: string) {
   for (const service of services) {
      if (service?.serviceId === serviceId || service?.serviceId?._id === serviceId) {
         return service;
      }
   }
}

interface ServiceProps {
   service: IServiceDetails;
}

const RenderServiceContent = (props: ServiceProps) => {
   const { isOfficeAdmin, isOffice, companySelected } = useContext(UserContext);
   const [document, setDocument] = useState<{
      name: string;
      size: number;
   }>(null);
   const [shareholderBodyId, setShareholderBodyId] = useState("");
   const [bodiesSeed, setBodiesSeed] = useState(0);
   const [corporateDataCheck, setCorporateDataCheck] = useState();

   const serviceDetails = getService(companySelected?.company_details?.servicesDetails, props.service.serviceId._id);
   const [checked, setChecked] = useState<boolean>(serviceDetails !== undefined && !serviceDetails?.disable);

   const ServiceMainInfo = () => {
      switch (props.service.serviceId.service) {
         case "Canal de denuncias":
            return <PlatformInfoComponent company={companySelected || null} />;
         case "Gobierno corporativo":
         case "Beneficiario controlador":
            return (
               <ModuleInfoGC
                  document={document || null}
                  shareholdersBodyId={shareholderBodyId || ""}
                  setBodiesSeed={setBodiesSeed}
                  bodiesSeed={bodiesSeed}
                  setCorporateDataCheck={setCorporateDataCheck}
               />
            );
         case "REPSE Y SIROC":
            return (
               <ModuleInfoReSi
                  document={document || null}
                  shareholdersBodyId={shareholderBodyId || ""}
                  setBodiesSeed={setBodiesSeed}
                  bodiesSeed={bodiesSeed}
                  setCorporateDataCheck={setCorporateDataCheck}
               />
            );

         default:
            return (
               <Box
                  display="flex"
                  sx={{
                     borderBottomRightRadius: 5,
                     borderBottomLeftRadius: 5,
                     p: 4,
                     justifyContent: "center",
                     alignItems: "center",
                     columnGap: 2,
                  }}
                  boxShadow={2}
                  bgcolor="white"
               >
                  <Typography>No existe información acerca de este servicio</Typography>
                  <Error sx={{ width: "35px", height: "35px", color: "#162c44" }} />
               </Box>
            );
      }
   };

   const ServiceSecondaryInfo = () => {
      switch (props.service.serviceId.service) {
         case "Gobierno corporativo":
         case "Beneficiario controlador":
            return (
               <>
                  {corporateDataCheck && (
                     <GoverningBodiesComponent
                        setDocument={setDocument}
                        setShareholderBodyId={setShareholderBodyId}
                        bodiesSeed={bodiesSeed}
                     />
                  )}
                  {isOfficeAdmin && !isOffice && (
                     <CompanyCoordination
                        beneficiary={props.service.serviceId.service === "Beneficiario controlador"}
                     />
                  )}
               </>
            );

         default:
            return null;
      }
   };

   return (
      <>
         <Stack
            spacing={1}
            sx={{
               bgcolor: "white",
               px: 4,
               py: 2,
               boxShadow: 2,
               borderBottomLeftRadius: 4,
               borderBottomRightRadius: 4,
            }}
         >
            {isOfficeAdmin && !isOffice && (
               <ModuleCheck
                  service={{ _id: props.service.serviceId._id, name: props.service.serviceId.service }}
                  checked={checked}
                  setChecked={setChecked}
               />
            )}
            <fieldset disabled={!checked} style={{ border: "none", padding: 0 }}>
               <Stack spacing={2} sx={{ opacity: checked ? 1 : 0.5, pointerEvents: checked ? "auto" : "none" }}>
                  <ServiceMainInfo />
               </Stack>
            </fieldset>
         </Stack>
         <Stack
            spacing={2}
            sx={{
               display: checked ? "inherit" : "none",
            }}
         >
            <ServiceSecondaryInfo />
            <UserByModuleComponent company={companySelected} service={props.service} />
         </Stack>
      </>
   );
};

export default RenderServiceContent;
