import { Document, View, Page, Text, Image, Font, StyleSheet, Polygon, Svg } from "@react-pdf/renderer";

export const BillPDF = ({
   values,
   members,
   session,
   commitmentsArray,
   notes,
   comment,
   signsArray,
   affairsArray,
   additionalVotes,
   colors,
   colorsText,
   governingBodyName,
   companyLogo,
   cancelled,
   externalUsers,
   sessionResolutions,
   affairVotes,
}) => {
   const valuesKeys = Object.keys(values);
   let commitmentObject = [];
   let numberOfCommitments = 0;
   let otherSubjectsArray = [];

   valuesKeys.forEach((key, index) => {
      const matchActivity = key.match(/activity(\d+)/);
      const matchResponsableName = key.match(/otherSubject(\d+)/);
      if (matchActivity) numberOfCommitments++;
      else if (matchResponsableName) otherSubjectsArray.push(values[key]);
   });
   for (let index = 0; index < numberOfCommitments; index++) {
      commitmentObject.push(index);
   }

   const formattedDate = new Date(session.startTime).toLocaleDateString();
   const formattedHourStart =
      new Date(session.startTime).getHours() +
      ":" +
      (new Date(session.startTime).getMinutes() < 10 ? "0" : "") +
      new Date(session.startTime).getMinutes();
   const finishingHour =
      new Date().getHours() + ":" + (new Date().getMinutes() < 10 ? "0" : "") + new Date().getMinutes();
   const internalMembers = members.filter((member) => {
      if (member.firstName)
         if (
            !member.memberCharge.includes("Usuario de implementacion") &&
            !member.memberCharge.includes("Coordinador de Gobierno Corporativo") &&
            member.memberCharge.length === 1
         )
            return member;
   });

   //#region repetitiveContainers

   const AdditionalVotationsContainer = (addVote, hasOrderId) => {
      return (
         <View style={{ width: "100%", display: "flex" }}>
            {!hasOrderId && (
               <Text
                  style={{
                     width: "100%",
                     textAlign: "center",
                     fontSize: "10",
                     paddingVertical: "1",
                     fontFamily: "open-sans-bold",
                  }}
               >
                  Votos no asociados
               </Text>
            )}
            <View key={addVote.title} style={{ borderBottom: 1, borderColor: colors.secondary }} wrap={false}>
               <View style={{ border: 1, borderColor: "#EFEFEF", paddingHorizontal: 20, paddingVertical: 10 }}>
                  <Text style={{ fontSize: "10" }}>{addVote.title}</Text>
               </View>
               <View style={{ display: "flex", flexDirection: "row", flexGrow: 1, flexWrap: "wrap" }}>
                  {addVote.canceled ? (
                     <View style={{ width: "100%", display: "flex" }}>
                        <Text style={{ textAlign: "center", fontSize: "11", fontFamily: "open-sans-bold" }}>
                           Votación cancelada
                        </Text>
                     </View>
                  ) : (
                     addVote.votes.map((shareholder) => {
                        return votationAnswerContainer(
                           shareholder.userName,
                           shareholder.abstention ? "ABS" : shareholder.answer || "PEN"
                        );
                     })
                  )}
               </View>
            </View>
         </View>
      );
   };

   const votationAnswerContainer = (user, answer) => {
      return (
         <View style={{ display: "flex", width: "24%", marginBottom: 5, marginRight: 5 }} wrap={false}>
            <Text
               style={{
                  fontSize: "8",
                  border: 1,
                  borderColor: "#EFEFEF",
                  backgroundColor: "#EFEFEF",
                  padding: 3,
                  textAlign: "center",
                  fontFamily: "open-sans",
                  flexGrow: 1,
               }}
            >
               {user}
            </Text>
            <View
               style={{
                  display: "flex",
                  justifyContent: "center",
                  border: 1,
                  borderColor: "#EFEFEF",
                  fontFamily: "open-sans",
               }}
            >
               <Text
                  style={{
                     fontSize: "8",
                     textAlign: "center",
                     paddingVertical: 5,
                     fontFamily: "open-sans",
                  }}
               >
                  {answer}
               </Text>
            </View>
         </View>
      );
   };

   //#endregion

   return (
      <Document>
         <Page style={styles.body}>
            {/*HEADER*/}
            <View
               style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10",
               }}
               fixed
            >
               <Image style={{ width: 52, marginHorizontal: "10" }} src={companyLogo} />
               <View style={{ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" }}>
                  <Text style={{ fontSize: "12" }}>MINUTA DE SESIÓN</Text>
                  <View
                     style={{
                        backgroundColor: colors.secondary,
                        padding: "4",
                        borderTopLeftRadius: "4",
                        borderBottomRightRadius: "4",
                     }}
                  >
                     <Text style={{ fontSize: "12", textAlign: "center", color: colorsText.secondary }}>
                        {session.billCode}
                     </Text>
                  </View>
               </View>
            </View>
            <Text
               style={{
                  textAlign: "center",
                  marginBottom: "10",
                  fontFamily: "open-sans-bold",
                  fontWeight: "bold",
                  fontSize: "15",
               }}
            >
               {governingBodyName}
            </Text>
            {/*HORAS INICIALES Y HORAS FINAL*/}
            <View style={{ border: "1" }}>
               <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <View
                     style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "50%", gap: "4" }}
                  >
                     <View style={{ justifyContent: "center", width: "50%" }}>
                        <Text style={{ fontSize: "12", fontWeight: "bold", textAlign: "right" }}>Fecha:</Text>
                     </View>
                     <View style={{ backgroundColor: colors.primary, width: "50%", justifyContent: "center" }}>
                        <Text style={{ fontSize: "12", color: colorsText.primary, paddingHorizontal: "7" }}>
                           {formattedDate}
                        </Text>
                     </View>
                  </View>
                  <View
                     style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "50%", gap: "4" }}
                  >
                     <View style={{ justifyContent: "center", width: "50%" }}>
                        <Text style={{ fontSize: "12", fontWeight: "bold", textAlign: "right" }}>Lugar:</Text>
                     </View>
                     <View
                        style={{
                           backgroundColor: colors.primary,
                           width: "50%",
                           justifyContent: "center",
                           padding: "",
                        }}
                     >
                        <Text
                           style={{
                              fontSize: "12",
                              color: colorsText.primary,
                              paddingHorizontal: "7",
                              paddingVertical: "6",
                           }}
                        >
                           {session.location}
                        </Text>
                     </View>
                  </View>
               </View>
               {/*HORAS INICIALES Y HORAS FINAL*/}
               <View
                  style={{
                     display: "flex",
                     flexDirection: "row",
                     borderTop: "1",
                     borderColor: "white",
                     justifyContent: "space-between",
                  }}
               >
                  <View
                     style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "50%", gap: "4" }}
                  >
                     <View style={{ justifyContent: "center", width: "50%" }}>
                        <Text style={{ fontSize: "12", fontWeight: "bold", textAlign: "right" }}>Hora Inicial:</Text>
                     </View>
                     <View style={{ backgroundColor: colors.primary, width: "50%", justifyContent: "center" }}>
                        <Text
                           style={{
                              fontSize: "12",
                              color: colorsText.primary,
                              paddingHorizontal: "7",
                              paddingVertical: "6",
                           }}
                        >
                           {formattedHourStart}
                        </Text>
                     </View>
                  </View>
                  <View
                     style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "50%", gap: "4" }}
                  >
                     <View style={{ justifyContent: "center", width: "50%" }}>
                        <Text style={{ fontSize: "12", fontWeight: "bold", textAlign: "right" }}>Hora Final:</Text>
                     </View>
                     <View
                        style={{
                           backgroundColor: colors.primary,
                           width: "50%",
                           justifyContent: "center",
                           padding: "",
                        }}
                     >
                        <Text
                           style={{
                              fontSize: "12",
                              color: colorsText.primary,
                              paddingHorizontal: "7",
                              paddingVertical: "6",
                           }}
                        >
                           {finishingHour}
                        </Text>
                     </View>
                  </View>
               </View>
            </View>
            {/*CONVOCADOS A SESION*/}
            <View
               style={{
                  display: "flex",
                  backgroundColor: colors.primary,
                  marginTop: "17",
                  alignItems: "center",
                  paddingVertical: "3",
               }}
            >
               <Text style={{ textAlign: "center", color: colorsText.primary, fontSize: "12" }}>
                  CONVOCADOS A SESIÓN
               </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
               <Text
                  style={{
                     textAlign: "center",
                     fontSize: "13",
                     paddingVertical: "5",
                     width: "50%",
                     fontFamily: "open-sans-bold",
                     fontWeight: "bold",
                     backgroundColor: "#efefef",
                     borderRight: "1",
                     borderBottom: "1",
                     borderColor: "white",
                  }}
               >
                  PARTICIPANTES
               </Text>
               <Text
                  style={{
                     textAlign: "center",
                     fontSize: "13",
                     paddingVertical: "5",
                     width: "50%",
                     fontFamily: "open-sans-bold",
                     fontWeight: "bold",
                     backgroundColor: "#efefef",
                     borderLeft: "1",
                     borderBottom: "1",
                     borderColor: "white",
                  }}
               >
                  {/* CARGO DENTRO DEL COMITÉ */}
                  CARGO
               </Text>
            </View>
            {/*MAPEO DE PARTICIPANTES*/}
            {internalMembers.map((member) => (
               <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text
                     style={{
                        textAlign: "center",
                        fontSize: "10",
                        paddingVertical: "6",
                        width: "50%",
                        backgroundColor: "#efefef",
                        borderRight: "1",
                        borderBottom: "1",
                        borderColor: "white",
                     }}
                  >
                     {member.firstName + " " + member.lastName}
                  </Text>
                  <Text
                     style={{
                        textAlign: "center",
                        fontSize: "10",
                        paddingVertical: "6",
                        width: "50%",
                        backgroundColor: "#efefef",
                        borderLeft: "1",
                        borderBottom: "1",
                        borderColor: "white",
                     }}
                  >
                     {member.memberCharge.filter((charge) => !charge.toLowerCase().includes("coordinador"))}
                  </Text>
               </View>
            ))}
            {/*INVITADOS*/}
            {externalUsers.length > 0 && (
               <View
                  style={{
                     display: "flex",
                     backgroundColor: colors.secondary,
                     alignItems: "center",
                     paddingVertical: "3",
                  }}
               >
                  <Text style={{ textAlign: "center", color: colorsText.secondary, fontSize: "12" }}>INVITADOS</Text>
               </View>
            )}
            {/*MAPEO DE INVITADOS*/}
            {externalUsers.map((member) => (
               <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text
                     style={{
                        textAlign: "center",
                        fontSize: "10",
                        paddingVertical: "6",
                        width: "50%",
                        backgroundColor: "#efefef",
                        borderRight: "1",
                        borderBottom: "1",
                        borderColor: "white",
                     }}
                  >
                     {member?.firstName ? `${member.firstName} ${member.lastName}` : member?.name || member.email}
                  </Text>
                  <Text
                     style={{
                        textAlign: "center",
                        fontSize: "10",
                        paddingVertical: "6",
                        width: "50%",
                        backgroundColor: "#efefef",
                        borderLeft: "1",
                        borderBottom: "1",
                        borderColor: "white",
                     }}
                  >
                     {(member?.memberCharge && member?.memberCharge[0]) || `Usuario Externo`}
                  </Text>
               </View>
            ))}
            {/*ORDEN DEL DIA*/}
            <View
               style={{
                  display: "flex",
                  marginTop: "17",
                  alignItems: "center",
                  paddingVertical: "3",
               }}
            >
               <Text style={{ textAlign: "center", fontSize: "12", fontFamily: "open-sans-bold", fontWeight: "bold" }}>
                  ORDEN DEL DÍA
               </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
               <Text
                  style={{
                     fontSize: "12",
                     paddingRight: "4.5",
                     width: "47%",
                     backgroundColor: colors.primary,
                     textAlign: "center",
                     color: colorsText.primary,
                     paddingVertical: 3,
                  }}
               >
                  ASUNTO
               </Text>
               <Text
                  style={{
                     fontSize: "12",
                     paddingRight: "4.5",
                     width: "47%",
                     backgroundColor: colors.primary,
                     textAlign: "center",
                     color: colorsText.primary,
                     paddingVertical: 3,
                  }}
               >
                  DESCRIPCIÓN
               </Text>
               <Text
                  style={{
                     paddingRight: "4.5",
                     width: "6%",
                     backgroundColor: colors.primary,
                     paddingVertical: 3,
                  }}
               />
            </View>
            {/*MAPEO DE ORDENES DEL DIA*/}
            {affairsArray.map((order, index) => (
               <View style={{ marginTop: "0.5", borderBottom: "1", borderColor: "white" }}>
                  <View
                     style={{
                        display: "flex",
                        flexDirection: "row",
                        borderBottom: "1",
                        borderColor: "white",
                        alignItems: "center",
                     }}
                  >
                     <Text
                        style={{
                           textAlign: "right",
                           fontSize: "10",
                           paddingRight: "4.5",
                           width: "4%",
                           backgroundColor: "#efefef",
                           fontFamily: "open-sans-bold",
                           height: "100%",
                           paddingVertical: 10,
                        }}
                     >
                        {index + 1}
                     </Text>
                     <Text
                        style={{
                           fontSize: "10",
                           width: "45%",
                           backgroundColor: "#efefef",
                           paddingLeft: "4",
                           height: "100%",
                           paddingVertical: 10,
                        }}
                     >
                        {order.title}
                     </Text>
                     <Text
                        style={{
                           fontSize: "10",
                           width: "45%",
                           backgroundColor: "#efefef",
                           paddingLeft: "4",
                           height: "100%",
                           paddingVertical: 10,
                        }}
                     >
                        {order.description}
                     </Text>
                     <View
                        style={{
                           backgroundColor: "#efefef",
                           width: "6%",
                           height: "100%",
                           borderLeft: 1,
                           borderColor: "white",
                           paddingVertical: 10,
                        }}
                     >
                        <Svg style={styles.checkbox}>
                           <Polygon points="8,4 8,16.5 20,16.5 20,4" stroke="black" strokeWidth={1} />
                           {/*CHECKBOX PALOMEADA*/}
                           {values["orderDay-" + order.orderId] && (
                              <>
                                 <Polygon points="10,12 12.5,14" stroke="black" strokeWidth={1} />
                                 <Polygon points="12.5,14 18,7" stroke="black" strokeWidth={1} />
                              </>
                           )}
                        </Svg>
                     </View>
                  </View>
               </View>
            ))}
            DELIBERACIONES
            <View style={{ display: "flex", flexDirection: "column", marginTop: "17" }}>
               <Text
                  style={{
                     width: "100%",
                     color: colorsText.secondary,
                     backgroundColor: colors.secondary,
                     textAlign: "center",
                     fontSize: "10",
                     paddingVertical: "3",
                     fontFamily: "open-sans-bold",
                  }}
               >
                  DELIBERACIONES
               </Text>
               <View style={{ border: 1, borderColor: "#EFEFEF" }}>
                  {affairVotes.map((order) => {
                     const linkedVotes = additionalVotes.filter((addVote) => addVote.orderId === order.affair.orderId);
                     return (
                        <View key={order.title} style={{ borderBottom: 1, borderColor: colors.secondary }}>
                           <View
                              style={{
                                 border: 1,
                                 borderColor: "#EFEFEF",
                                 paddingHorizontal: 20,
                                 paddingVertical: 10,
                              }}
                           >
                              <Text style={{ fontSize: "10" }}>{order.affair.description}</Text>
                           </View>
                           <View style={{ display: "flex", flexDirection: "row", flexGrow: 1, flexWrap: "wrap" }}>
                              {order.totalVotesUsed > 0 &&
                                 internalMembers.map((shareholder) => {
                                    const founuserInVotation = order.users.find(
                                       (user) => user.user === shareholder._id
                                    );
                                    return votationAnswerContainer(
                                       `${shareholder.firstName} ${shareholder.lastName}`,
                                       founuserInVotation.abstention ? "ABS" : founuserInVotation.answer || "PEN"
                                    );
                                 })}
                           </View>
                           {linkedVotes.length > 0 && (
                              <View>
                                 <View
                                    style={{
                                       borderTop: 1,
                                       borderBottom: 1,
                                       borderColor: "#EFEFEF",
                                       paddingHorizontal: 20,
                                       paddingVertical: 5,
                                    }}
                                 >
                                    <Text
                                       style={{
                                          width: "100%",
                                          textAlign: "center",
                                          fontSize: "10",
                                          paddingVertical: "1",
                                          fontFamily: "open-sans-bold",
                                       }}
                                    >
                                       Votaciones asociadas
                                    </Text>
                                 </View>
                                 {linkedVotes
                                    .filter((addVote) => addVote.orderId)
                                    .map((addVote) => AdditionalVotationsContainer(addVote, true))}
                              </View>
                           )}
                        </View>
                     );
                  })}
                  {additionalVotes
                     .filter((addVote) => !addVote.orderId)
                     .map((addVote) => AdditionalVotationsContainer(addVote, false))}
               </View>
            </View>
            {/*RESOLUCIONES*/}
            <View style={{ marginTop: 17 }} wrap={false}>
               <Text style={{ textAlign: "center", fontSize: "10", fontFamily: "open-sans-bold", paddingBottom: 3 }}>
                  RESOLUCIONES
               </Text>
               <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text
                     style={{
                        width: "33%",
                        textAlign: "center",
                        fontSize: "10",
                        fontFamily: "open-sans-bold",
                        padding: 5,
                        color: colorsText.primary,
                        backgroundColor: colors.primary,
                     }}
                  >
                     ASUNTO
                  </Text>
                  <Text
                     style={{
                        width: "33%",
                        textAlign: "center",
                        fontSize: "10",
                        fontFamily: "open-sans-bold",
                        padding: 5,
                        color: colorsText.primary,
                        backgroundColor: colors.primary,
                     }}
                  >
                     DESCRIPCIÓN
                  </Text>
                  <Text
                     style={{
                        width: "33%",
                        textAlign: "center",
                        fontSize: "10",
                        fontFamily: "open-sans-bold",
                        padding: 5,
                        color: colorsText.primary,
                        backgroundColor: colors.primary,
                     }}
                  >
                     RESOLUCIÓN
                  </Text>
               </View>

               {sessionResolutions
                  .map((order) => {
                     if (order.resolution.includes("no completada")) return;
                     return (
                        <View style={{ display: "flex", flexDirection: "row" }} wrap={false}>
                           <Text
                              style={{
                                 width: "33%",
                                 textAlign: "center",
                                 fontSize: "8",
                                 padding: 5,
                                 backgroundColor: "#EFEFEF",
                              }}
                           >
                              {order.title}
                           </Text>
                           <Text
                              style={{
                                 width: "33%",
                                 textAlign: "center",
                                 fontSize: "8",
                                 padding: 5,
                                 backgroundColor: "#EFEFEF",
                              }}
                           >
                              {order.description}
                           </Text>
                           <Text
                              style={{
                                 width: "33%",
                                 textAlign: "center",
                                 fontSize: "8",
                                 padding: 5,
                                 backgroundColor: "#EFEFEF",
                              }}
                           >
                              {order.resolution}
                           </Text>
                        </View>
                     );
                  })
                  .filter((v) => v)}
            </View>
            {/*ACUERDOS Y/O COMPROMISOS*/}
            {commitmentsArray.length !== 0 && (
               <>
                  <View style={{ display: "flex", marginTop: "17", alignItems: "center", paddingVertical: "3" }}>
                     <Text
                        style={{
                           textAlign: "center",
                           fontSize: "12",
                           fontFamily: "open-sans-bold",
                           fontWeight: "bold",
                        }}
                     >
                        ACUERDOS Y/O COMPROMISOS
                     </Text>
                  </View>
                  {/* HEADER DE ACUERDOS Y/O COMPROMISOS */}
                  <View style={{ display: "flex", flexDirection: "row" }}>
                     <View
                        style={{
                           display: "flex",
                           backgroundColor: colors.primary,
                           alignItems: "center",
                           paddingVertical: "3",
                           width: "33%",
                        }}
                     >
                        <Text style={{ textAlign: "center", color: colorsText.primary, fontSize: "10" }}>
                           RESPONSABLE
                        </Text>
                     </View>
                     <View
                        style={{
                           display: "flex",
                           backgroundColor: colors.primary,
                           alignItems: "center",
                           paddingVertical: "3",
                           width: "33%",
                        }}
                     >
                        <Text style={{ textAlign: "center", color: colorsText.primary, fontSize: "10" }}>
                           ACTIVIDAD
                        </Text>
                     </View>
                     <View
                        style={{
                           display: "flex",
                           backgroundColor: colors.primary,
                           alignItems: "center",
                           paddingVertical: "3",
                           width: "33%",
                        }}
                     >
                        <Text style={{ textAlign: "center", color: colorsText.primary, fontSize: "10" }}>
                           FECHA COMPROMISO
                        </Text>
                     </View>
                  </View>
               </>
            )}
            {/*MAPEO DE ACUERDOS Y/O COMPROMISOS */}
            {commitmentsArray.map((element, index) => {
               const formattedDate = new Date(element.limitDate).toLocaleDateString();
               return (
                  <View style={{ display: "flex", flexDirection: "row" }}>
                     <View
                        style={{
                           width: "33%",
                           backgroundColor: "#efefef",
                           paddingVertical: "6",
                           borderRight: "1",
                           borderBottom: "1",
                           borderColor: "white",
                           justifyContent: "center",
                        }}
                     >
                        <Text style={{ textAlign: "center", fontSize: "10" }}>
                           {element.users
                              .map((user) => `${user.firstName || user?.email} ${user.lastName || ""}`)
                              .join(", ")}
                        </Text>
                     </View>
                     <View
                        style={{
                           width: "33%",
                           backgroundColor: "#efefef",
                           paddingVertical: "6",
                           borderRight: "1",
                           borderBottom: "1",
                           borderColor: "white",
                           justifyContent: "center",
                        }}
                     >
                        <Text style={{ textAlign: "center", fontSize: "10" }}>{element.activity}</Text>
                     </View>
                     <View
                        style={{
                           width: "33%",
                           backgroundColor: "#efefef",
                           paddingVertical: "6",
                           borderBottom: "1",
                           borderColor: "white",
                           justifyContent: "center",
                        }}
                     >
                        <Text style={{ textAlign: "center", fontSize: "10" }}>{formattedDate}</Text>
                     </View>
                  </View>
               );
            })}
            {/*FIRMA DE ACUERDOS*/}
            <View
               style={{
                  display: "flex",
                  backgroundColor: colors.secondary,
                  alignItems: "center",
                  paddingVertical: "3",
                  marginTop: "17",
               }}
            >
               <Text style={{ textAlign: "center", color: colorsText.secondary, fontSize: "12" }}>
                  FIRMA DE ACUERDOS
               </Text>
            </View>
            {/*HEADERS FIRMA DE ACUERDOS*/}
            <View style={{ display: "flex", flexDirection: "row" }}>
               <View
                  style={{
                     width: "60%",
                     paddingVertical: "6",
                     borderRight: "1",
                     borderBottom: "1",
                     borderColor: "white",
                     justifyContent: "center",
                  }}
               >
                  <Text
                     style={{ textAlign: "center", fontSize: "10", fontFamily: "open-sans-bold", fontWeight: "bold" }}
                  >
                     NOMBRE
                  </Text>
               </View>
               <View
                  style={{
                     width: "40%",
                     paddingVertical: "6",
                     borderRight: "1",
                     borderBottom: "1",
                     borderColor: "white",
                     justifyContent: "center",
                  }}
               >
                  <Text
                     style={{ textAlign: "center", fontSize: "10", fontFamily: "open-sans-bold", fontWeight: "bold" }}
                  >
                     FIRMA
                  </Text>
               </View>
            </View>
            {/*MAPEO DE FIRMAS*/}
            {signsArray.map((signs) => {
               return (
                  <View style={{ display: "flex", flexDirection: "row" }}>
                     <View
                        style={{
                           width: "60%",
                           paddingVertical: "6",
                           borderRight: "1",
                           borderBottom: "1",
                           borderColor: "white",
                           justifyContent: "center",
                           backgroundColor: "#efefef",
                        }}
                     >
                        <Text style={{ textAlign: "center", fontSize: "10" }}>{signs.userName}</Text>
                     </View>
                     <View
                        style={{
                           width: "40%",
                           paddingVertical: "6",
                           borderBottom: "1",
                           borderColor: "white",
                           justifyContent: "center",
                           backgroundColor: "#efefef",
                        }}
                     >
                        <Image
                           src={signs.sign}
                           style={{
                              marginHorizontal: "10%",
                              maxWidth: "auto",
                              maxHeight: "60px",
                              objectFit: "contain",
                           }}
                        />
                     </View>
                  </View>
               );
            })}
            {/*OTROS ASUNTOS*/}
            {otherSubjectsArray.length > 0 && (
               <View
                  style={{
                     display: "flex",
                     marginTop: "17",
                     alignItems: "center",
                     paddingVertical: "3",
                     borderBottom: "3",
                     borderColor: colors.secondary,
                  }}
               >
                  <Text
                     style={{
                        textAlign: "center",
                        fontSize: "12",
                        fontFamily: "open-sans-bold",
                        fontWeight: "bold",
                     }}
                  >
                     OTROS ASUNTOS
                  </Text>
               </View>
            )}
            {/*MAPEO DE OTROS ASUNTOS*/}
            {otherSubjectsArray.map((otherSubject, index) => {
               if (otherSubject === "") return;
               return (
                  <View
                     wrap={false}
                     style={{
                        display: "flex",
                        flexDirection: "row",
                        borderBottom: "1",
                        borderColor: "white",
                        gap: "2",
                        alignItems: "center",
                        paddingVertical: "3",
                     }}
                  >
                     <Text
                        style={{
                           textAlign: "right",
                           fontSize: "10",
                           paddingVertical: "4",
                           paddingRight: "5",
                           width: "8%",
                           backgroundColor: "#efefef",
                           fontFamily: "open-sans-bold",
                           fontWeight: "bold",
                        }}
                     >
                        {index + 1}.
                     </Text>
                     <Text
                        style={{
                           fontSize: "10",
                           paddingVertical: "6",
                           width: "92%",
                           backgroundColor: "#efefef",
                           paddingLeft: "10",
                        }}
                     >
                        {otherSubject}
                     </Text>
                  </View>
               );
            })}
            {/*NOTAS*/}
            {notes.length > 0 && (
               <>
                  <View
                     style={{
                        display: "flex",
                        backgroundColor: colors.primary,
                        alignItems: "center",
                        paddingVertical: "3",
                     }}
                  >
                     <Text style={{ textAlign: "center", color: colorsText.primary, fontSize: "12" }}>NOTAS</Text>
                  </View>
                  <View style={{ minHeight: "100", backgroundColor: "#efefef" }}>
                     <Text style={{ fontSize: "12", margin: "4" }}>{notes}</Text>
                  </View>
               </>
            )}
            {/*COMENTARIOS*/}
            {comment.length > 0 && (
               <>
                  <View
                     style={{
                        display: "flex",
                        backgroundColor: colors.primary,
                        alignItems: "center",
                        paddingVertical: "3",
                     }}
                  >
                     <Text style={{ textAlign: "center", color: colorsText.primary, fontSize: "12" }}>
                        COMENTARIOS CLAVE DEL PRESIDENTE / VICEPRESIDENTE
                     </Text>
                  </View>
                  <View style={{ minHeight: "100", backgroundColor: "#efefef" }}>
                     {comment.map((c, i) => (
                        <Text style={{ fontSize: "12", margin: "4" }}>{`${i + 1}. ${c}`}</Text>
                     ))}
                  </View>
               </>
            )}
            {session.completed === false && cancelled !== true ? (
               <Text
                  style={{
                     position: "absolute",
                     top: "50%",
                     left: "15%",
                     fontSize: 45,
                     color: "red",
                     border: 6,
                     borderColor: "red",
                     borderRadius: "15",
                     fontWeight: 600,
                     padding: "15",
                     textAlign: "center",
                     transform: "rotate(-45deg)",
                  }}
                  fixed
               >
                  SESIÓN INVÁLIDA
               </Text>
            ) : (
               cancelled === true && (
                  <Text
                     style={{
                        position: "absolute",
                        top: "10%",
                        left: "37.5%",
                        fontSize: 20,
                        color: "red",
                        border: 3,
                        borderColor: "red",
                        borderRadius: "15",
                        fontWeight: 600,
                        padding: "10",
                        textAlign: "center",
                     }}
                     fixed
                  >
                     SESIÓN CANCELADA
                  </Text>
               )
            )}
            <View style={styles.pageNumber} fixed>
               <Text
                  style={{ fontSize: "12px", textAlign: "center" }}
                  render={({ pageNumber }) => `${pageNumber}`}
                  fixed
               />
            </View>
         </Page>
      </Document>
   );
};

Font.register({
   family: "open-sans",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Regular.ttf",
});

Font.register({
   family: "open-sans-bold",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Bold.ttf",
});

const styles = StyleSheet.create({
   body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
   },
   checkbox: {
      height: 20,
      width: "100%",
   },
   pageNumber: {
      position: "absolute",
      right: 30,
      bottom: 30,
   },
   // title: {
   //    fontSize: 24,
   //    textAlign: "center",
   //    fontFamily: "Oswald",
   // },
   // author: {
   //    fontSize: 12,
   //    textAlign: "center",
   //    marginBottom: 40,
   // },
   // subtitle: {
   //    fontSize: 18,
   //    margin: 12,
   //    fontFamily: "Oswald",
   // },
   // text: {
   //    margin: 12,
   //    fontSize: 14,
   //    textAlign: "justify",
   //    fontFamily: "Times-Roman",
   // },
   // image: {
   //    marginVertical: 15,
   //    marginHorizontal: 100,
   // },
   // header: {
   //    fontSize: 12,
   //    marginBottom: 20,
   //    textAlign: "center",
   //    color: "grey",
   // },
   // pageNumber: {
   //    position: "absolute",
   //    fontSize: 12,
   //    bottom: 30,
   //    left: 0,
   //    right: 0,
   //    textAlign: "center",
   //    color: "grey",
   // },
});
