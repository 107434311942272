import { createContext, useContext, useEffect, useMemo, useReducer, useState } from "react";
import {
   IAdditionalVotes,
   IAffairs,
   IAffairVotation,
   IMembersWithCharges,
   IResolutions,
   ISession,
} from "../../types/governance.types";
import { createExternalSessionToken, getSessionByIdExternal } from "../../lib/gobCorpBEClient";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { Companies } from "../../types/BaseTypes";
import { SnackBarContext } from "../snackBarContext";
import { getUrlForDocumentsListGCPrefix } from "../../lib/usersBEClient";

interface ISessionContext {
   session: ISession;
   setSession: Function;
   externalSessionToken: string;
   affairsArray: IAffairs[];
   isLoading: boolean;
   setIsLoading: Function;
   isVerified: boolean;
   setIsVerified: Function;
   socket: any;
   userId: string;
   isRejected: boolean;
   setIsRejected: Function;
   isWaitingToVerify: boolean;
   setIsWaitingToVerify: Function;
   colors: { primary: string; secondary: string };
   externalMemberUsers: { user: string; email: string; name?: string; status: string; attended: boolean }[];
   fileArray: any[];
   setFileArray: Function;
   initialValues: any;
   membersWithCharge: IMembersWithCharges[];
   governingBody: any;
   setGoverningBody: Function;
   valuesFromBill: any;
   setValuesFromBill: Function;
   quorum: { attendance: number; vote: number };
   setQuorum: Function;
   usersOnline: any[];
   setUsersOnline: Function;
   groupCompaniesInSession: Companies[];
   additionalVotes: IAdditionalVotes[];
   affairVotations: any[];
   userOnlineSigns: { firstName: string; name?: string; lastName: string; _id: string }[];
   attendedPercentage: number;
   setAttendedPercentage: Function;
   completedSession: boolean;
   canceledSession: boolean;
   openModalToSign: boolean;
   setOpenModalToSign: Function;
   openInputNameModal: boolean;
   setOpenInputNameModal: Function;
   documentUrl: string;
   setDocumentUrl: Function;
   sessionResolutions: IResolutions[];
   signArray: string[];
   setSignArray: Function;
   fetchSignArrayList: Function;
}

export const ExternalGovernanceSessionContext = createContext<ISessionContext>({
   session: null,
   setSession: () => {},
   externalSessionToken: null,
   affairsArray: [],
   isLoading: true,
   setIsLoading: () => {},
   isVerified: false,
   setIsVerified: () => {},
   socket: null,
   userId: null,
   isRejected: false,
   setIsRejected: () => {},
   isWaitingToVerify: false,
   setIsWaitingToVerify: () => {},
   colors: null,
   externalMemberUsers: [],
   fileArray: [],
   setFileArray: () => {},
   initialValues: null,
   membersWithCharge: null,
   governingBody: null,
   setGoverningBody: () => {},
   valuesFromBill: null,
   setValuesFromBill: () => {},
   quorum: null,
   setQuorum: () => {},
   usersOnline: [],
   setUsersOnline: () => {},
   groupCompaniesInSession: [],
   additionalVotes: [],
   affairVotations: [],
   userOnlineSigns: [],
   attendedPercentage: 0,
   setAttendedPercentage: () => {},
   completedSession: false,
   canceledSession: false,
   openModalToSign: false,
   setOpenModalToSign: () => {},
   openInputNameModal: false,
   setOpenInputNameModal: () => {},
   documentUrl: null,
   setDocumentUrl: () => {},
   sessionResolutions: null,
   signArray: [],
   setSignArray: () => {},
   fetchSignArrayList: () => {},
});

export const ExternalGovernanceSessionProvider = ({ children }) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const [session, setSession] = useState<ISession>(null);
   const [externalSessionToken, setExternalSessionToken] = useState(null);
   const [affairsArray, setaffairsArray] = useState([]);
   const { sessionId, userId } = useParams();
   const [socket, setSocket] = useState(null);
   const [isLoading, setIsLoading] = useState(true);
   const [isVerified, setIsVerified] = useState(false);
   const [isRejected, setIsRejected] = useState(false);
   const [isWaitingToVerify, setIsWaitingToVerify] = useState(false);
   const [colors, setColors] = useState(null);
   const [fileArray, setFileArray] = useState([]);
   const [initialValues, setInitialValues] = useState(null);
   const [membersWithCharge, setMembersWithCharge] = useState([]);
   const [governingBody, setGoverningBody] = useState(null);
   const [valuesFromBill, setValuesFromBill] = useState(null);
   const [quorum, setQuorum] = useState(null);
   const [usersOnline, setUsersOnline] = useState([]);
   const [completedSession, setCompletedSession] = useState(false);
   const [canceledSession, setCanceledSession] = useState(false);
   const [attendedPercentage, setAttendedPercentage] = useState(0);
   const [groupCompaniesInSession, setGroupCompaniesInSession] = useState<Companies[]>([]);
   const [openModalToSign, setOpenModalToSign] = useState(false);
   const [openInputNameModal, setOpenInputNameModal] = useState(false);
   const [documentUrl, setDocumentUrl] = useState(null);
   const [resolutionSeed, setResolutionsSeed] = useState(0);
   const [{ additionalVotes, affairVotations }, dispatch] = useReducer(reducer, {
      additionalVotes: [],
      affairVotations: [],
   });
   const [signArray, setSignArray] = useState([]);

   const fetchSignArrayList = async () => {
      if (!session) return [];
      const s3SignArray = await getUrlForDocumentsListGCPrefix(
         "files-lecosy",
         `gc/companies/${session?.company}/governing-body/sessions/${session._id}`,
         "sign",
         externalSessionToken
      );
      if (!s3SignArray.Contents) return [];
      const signArray =
         s3SignArray.Contents?.reduce((array, key) => {
            const keySplit = key.Key.split("sign-");
            if (keySplit.length < 2) return array;
            const idSplit = key.Key.split("sign-")[1]; //Separates 'sign-' from key
            return [...array, idSplit.substring(0, idSplit.length - 4)]; //cuts userid from png extension
         }, []) || [];
      return signArray;
   };

   useEffect(() => {
      const fetchInitialSignList = async () => {
         const list = await fetchSignArrayList();
         setSignArray(list);
      };
      if (!session && !externalSessionToken) return;
      fetchInitialSignList();
   }, [session, externalSessionToken]);

   const base_url =
      window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
         ? "https://test.server.lecosy.com.mx"
         : process.env.NODE_ENV === "production"
         ? "https://server.lecosy.com.mx"
         : "http://localhost:8003";

   useEffect(() => {
      try {
         const socketServer = io(base_url, {
            path:
               process.env.NODE_ENV === "production" ||
               window.location.hostname === "test.web.lecosy.com.mx" ||
               window.location.hostname === "www.test.web.lecosy.com.mx"
                  ? "/gc/socket.io"
                  : "/socket.io",
            port:
               process.env.NODE_ENV === "production" ||
               window.location.hostname === "test.web.lecosy.com.mx" ||
               window.location.hostname === "www.test.web.lecosy.com.mx"
                  ? 80
                  : 8003,
            withCredentials: true,
         });
         setSocket(socketServer);
         return () => {
            socketServer.disconnect();
         };
      } catch (e) {
         setSocket(null);
      }
   }, [base_url]);

   function reducer(state, action) {
      switch (action.type) {
         //#region affairVotationState
         case "affairsInitialState":
            return { ...state, affairVotations: action.affairVotations };
         case "voteUserInAffairVotation": {
            return voteUserInAffairVotation(state, action.orderInfo, action.company);
         }
         case "updateAffairVoteInVoteStatus": {
            const tempVote = state.affairVotations;
            if (session.group) {
               const foundOrderIndex = tempVote.findIndex((order) => order.affair.orderId === action.orderId);
               const foundCompanyIndex = tempVote[foundOrderIndex].companies.findIndex(
                  (company) => action.orderInfo.company === company.company
               );
               tempVote[foundOrderIndex].companies[foundCompanyIndex] = action.orderInfo;
            } else {
               const foundIndexByOrder = tempVote.findIndex(
                  (vote) => vote.affair.orderId === action.orderInfo.affair.orderId
               );
               tempVote[foundIndexByOrder] = action.orderInfo;
            }
            return {
               ...state,
               affairsVotation: tempVote,
            };
         }
         //#endregion
         //#region additionalVotesState
         case "additionalVotesUpdateAll":
            return { ...state, additionalVotes: action.additionalVotes };
         case "addNewAdditionalVote":
            return {
               ...state,
               additionalVotes: [...state.additionalVotes, action.newAdditionalVote],
            };
         case "updateSpecificVote": {
            return updateSpecificVote(state, action);
         }
         case "overWriteAdditionalVote": {
            const tempAdditionalVotes = state.additionalVotes;
            const foundIndex = tempAdditionalVotes.findIndex((vote) => vote._id === action.additionalVote._id);
            if (foundIndex === -1) tempAdditionalVotes.push(action.additionalVote);
            else tempAdditionalVotes[foundIndex] = action.additionalVote;
            return { ...state, additionalVotes: tempAdditionalVotes };
         }
         //#endregion
      }
   }

   function voteUserInAffairVotation(state, orderInfo, orderCompany = undefined) {
      const tempVotations: IAffairVotation[] = state.affairVotations;
      const orderIndex = tempVotations.findIndex((vote) => vote.affair.orderId === orderInfo.orderId);
      if (orderIndex < 0) {
         showSnackBar("Error en votación, intente de nuevo más tarde", true);
         return { ...state };
      }
      if (session.group) {
         const companyIndex = tempVotations[orderIndex].companies.findIndex(
            (company) => company.company === orderCompany
         );
         if (companyIndex < 0) {
            showSnackBar("Error en votación, intente de nuevo más tarde", true);
            return { ...state };
         }
         const foundUserIndex = tempVotations[orderIndex].companies[companyIndex].users.findIndex(
            (user) => user.user === orderInfo.user
         );
         if (foundUserIndex < 0) {
            showSnackBar("Error en votación, intente de nuevo más tarde", true);
            return { ...state };
         }
         if (orderInfo.abstention) {
            tempVotations[orderIndex].companies[companyIndex].users[foundUserIndex].abstention = true;
         } else {
            tempVotations[orderIndex].companies[companyIndex].users[foundUserIndex].answer = orderInfo.answer;
         }
         return { ...state, affairVotations: tempVotations };
      } else {
         const foundUserIndex = tempVotations[orderIndex].users.findIndex((user) => user.user === orderInfo.user);
         if (foundUserIndex < 0) {
            showSnackBar("Error en votación, intente de nuevo más tarde", true);
            return { ...state };
         }
         if (orderInfo.abstention) {
            tempVotations[orderIndex].users[foundUserIndex].abstention = true;
         } else {
            tempVotations[orderIndex].users[foundUserIndex].answer = orderInfo.answer;
         }
         return { ...state, affairVotations: tempVotations };
      }
   }

   function updateSpecificVote(state, action) {
      const tempVotes = state.additionalVotes;
      const foundVoteIndex = tempVotes.findIndex((vindex: any) => vindex._id === action.votationId);
      if (foundVoteIndex === -1) return { ...state };
      const foundUserVoteIndex = tempVotes[foundVoteIndex].votes.findIndex(
         (user) => user.userId === action.voteInfo.userId
      );
      if (foundUserVoteIndex === -1) tempVotes[foundVoteIndex].votes.push(action.voteInfo);
      else tempVotes[foundVoteIndex].votes[foundUserVoteIndex] = action.voteInfo;
      let tempActiveVote = state.vote;
      if (tempActiveVote !== null) tempActiveVote = tempVotes[foundVoteIndex];
      return {
         ...state,
         additionalVotes: tempVotes,
         vote: tempActiveVote !== null ? tempActiveVote : state.vote,
      };
   }

   useEffect(() => {
      if (!session) return;
      socket.emit("join", { sessionId: session._id, userId: userId });

      const handleJoinExternal = async (valuesFromSocket) => {
         if (!valuesFromSocket.access) {
            setIsRejected(true);
            setIsWaitingToVerify(false);
         } else {
            setIsRejected(false);
            setIsVerified(true);
         }
      };

      const handleReceiveValuesExternal = (values) => {
         setaffairsArray(values.affairsArray);
         setColors(values.colors);
         setFileArray(values.fileArray);
         setInitialValues(values.initialValues);
         setMembersWithCharge(values.membersWithCharge);
         setGoverningBody(values.governingBody);
         setValuesFromBill(values.valuesFromBill);
         setGroupCompaniesInSession(values.groupCompaniesInSession);
         dispatch({ type: "additionalVotesUpdateAll", additionalVotes: values.additionalVotes });
         setQuorum(values.quorum);
         setIsWaitingToVerify(false);
      };

      const socketModalToSign = (valuesFromSocket) => {
         if (!valuesFromSocket.signArray.includes(userId)) setOpenModalToSign(true);
      };

      const handleEndSession = () => {
         setCompletedSession(true);
      };

      const handleCancelSession = () => {
         setCanceledSession(true);
      };

      const updateExternalNames = (values) => {
         session.externs.find((u) => u.user === values.userId).name = values.firstName;
      };

      const handleReceiveNewVote = (valuesFromSocket) => {
         if (!valuesFromSocket.orderId) dispatch({ type: "addNewAdditionalVote", newAdditionalVote: valuesFromSocket });
      };

      const handleReceiveNewAffairVote = (valuesFromSocket) => {
         if (valuesFromSocket.vote)
            dispatch({ type: "addNewAdditionalVote", newAdditionalVote: valuesFromSocket.vote });
      };

      const handleUpdateAdditionalVotes = (valuesFromSocket) => {
         dispatch({
            type: "updateSpecificVote",
            votationId: valuesFromSocket.votationId,
            voteInfo: valuesFromSocket.voteInfo,
         });
         setResolutionsSeed((s) => s + 1);
      };

      const handleUpdateAffariVotes = (valuesFromSocket) => {
         dispatch({
            type: "voteUserInAffairVotation",
            orderInfo: valuesFromSocket.orderInfo,
            ...(valuesFromSocket.company && { company: valuesFromSocket.company }),
         });
         setResolutionsSeed((s) => s + 1);
      };

      const socketHandlerCloseVoteModal = (valuesFromSocket) => {
         if (valuesFromSocket.order) {
            dispatch({
               type: "updateAffairVoteInVoteStatus",
               orderInfo: valuesFromSocket.order,
               orderId: valuesFromSocket.orderId,
            });
            setResolutionsSeed((s) => s + 1);
         }
         if (valuesFromSocket.addVote) {
            dispatch({
               type: "overWriteAdditionalVote",
               additionalVote: valuesFromSocket.addVote,
            });
         }
      };

      const handleCancelVotation = (valuesFromSocket) => {
         if (!valuesFromSocket.isAffairVote)
            dispatch({ type: "overWriteAdditionalVote", additionalVote: valuesFromSocket.additionalVote });
         dispatch({ type: "deleteActiveVote" });
      };

      const handleCancelAffairVotation = (valuesFromSocket) => {
         setSession(valuesFromSocket.session);
         dispatch({ type: "additionalVotesUpdateAll", additionalVotes: valuesFromSocket.vote });
         dispatch({ type: "deleteActiveVote" });
      };

      socket.on("send-response-join-external", handleJoinExternal);
      socket.on("receive-values-external", handleReceiveValuesExternal);
      socket.on("require-sign", socketModalToSign);
      socket.on("complete-killer", handleEndSession);
      socket.on("killer", handleCancelSession);
      socket.on("update-external-users", updateExternalNames);
      socket.on("receive-votation", handleReceiveNewVote);
      socket.on("receive-affair-votation", handleReceiveNewAffairVote);
      socket.on("receive-additional-votes-changes", handleUpdateAdditionalVotes);
      socket.on("receive-affair-votes-changes", handleUpdateAffariVotes);
      socket.on("close-vote-modal", socketHandlerCloseVoteModal);
      socket.on("close-votation", handleCancelVotation);
      socket.on("close-affair-votation", handleCancelAffairVotation);

      return () => {
         socket.off("send-response-join-external", handleJoinExternal);
         socket.off("receive-values-external", handleReceiveValuesExternal);
         socket.off("require-sign", socketModalToSign);
         socket.off("complete-killer", handleEndSession);
         socket.off("killer", handleCancelSession);
         socket.off("update-external-users", updateExternalNames);
         socket.off("receive-votation", handleReceiveNewVote);
         socket.off("receive-affair-votation", handleReceiveNewAffairVote);
         socket.off("receive-additional-votes-changes", handleUpdateAdditionalVotes);
         socket.off("receive-affair-votes-changes", handleUpdateAffariVotes);
         socket.off("close-vote-modal", socketHandlerCloseVoteModal);
         socket.off("close-votation", handleCancelVotation);
         socket.off("close-affair-votation", handleCancelAffairVotation);
      };
   }, [session, socket]);

   const externalMemberUsers = useMemo(() => {
      if (session === null) return;
      const membersArray = session.externs.map((member: any) => member);
      return membersArray;
   }, [session]);

   const userOnlineSigns = useMemo(() => {
      let userMap = {};
      if (!membersWithCharge || usersOnline.length === 0) return [];
      membersWithCharge.concat(externalMemberUsers)?.forEach((member) => {
         userMap[member._id || member.user] = {
            firstName: member?.firstName || member.name,
            lastName: member?.lastName || "",
            _id: member?._id || member.user,
         };
      });
      const usersOnlineSignsArray = usersOnline.map((userOnline) => userMap[userOnline]);
      return [...new Set(usersOnlineSignsArray)];
   }, [usersOnline, membersWithCharge, externalMemberUsers]);

   const sessionResolutions = useMemo(() => {
      const rows = [];
      const deliberationVotes = [];
      if (!affairsArray || affairsArray.length === 0) return [];
      if (session?.group) {
         for (const order of affairVotations) {
            const foundAffair = affairsArray.find((affair) => affair.orderId === order.affair?.orderId);
            for (const company of order.companies) {
               if (!company.answers) continue;
               const votationObject = {
                  title: foundAffair.title,
                  description: order.affair.description,
                  company: company.company,
                  orderId: order.affair.orderId,
               };
               const votationTotalVotes = Object.values(company.answers).reduce((obj: any, keyName: any) => {
                  if (Object.keys(obj).length === 0) return { [keyName]: 0 };
                  return { ...obj, [keyName]: 0 };
               }, {});

               for (const user of company.users || company.votes) {
                  if (!user.answer || user.abstention) continue;
                  votationTotalVotes[user.answer] += user.avaliableVotes;
               }

               let maxedObject;
               for (const key of Object.keys(votationTotalVotes)) {
                  if (!maxedObject) {
                     maxedObject = key;
                     continue;
                  }
                  if (votationTotalVotes[maxedObject] < votationTotalVotes[key]) maxedObject = key;
               }
               votationObject["resolution"] =
                  votationTotalVotes[maxedObject] > 0
                     ? `${maxedObject} con ${((votationTotalVotes[maxedObject] * 100) / company.totalVotes).toFixed(
                          2
                       )}% de votos`
                     : "Votación inválida";
               rows.push(votationObject);
            }
         }
      } else if (affairVotations?.length > 0) deliberationVotes.push(...affairVotations);
      if (additionalVotes?.length > 0) deliberationVotes.push(...additionalVotes);
      if (deliberationVotes?.length === 0) return rows;
      for (const votation of deliberationVotes) {
         if (!votation.answers) continue;
         const foundAffair = affairsArray.find((affair) => affair.orderId === votation.affair?.orderId);
         const proclamation = !quorum
            ? governingBody?.structure?.resolutionVotes || 0
            : session.proclamation === "Primera"
            ? quorum.vote[0]
            : quorum.vote[1];
         const title = votation.affair ? foundAffair?.title || votation.affair.description : votation.title;
         const votationObject = {
            title: title,
            description: votation.affair ? votation.affair.description : "N/A",
            ...(votation.company && { company: votation.company }),
            ...(session.group && { orderId: votation.orderId, votationId: votation._id }),
         };
         if ((votation.affair || votation.orderId) && Number(votation.resolutionPercentage || 0) < proclamation) {
            votationObject["resolution"] = "Votación inválida";
         } else {
            const votationTotalVotes = Object.values(votation.answers).reduce((obj: any, keyName: any) => {
               if (Object.keys(obj).length === 0) return { [keyName]: 0 };
               return { ...obj, [keyName]: 0 };
            }, {});

            for (const user of votation.users || votation.votes) {
               if (!user.answer || user.abstention) continue;
               votationTotalVotes[user.answer] += user.avaliableVotes;
            }

            let maxedObject;
            for (const key of Object.keys(votationTotalVotes)) {
               if (!maxedObject) {
                  maxedObject = key;
                  continue;
               }
               if (votationTotalVotes[maxedObject] < votationTotalVotes[key]) maxedObject = key;
            }
            votationObject["resolution"] =
               votationTotalVotes[maxedObject] > 0
                  ? `${maxedObject} con ${((votationTotalVotes[maxedObject] * 100) / votation.totalVotes).toFixed(
                       2
                    )}% de votos`
                  : "Votación inválida";
         }
         rows.push(votationObject);
      }
      return rows;
   }, [resolutionSeed, additionalVotes, affairVotations, quorum, session, affairsArray]);

   useEffect(() => {
      if (isVerified) socket.emit("already-verified-external", { sessionId: sessionId, userId: userId });
   }, [isVerified, socket]);

   useEffect(() => {
      const fetchSession = async () => {
         setIsLoading(true);
         const response = await getSessionByIdExternal(sessionId, userId);
         if (!response) return setIsLoading(false);
         setSession(response.session);
         setExternalSessionToken(response.accessToken);
         dispatch({ type: "affairsInitialState", affairVotations: response.session.affairVotations });
         setIsLoading(false);
      };
      fetchSession();
   }, []);

   useEffect(() => {
      const verifyToken = async () => {
         if (!completedSession && !canceledSession) {
            const tokenResponse = await createExternalSessionToken(sessionId, userId);
            setExternalSessionToken(tokenResponse?.accessToken);
         }
      };
      verifyToken();
      const intervalId = setInterval(verifyToken, 1000 * 10 * 5);
      return () => clearInterval(intervalId);
   }, [isVerified]);

   return (
      <ExternalGovernanceSessionContext.Provider
         value={{
            session,
            setSession,
            externalSessionToken,
            affairsArray,
            isLoading,
            setIsLoading,
            isVerified,
            setIsVerified,
            socket,
            userId,
            isRejected,
            setIsRejected,
            isWaitingToVerify,
            setIsWaitingToVerify,
            colors,
            externalMemberUsers,
            fileArray,
            setFileArray,
            initialValues,
            membersWithCharge,
            governingBody,
            valuesFromBill,
            setValuesFromBill,
            groupCompaniesInSession,
            quorum,
            setQuorum,
            usersOnline,
            setUsersOnline,
            userOnlineSigns,
            attendedPercentage,
            setAttendedPercentage,
            setGoverningBody,
            completedSession,
            canceledSession,
            openModalToSign,
            setOpenModalToSign,
            openInputNameModal,
            setOpenInputNameModal,
            documentUrl,
            setDocumentUrl,
            sessionResolutions,
            affairVotations,
            additionalVotes,
            signArray,
            setSignArray,
            fetchSignArrayList,
         }}
      >
         {children}
      </ExternalGovernanceSessionContext.Provider>
   );
};
