import axios, { AxiosError } from "axios";
import { BASE_ENDPOINT, DocumentationFile, DocumentationUpload } from "./lecosyBackendClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { UserContext } from "../context/userContext";

export const base_url =
   window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
      ? "https://test.server.lecosy.com.mx"
      : process.env.NODE_ENV === "production"
      ? "https://server.lecosy.com.mx"
      : "http://localhost:8002";

export const host = base_url;
export const CLIENT_ENDPOINT = "/client";
const MASTER_ENDPOINT = "/master";
const MAIL_ENDPOINT = "/mail";
const BRANCHES_ENDPOINT = "/branches";
const S3_ENDPOINT = "/s3";
const COMPANIES_ENDPOINT = "/companies";
const PLATFORM_INFO_ENDPOINT = "/platform-info";
const GROUP_ENDPOINT = "/groups";
const USERS_ENDPOINT = "/user";
const COMPANYDETAILS_ENDPOINT = "/company_details";
const INVOICE_ENDPOINT = "/invoice";
const RECEIPT_ENDPOINT = "/receipt";
const INVOICE_DETAILS_ENDPOINT = "/invoiceDetails";
const USER_CONTACT = "/contact";
const USER_DETAILS_ENDPOINT = "/userDetails";
const SERVICES_ENDPOINT = "/services";

export const useGetResourceSelected = () => {
   const { selectedResources } = useContext(UserContext);
   if (selectedResources) {
      return selectedResources._id;
   }
   return "";
};

export const handleInvalidRequest = (error) => {
   const details: AxiosError = error as AxiosError;
   if (details.response.data === "expired") {
      console.log("request session expired");
      return null;
   }
   if (details.response.data === "no user") {
      console.log("request session expired");
      return null;
   }
};

export const sendSupportEmail = async (title, feedbackType, files, description?, email?) => {
   const body = {
      title,
      feedbackType,
      description,
      email,
      files,
   };
   return axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${MAIL_ENDPOINT}/send/support`, body);
};

export const restoreMasterUserPassword = async (email: string) => {
   const body = {
      email: email,
   };
   try {
      axios.defaults.withCredentials = false;
      return await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/restore-password`, body);
   } catch (error) {
      console.log(error);
   }
};

export const getUsersDataByGoverningBody = async (ids: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USERS_ENDPOINT}/governing_body/users`, {
         ids,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getUsersBasicInfoByIds = async (ids: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const body = {
         ids: ids,
      };
      const data = await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USERS_ENDPOINT}/basic/all`, body);
      return data.data;
   } catch (err) {
      handleInvalidRequest(err);
   }
};

export const checkUserExistence = async (email: string) => {
   try {
      axios.defaults.withCredentials = true;
      const body = {
         email,
      };
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENDPOINT}/exists`, body);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const checkIfUserIsAdminInCompany = async (userId: string, companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USERS_ENDPOINT}/checkIfAdmin/${userId}/${companyId}`
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getNameUserData = async (ids: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/comments`, ids);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getUserDataGB = async (arrayids: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/members`, arrayids);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const LoginUser = () => {
   return useMutation({
      mutationFn: async (userInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/login`, userInfo);
         return data;
      },
   });
};

export const VerifyUser = () => {
   return useMutation({
      mutationFn: async (userInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/verify`, userInfo);
         return data;
      },
   });
};
export const getAccessByServiceId = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/services/access/${id}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getAllResources = async () => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/resources/get/all`);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const GetGroupById = async (groupId) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUP_ENDPOINT}/${groupId}`);
   return data;
};

export const GetManyGroupsById = async (groupIds: string[]) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUP_ENDPOINT}/many`, groupIds);
   return data;
};

export const GetGroupByAdminId = async (groupId) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUP_ENDPOINT}/${groupId}/admin`);
   return data;
};

export const GetUserSession = async () => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}/session`);
   return data;
};

export const GetUserByUid = async () => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/my-information`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const GetUserAndUserDetailsByUid = async () => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/user-details-by-uid`);
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getManyCompaniesById = async (companiesIds: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/many`, {
         companiesIds,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getManyCompaniesByIdPublic = async (companiesIds: string[], token: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/many/token`, {
         companiesIds,
         token,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCompaniesAndBranchCountByGroup = async (groupId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/all/group/${groupId}`
   );
   return data;
};

export const LogoutUser = async (email: string) => {
   axios.defaults.withCredentials = true;
   const body = {
      email: email,
   };
   await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/logout`, body);
};

export const GetAdminUserContractSubusers = () => {
   return useQuery({
      queryKey: ["subUsers"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/subs`);
         return data;
      },
   });
};

export const getContactInfoByCompanyId = async (companyId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USER_CONTACT}${COMPANIES_ENDPOINT}/${companyId}`
   );
   return data;
};

export const CreateSubUser = () => {
   return useMutation({
      mutationFn: async (userData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/new`, userData);
         return data;
      },
   });
};

export const addUserRole = async (userUid, roleInfo) => {
   //its actually _id
   try {
      const body = {
         company: roleInfo.companyId,
         roles: roleInfo.roles,
      };
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/new/role/${userUid}`, body);
      return data;
   } catch (err) {
      handleInvalidRequest(err);
   }
};

export const UpdateSubUserById = (uid: string) => {
   return useMutation({
      mutationFn: async (userData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/update/${uid}`, userData);
         return data;
      },
   });
};

export const UpdateSubUserRolesById = (uid: string) => {
   return useMutation({
      mutationFn: async (userData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/update/${uid}/subuser`,
            userData
         );
         return data;
      },
   });
};

export const RemoveCompanySubUserRolesById = (uid: string, companyId: string) => {
   return useMutation({
      mutationFn: async (userData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/update/${uid}/subuser/${companyId}`,
            userData
         );
         return data;
      },
   });
};

export const GetSubUsersByComplaintBranch = (branchId: number, company: boolean, group?: string) => {
   return useQuery({
      queryKey: ["complaintSubUsers"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const body = {
            ...(group && { groupId: group }),
         };
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user${BRANCHES_ENDPOINT}/${branchId}/all/${company}/`,
            body
         );
         return data;
      },
   });
};

export const getPlatformInfo = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      if (companyId === "") return;
      const { data } = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${PLATFORM_INFO_ENDPOINT}${COMPANIES_ENDPOINT}/${companyId}`
      );
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getPlatformInfoByCompanyId = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      if (companyId === "") return;
      const { data } = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${PLATFORM_INFO_ENDPOINT}${COMPANIES_ENDPOINT}/${companyId}/company`
      );
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getCompanyById = async (id) => {
   axios.defaults.withCredentials = true;
   return axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/${id}`);
};

export const getCompaniesById = async (ids) => {
   axios.defaults.withCredentials = true;
   return axios.post<any[]>(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/all`, {
      companyIds: ids,
   });
};

export const getAdminCompanyById = async (id) => {
   axios.defaults.withCredentials = true;
   return axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANIES_ENDPOINT}/${id}/admin`);
};

export const getUserBranches = async (companyDetails, isCompanyAdmin: boolean, user) => {
   try {
      const body = {
         companyDetails: companyDetails,
         isCompanyAdmin,
         ...(user.group && { groupId: user.group._id }),
      };
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${BRANCHES_ENDPOINT}/user/branches`,
         body
      );
      return data;
   } catch (error) {
      console.log("error", error);
   }
};

export const getBranchesByCompanyId = async (id) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${BRANCHES_ENDPOINT}/company/${id}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getBranchById = async (branchId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${BRANCHES_ENDPOINT}/${branchId}`);
   return data;
};

export const getClientModules = async (ids) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/services/all`, { ids });
   return data;
};

export const GetAllClientRoles = () => {
   return useQuery({
      queryKey: ["userRoles"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user-role`);
         return data;
      },
   });
};

export const getAllUserRoles = async (ids) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user-role/all/byIds`, {
      ids,
   });
   return data;
};

//#region s3Client

export const getUrlS3 = async (bucket: string, folder: any, fileKey: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/get/${bucket}/${fileKey}`, folder);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getS3LastModifiedDate = async (bucket: string, folder: any, fileKey: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/get/date/${bucket}/${fileKey}`, folder);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlS3Token = async (bucket: string, folder: any, fileKey: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/get/${bucket}/${fileKey}/token`, folder);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlForDocumentsList = async (bucket: string, objectId: any) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/list/${bucket}`, objectId);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlS3GC = async (bucket: string, folder: any, file: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/get/${bucket}/${file}`, folder);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlForDocumentsListGC = async (bucket: string, objectId: any) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/list/gc/${bucket}`, { folder: objectId });
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlForDocumentsListGCPrefix = async (
   bucket: string,
   objectId: any,
   prefix: string,
   token = undefined
) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(
         `${host}${BASE_ENDPOINT}${S3_ENDPOINT}/list/${bucket}/prefix`,
         {
            folder: objectId,
            prefix,
         },
         {
            headers: {
               token: token,
            },
         }
      );
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlForDocumentationFile = async (
   reportNumber: string,
   bucket: string,
   folder: any,
   fileKey: string
) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/get/${bucket}/${fileKey}`, folder);
      if (data) {
         await DocumentationFile(reportNumber, fileKey);
         return data;
      }
      throw new Error("document not uploaded");
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlForDocumentationUpload = async (
   bucket: string,
   folder: any,
   fileKey: string,
   reportNumber: string
) => {
   try {
      const body = {
         bucket: bucket,
         folder: folder,
         name: fileKey,
      };
      axios.defaults.withCredentials = true;
      const { data } = await axios.post(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/upload`, body);
      if (data) {
         await DocumentationUpload(fileKey, reportNumber);
         return data;
      }
      throw new Error("document not uploaded");
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getUrlForEvidenceUpload = async (
   evidences: string[],
   id: string,
   subject: string,
   reportNumber: string
) => {
   try {
      axios.defaults.withCredentials = true;
      const preSignedUrl: any[] = [];
      for (const fileKey of evidences) {
         const body = {
            bucket: "files-lecosy",
            folder: {
               folder: id
                  ? `evidences/${reportNumber}/${subject}/${id}`
                  : `evidences/${reportNumber}/${subject}/anonimo`,
            },
            name: fileKey,
         };
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/upload`, body);
         preSignedUrl.push(data);
      }
      if (preSignedUrl.length === 0) throw new Error("document not uploaded");
      return preSignedUrl;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const complaintUploadPdfEvidence = async (signedURL, file) => {
   try {
      const config = {
         headers: {
            "Content-Type": `${file.type}`,
         },
      };
      axios.defaults.withCredentials = false;
      return await axios.put(signedURL, file, config);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const moveFileToFolder = async (bucket: string, folder: string, newFolder: any, fileKey: string) => {
   try {
      axios.defaults.withCredentials = true;
      const body = {
         folder,
         docName: fileKey,
         newFolder,
      };
      await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/move/${bucket}`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const deletePdfEvidence = async (bucket: string, folder: any, fileKey: string) => {
   try {
      axios.defaults.withCredentials = true;
      await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/delete/${bucket}/${fileKey}`, folder);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const deleteFolderS3 = async (bucket: string, folder: string) => {
   try {
      axios.defaults.withCredentials = true;
      await axios.put(`${host}${BASE_ENDPOINT}${S3_ENDPOINT}/delete/folder`, { folder, bucket });
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const verifyActionCode = async (code: string, type: any) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/auth/vc/${code}/${type}`);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const confirmPasswordReset = async (newPassword: string, code: string) => {
   try {
      const payload = {
         newPassword,
         code,
      };
      axios.defaults.withCredentials = true;
      return await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/auth/cpr`, payload);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const resendEmailVerification = async (userEmail: string) => {
   try {
      const body = {
         email: userEmail,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/auth/gevl`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};
//#endregion

export const updateUserPassword = async (password: string, newPassword: string) => {
   try {
      const body = {
         password,
         newPassword,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/pass/v`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateUser = async (uid: string, firstName: string, lastName: string, phoneNumber: string) => {
   try {
      const body = {
         uid: uid,
         firstName: firstName,
         lastName: lastName,
         phoneNumber: phoneNumber,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USERS_ENDPOINT}/update/`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const ReplaceUser = () => {
   return useMutation({
      mutationFn: async (replaceData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USERS_ENDPOINT}/replace`,
            replaceData
         );
         return data;
      },
   });
};

export const updateUserEmail = async (email: string, uid: string) => {
   try {
      const body = {
         email,
         uid,
      };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USERS_ENDPOINT}/modify/email`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateUserDetailsDisabled = async (_id: string, disabled: boolean) => {
   try {
      const body = { _id, disabled };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USER_DETAILS_ENDPOINT}/update`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateUserDetailsByUserId = async (updateData) => {
   try {
      axios.defaults.withCredentials = true;
      return await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USER_DETAILS_ENDPOINT}/update/byUser`,
         updateData
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const findStatusService = async (companyDetailsId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${COMPANYDETAILS_ENDPOINT}/${companyDetailsId}/status`
      );
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const UseLastCompanyInvoice = async (companyId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INVOICE_ENDPOINT}/lastInvoice/${companyId}`
   );
   return data;
};
export const getReceiptById = async (receiptId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${RECEIPT_ENDPOINT}/${receiptId}`);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getInvoiceById = async (invoiceId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INVOICE_ENDPOINT}/${invoiceId}`);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const UseInvoicesByCompanyId = async (companyId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INVOICE_ENDPOINT}${COMPANIES_ENDPOINT}/${companyId}`
   );
   return data;
};

export const UseInvoicesByGroupId = async (groupId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${INVOICE_ENDPOINT}${COMPANIES_ENDPOINT}/${groupId}/group`
   );
   return data;
};

export const GetReceiptByGroupId = async (groupId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${RECEIPT_ENDPOINT}${COMPANIES_ENDPOINT}/${groupId}/group`
   );
   return data;
};
export const GetReceiptByCompanyId = async (companyId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${RECEIPT_ENDPOINT}${COMPANIES_ENDPOINT}/${companyId}`
   );
   return data;
};

export const useCreateUser = () => {
   return useMutation({
      mutationFn: async (userInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/new`, userInfo);
         return data;
      },
   });
};

export const createUser = async (userInfo) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/new`, userInfo);
   return data;
};

export const PutNewUserRole = async (uid: string, roles: any, branches: any, companyId: string) => {
   axios.defaults.withCredentials = true;
   const body = { roles, branches, companyId };
   const { data } = await axios.post(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENDPOINT}/new/role/${uid}`,
      body
   );
   return data;
};

export const getGroupUsers = async (group: any) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/groupU/${group}/all`);
   return data;
};

export const addUserToGroupModule = async (groupId, userInfo, module) => {
   try {
      axios.defaults.withCredentials = true;
      const body = {
         userInfo,
         module,
      };
      const { data } = await axios.put(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/groupU/${groupId}/all/register`,
         body
      );
      return data;
   } catch (err) {
      handleInvalidRequest(err);
   }
};

export const editUserRolesInGroup = async (groupId: string, userInfo, module: string) => {
   try {
      axios.defaults.withCredentials = true;
      const body = {
         roles: userInfo.roles,
         userId: userInfo.userId,
         ...(userInfo.roleName && { roleName: userInfo.roleName }),
         module: module,
      };
      const { data } = await axios.put(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/groupU/${groupId}/all/update`,
         body
      );
      return data;
   } catch (err) {
      handleInvalidRequest(err);
   }
};

export const getGroupLeveledUsers = async (groupId: string, level: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/groupU/${groupId}/all/${level}`);
   return data;
};

export const getUserStatus = async (usersId: string[]) => {
   const body = {
      usersId: usersId,
   };
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/userDetails/disabled`, body);
   return data;
};

export const getInvoiceDetailsById = async (invoiceId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${INVOICE_DETAILS_ENDPOINT}/${invoiceId}`
   );
   return data;
};

export const UpdateInvoiceDetails = (invoiceId: string) => {
   return useMutation({
      mutationFn: async (invoiceInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${INVOICE_DETAILS_ENDPOINT}/update/${invoiceId}`,
            invoiceInfo
         );
         return data;
      },
   });
};

export const getUserByUserUid = async (userId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENDPOINT}/${userId}`);
   return data;
};

export const getUserByUserId = async (userId: string, token = undefined) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENDPOINT}/id/${userId}`, {
      headers: {
         token: token,
      },
   });
   return data;
};

export const getUsersByIds = async (userIds: string[]) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENDPOINT}/ByIds`, {
      ids: userIds,
   });
   return data;
};

export const GetAllServices = () => {
   return useQuery({
      queryKey: ["services"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/services/all`);
         return data;
      },
   });
};

export const CreateAditionalContact = (companyId: string) => {
   return useMutation({
      mutationFn: async (aditionalContact: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/contact/${companyId}`,
            aditionalContact
         );
         return data;
      },
   });
};

export const ModifyAditionalContact = (contactId: string) => {
   return useMutation({
      mutationFn: async (aditionalContact: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/contact/put/${contactId}`,
            aditionalContact
         );
         return data;
      },
   });
};

export const deleteAdditionalContact = async (contactId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.delete(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/contact/${contactId}`);
   return data;
};

//#region LOGS

export const getLogsByGovernanceAndResource = async (object: string, resourceId: string, companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/logs/companies/governance/${object}/${resourceId}/${companyId}`
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getLogsByUserAndResource = async (userId: string, resourceId: string, companySelected: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/logs/user/${userId}/${resourceId}/${companySelected}`
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

//#endregion

export const getAllUsers = async () => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/all`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getFilteredUsers = async (userIds: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/filtered`, userIds);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getUsersByIdPublic = async (userIds, token, company, group) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/names`, {
         ids: userIds,
         token: token,
         company: company,
         group: group,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

//#region GC

export const CreateFolder = () => {
   return useMutation({
      mutationFn: async (folderData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/new`, folderData, {
            headers: {
               company: folderData.companyId,
            },
         });
         return data;
      },
   });
};

export const CreateFolderGoverning = () => {
   return useMutation({
      mutationFn: async (folderData: { folderData: any; governanceId: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/new/${folderData.governanceId}`,
            folderData.folderData
         );
         return data;
      },
   });
};

export const getFoldersById = async (foldersIds) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get`, { foldersIds: foldersIds });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getFoldersByIdsExpedient = async (foldersIds, company) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get/expedient`, {
         foldersIds: foldersIds,
         company,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getYearlyParentFoldersByIds = async (foldersIds) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get/yearly/bc`, {
         foldersIds: foldersIds,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getChildrenByGovernanceId = async (governanceId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get/governance/${governanceId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getFoldersByGovernanceId = async (foldersIds) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get/byGovernance`, {
         foldersIds: foldersIds,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAllInFolderUrlS3GC = async (bucket: string, folderId: string, types: string[], page: number) => {
   try {
      const body = {
         bucket: bucket,
         folderId: folderId,
         types: types,
         page: page,
      };
      axios.defaults.withCredentials = true;
      const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get/url`, body);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getFoldersAndFilesById = async (foldersIds, company, id?) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get/files`,
         {
            foldersIds: foldersIds,
            governanceId: id,
         },
         {
            headers: {
               company: company,
            },
         }
      );
      return data.data;
   } catch (error) {
      console.log("Error fetching folders and files:", error.response ? error.response.data : error.message);
      handleInvalidRequest(error);
   }
};

export const getFoldersAndFilesByIdPublic = async (foldersIds, company) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get/files/public`,
         {
            foldersIds: foldersIds,
         },
         {
            headers: {
               company: company,
            },
         }
      );
      return data.data;
   } catch (error) {
      console.log("Error fetching folders and files:", error.response ? error.response.data : error.message);
      handleInvalidRequest(error);
   }
};

export const ModifyFoldersById = (folderId, resourceId: string, companyId: string) => {
   return useMutation({
      mutationFn: async (folderData: { name: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/put/${folderId}`,
            folderData,
            {
               headers: { r1: companyId, r2: resourceId, company: companyId },
            }
         );
         return data;
      },
   });
};

export const addChildToFolder = async (folderId, name, child) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/put/${folderId}/add/child`, {
      child,
      name,
   });
   return data;
};

export const verifyFolderByParentAndNameFolder = async (folderId, name) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/new/foldername/folder`, {
      name,
      folderId,
   });
   return data;
};

export const createFolder = async (folderName, companyId) => {
   try {
      const response = await axios.post(`${host}${BASE_ENDPOINT}/create-folder`, {
         name: folderName,
         companyId: companyId,
      });
      return response.data;
   } catch (error) {
      console.error("Error creating folder:", error);
      handleInvalidRequest(error);
   }
};

export const moveFolder = async (sourceFolderId, destinationFolderId) => {
   try {
      const response = await axios.post(`${host}${BASE_ENDPOINT}/move-folder`, {
         sourceId: sourceFolderId,
         destinationId: destinationFolderId,
      });
      return response.data;
   } catch (error) {
      console.error("Error moving folder:", error);
      handleInvalidRequest(error);
   }
};
export const VerifyChatFolderByName = () => {
   return useMutation({
      mutationFn: async (folderData: { name: string; userId: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/new/foldername`,
            folderData
         );
         return data;
      },
   });
};

export const VerifyFolderByNameAndGovernance = () => {
   return useMutation({
      mutationFn: async (folderData: { name: string; governanceId: string; governanceName: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/new/foldername/byGov`,
            folderData
         );
         return data;
      },
   });
};

export const VerifyFolderByName = () => {
   return useMutation({
      mutationFn: async (folderData: { name: string; userId: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/new/verify`, folderData);
         return data;
      },
   });
};

export const VerifyFolderByNameNoMutate = async (folderData: { name: string; userId: string }) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/new/verify`, folderData);
      return data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const CreateFile = () => {
   return useMutation({
      mutationFn: async (fileData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/new`, fileData);
         return data;
      },
   });
};

export const createFileAsync = async (fileData) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/new`, fileData);
   return data;
};

export const createMultipleFileAsync = async (fileData) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/new/multiple`, fileData);
   return data;
};

export const CreateFileByName = () => {
   return useMutation({
      mutationFn: async (fileData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/new/byName`, fileData);
         return data;
      },
   });
};

export const CreateFileByNameNoMutate = async (fileData: any) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/new/byName`, fileData);
      return data;
   } catch (error) {
      console.log(error);
   }
};

export const SendProclamationFile = () => {
   return useMutation({
      mutationFn: async (fileData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/get/${fileData.fileId}/sendByMail`,
            fileData
         );
         return data;
      },
   });
};

export const SendFilesByMail = () => {
   return useMutation({
      mutationFn: async (fileData: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/get/sendByMail/multiple`, {
            files: fileData,
         });
         return data;
      },
   });
};

export const sendFilesByMailAsync = async (fileData: any) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.post(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/get/sendByMail/multiple`,
         { files: fileData },
         { timeout: 30000 }
      );

      if (!data) {
         throw new Error("No response from email service");
      }

      return data;
   } catch (error) {
      console.error("Error sending files by mail:", error);
      handleInvalidRequest(error);
      throw error;
   }
};

export const sendFilesByMailBc = async (fileData: any, fileId) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/get/${fileId}/sendByMail/bc`,
      fileData
   );
   return data.data;
};

export const getFilesByFilterId = async (text: string, id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/meta/${id}`, { text: text });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const DeleteFile = (fileId: string, companyId: string) => {
   return useMutation({
      mutationFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.delete(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/delete/${fileId}`, {
            headers: {
               company: companyId,
            },
         });
         return data;
      },
   });
};

export const deleteFilesBC = async (fileId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.delete(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/delete/${fileId}/bc`);
   return data;
};

export const deleteSheetsBCByFoldersId = async (foldersIds: string[]) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get/delete/sheets/bc`, {
      foldersIds,
   });
   return data;
};

export const CopyFile = (fileId: string, resourceId: string, id: string) => {
   return useMutation({
      mutationFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/copy/${fileId}/${id}`, {
            headers: { r1: "", r2: resourceId },
         });
         return data;
      },
   });
};

export const ModifyFile = (fileId: string, resourceId: string, companyId: string, governanceId: string) => {
   return useMutation({
      mutationFn: async (fileData: { name: string; metadata: string[] }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/modify/${fileId}/${governanceId}`,
            fileData,
            {
               headers: { r1: companyId, r2: resourceId, company: companyId },
            }
         );
         return data;
      },
   });
};

export const CopyFileToFolder = () => {
   return useMutation({
      mutationFn: async (fileData: { folderId: string; fileId: string; resourceId: string; companyId: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/copy/${fileData.fileId}/tofolderid`,
            fileData,
            {
               headers: { r1: fileData.companyId, r2: fileData.resourceId },
            }
         );
         return data;
      },
   });
};

export const ShareFileById = (folderId, resourceId: string, companyId: string) => {
   return useMutation({
      mutationFn: async (folderData: {
         shared: { user: string; permission: number }[];
         new: { user: string; permission: number }[];
      }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/share/${folderId}`,
            folderData,
            {
               headers: { r1: companyId, r2: resourceId },
            }
         );
         return data;
      },
   });
};

export const ShareFolderById = (folderId, resourceId: string, companyId: string) => {
   return useMutation({
      mutationFn: async (folderData: {
         shared: { user: string; permission: number }[];
         new: { user: string; permission: number }[];
      }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/share/${folderId}`,
            folderData,
            {
               headers: { r1: companyId, r2: resourceId },
            }
         );
         return data;
      },
   });
};

export const RemoveShareFileById = (folderId, resourceId: string, companyId: string) => {
   return useMutation({
      mutationFn: async (folderData: { user: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/share/${folderId}/${folderData.user}`,
            {
               headers: { r1: companyId, r2: resourceId },
            }
         );
         return data;
      },
   });
};

export const RemoveShareFolderById = (folderId, resourceId: string, companyId: string) => {
   return useMutation({
      mutationFn: async (folderData: { user: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/share/${folderId}/${folderData.user}`,
            {
               headers: { r1: companyId, r2: resourceId },
            }
         );
         return data;
      },
   });
};

export const ChangeAccessShareFileById = (folderId, resourceId: string, companyId: string) => {
   return useMutation({
      mutationFn: async (folderData: { user: string; permission: number }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/share/${folderId}/${folderData.user}`,
            folderData,
            {
               headers: { r1: companyId, r2: resourceId },
            }
         );
         return data;
      },
   });
};

export const managePlatformInfoSuggestions = async (subName: string, suggestions: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const body = {
         suggestions,
      };
      const { data } = await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${PLATFORM_INFO_ENDPOINT}/${subName}/sugestions`,
         body
      );
      return data;
   } catch (error) {
      console.error(error);
      handleInvalidRequest(error);
   }
};

export const ChangeAccessShareFolderById = (folderId, resourceId: string, companyId: string) => {
   return useMutation({
      mutationFn: async (folderData: { user: string; permission: number }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/share/${folderId}/${folderData.user}`,
            folderData,
            {
               headers: { r1: companyId, r2: resourceId },
            }
         );
         return data;
      },
   });
};

export const getFileByIdChat = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/fileBySenderId/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getFileById = async (id: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/share/${id}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const MoveFileQuery = (fileId: string, resourceId: string, governanceId: string) => {
   return useMutation({
      mutationFn: async (folderId: string) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/move/${fileId}`,
            {
               folderId: folderId,
               governanceId: governanceId,
            },
            {
               headers: { r1: "", r2: resourceId },
            }
         );
         return data;
      },
   });
};

export const MoveFolderQuery = (folderId: string, resourceId: string, companyId: string) => {
   return useMutation({
      mutationFn: async (folderToId: string) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/move/${folderId}/${folderToId}`,
            {},
            {
               headers: { r1: "", r2: resourceId, company: companyId },
            }
         );
         return data;
      },
   });
};

export const DeleteFolder = (folderId: string, companyId: string) => {
   return useMutation({
      mutationFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.delete(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/delete/${folderId}`, {
            headers: {
               company: companyId,
            },
         });
         return data;
      },
   });
};

export const CopyFolder = (folderId: string, resourceId: string) => {
   return useMutation({
      mutationFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/copy/${folderId}`, {
            headers: { r1: "", r2: resourceId },
         });
         return data;
      },
   });
};

export const getRolesGC = async (bodyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user-role/GCRoles/${bodyId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getRoleByCharge = async (chargeId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user-role/${chargeId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const addGovernBodyRole = async (roleData: any, bodyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USERS_ENDPOINT}/addGBRole`, {
         ...roleData,
         governance: bodyId,
      });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getUsersDetails = async (ids: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USER_DETAILS_ENDPOINT}/users-details`,
         ids
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getUsersDetailsByUserId = async (userId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USER_DETAILS_ENDPOINT}/get/${userId}`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getLogsByUserAndResourceAndFile = async (
   userId: string,
   resourceId: string,
   fileId: string,
   companyId: string
) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/logs/user/${userId}/${resourceId}/${companyId}/${fileId}`
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const deleteGovernRol = async (
   deletedUserId: string,
   chargeId: string,
   companyId: string,
   governanceId: string,
   disable?: boolean
) => {
   try {
      axios.defaults.withCredentials = true;
      const body = { deletedUserId, chargeId, companyId, governanceId, disable };
      const data = await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user-role/deleteGBRole`, body);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateGovernBodyRole = async (roleData: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user-role/updateGBRole`, roleData);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const moveGovernBodyRole = async (roleData: any, oldUser: string, governance: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USERS_ENDPOINT}/replaceGBRole/${oldUser}`,
         { ...roleData, governance: governance }
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const VerifyInsightFolderByName = () => {
   return useMutation({
      mutationFn: async (folderData: { title: string; userId: string; insightUrl; protectedGuide }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/new/createInsights`,
            folderData
         );
         return data;
      },
   });
};

export const getSavedInsights = async (userId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get/savedInsights/${userId}`);
   return data;
};

export const GetFileFolderByFileDirection = () => {
   return useMutation({
      mutationFn: async (folderData: { userId: string; fileDirection: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/get/byFileDirection`,
            folderData
         );
         return data;
      },
   });
};

export const DeleteFileWithBody = () => {
   return useMutation({
      mutationFn: async (insightData: { userId: string; fileDirection: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/deleteByFileDirection`,
            insightData
         );
         return data;
      },
   });
};

export const postCorporateData = async (corporateData: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/corporateData/new`, corporateData);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};
export const updateCorporateData = async (corporateDataId: string, corporateData: any) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/corporateData/update/${corporateDataId}`,
         corporateData
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCorporateData = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/corporateData/${companyId}`);
      return data.data;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getManyCorporateData = async (companyArray: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/corporateData/many`, { companyArray });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getAdminFunctionsData = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/corporateData/${companyId}/affair`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const verifyImplementationUser = async (userId: string, companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${USERS_ENDPOINT}/implementationCheck/${userId}/${companyId}`
      );
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getUserByRoleAndEntity = async (roleId: string, companyId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENDPOINT}/${companyId}/all/${roleId}`
   );
   return data;
};

export const deleteRegulationFile = async (governanceId: string, folderName: string, userCheck: boolean) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.delete(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/delete/${governanceId}/${folderName}/${userCheck}`
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getFileByGovernanceAndFoldername = async (governanceId: string, folderName: string) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get/${governanceId}/${folderName}`
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getUserProfilePics = async (userIds: string[]) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/ByIds/profilePics`, { userIds });
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCompanyColors = async (companyId: string) => {
   try {
      axios.defaults.withCredentials = true;
      // Realiza la solicitud usando GET
      const companyDetailsInfo = await axios.get(`${host}/api/master/companies/${companyId}`);
      // Extrae los valores que necesitas
      const businessName = companyDetailsInfo.data?.person_details?.businessName;
      const primaryColor = companyDetailsInfo.data?.company_details?.primaryColor;
      const secondaryColor = companyDetailsInfo.data?.company_details?.secondaryColor;

      return {
         businessName,
         primaryColor,
         secondaryColor,
      };
   } catch (error) {
      handleInvalidRequest(error);
      return {
         businessName: null,
         primaryColor: null,
         secondaryColor: null,
      };
   }
};

export const getGroupColors = async (groupId: string) => {
   try {
      axios.defaults.withCredentials = true;
      // Realiza la solicitud usando GET para obtener la información del grupo
      const groupDetailsInfo = await axios.get(`${host}/api/master/groups/${groupId}`);

      const groupName = groupDetailsInfo.data?.name;
      const primaryColor = groupDetailsInfo.data?.primaryColor;
      const secondaryColor = groupDetailsInfo.data?.secondaryColor;
      const group_Id = groupId;
      return {
         groupName,
         primaryColor,
         secondaryColor,
         group_Id,
      };
   } catch (error) {
      handleInvalidRequest(error);
      return {
         groupName: null,
         primaryColor: null,
         secondaryColor: null,
         group_Id: null,
      };
   }
};

export const createFolderBeneficiary = async (data: { name: string; owner: string; parent: string }) => {
   try {
      const response = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/new`, data);
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const postBeneficiary = async (data: {
   user?: string;
   shareHolderType: string;
   legalPerson: string;
   businessName?: string;
   // address?: string;
   birthDate?: string;
   placeOfBirth?: string;
   rfc?: string;
   name?: string; // Propiedades opcionales
   lastName?: string;
   email?: string;
   phoneNumber?: string;
   active?: boolean;
   fiscalAddress?: string;
   fiscalCountry?: string;
   constitutionCountry?: string;
}) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/new`, data);
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getBeneficiaries = async (companyId) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/${companyId}`);
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getBeneficiariesControl = async (companyId) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/${companyId}/control`);
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getBeneficiariesShareholder = async (companyId) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/${companyId}/shareholder`
      );
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getMoralBeneficiaries = async (companyId) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/moral/${companyId}`);
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getBeneficiaryByFolderId = async (folderId) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/byFolder/${folderId}`);
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateBeneficiaryFile = async (fileId, verify) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/beneficiary/${fileId}`, {
         verify,
      });
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateBeneficiaryFilesByName = async (beneficiaryId, fileNames, verify, addFile) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/file/modifyFilesByName/${beneficiaryId}`,
         {
            verify,
            fileNames,
            addFile,
         }
      );
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateNoChangesBeneficiaryFilesByName = async (beneficiaryId, fileNames) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/file/modifyFilesByName/noChanges/${beneficiaryId}`,
         {
            fileNames,
         }
      );
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const deleteCustomBeneficiaryDocument = async (fileName, companyId) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.delete(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary-application/${companyId}/${fileName}`
      );
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const getIdentifications = async (companyId) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/identifier/${companyId}`);
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getCaseIdentifications = async (companyId) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/case-identifier/${companyId}`);
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createIdentification = async (data: { companyId; text; type }) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/identifier/new`, data);
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createCaseIdentification = async (data: { companyId; text; type }) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/case-identifier/new`, data);
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getBeneficiaryById = async (beneficiaryId) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/get/${beneficiaryId}`);
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getBeneficiaryByRFC = async (rfc) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/rfc/${rfc}`);
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateBeneficiaryCompanies = async (beneficiaryId, companyId) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/put/${beneficiaryId}`, {
         companyId,
      });
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const addEfectiveControllerToBeneficiary = async (beneficiaryId) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/put/${beneficiaryId}/efective`
      );
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateActiveStatus = async (beneficiaryId, isActive) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.patch(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/update-active/${beneficiaryId}`,
         {
            isActive,
         }
      );
      return response.data;
   } catch (error) {
      throw error;
   }
};

export const postBeneficiaryDetails = async (data: {
   legalName?: string;
   acta?: any | null;
   contrato?: any | null;
   cdsf?: any | null;
   informationLegalRepresentative?: {
      userBeneficiaryId?: string;
      nameAndLastName?: string;
      alias?: string;
      dateBirthday?: string;
      dateRip?: string | null;
      nacionality?: string;
      country?: string;
      gender?: string;
      curp?: string;
      rfc?: string;
      phone?: string;
      countryAddress?: string;
      email?: string;
      stateCivil?: string;
   };
   informationControllerBeneficiary?: {
      identificacion?: string;
      autorityIdentificacion?: string;
      numberIdentificacion?: string;
   };
   informationConcubine?: {
      identificacion?: string;
      autorityIdentificacion?: string;
      numberIdentificacion?: string;
   };
   address?: {
      street?: string;
      outdoor?: string;
      indoor?: string;
      colonia?: string;
      municipality?: string;
      city?: string;
      state?: string;
      countryAddress?: string;
      zipCode?: string;
      sameAddress?: boolean;
   };
   fiscalAddress?: {
      street?: string;
      outdoor?: string;
      indoor?: string;
      colonia?: string;
      municipality?: string;
      city?: string;
      state?: string;
      countryAddress?: string;
      zipCode?: string;
   };
   relationMoralPerson?: {
      relationWithPerson?: string;
      porcentajeParticipation?: string;
      actions?: Array<{
         title: string;
         nominalValue: string;
         totalActions: string;
      }>;
      placeDeposited?: string;
      dateInitial?: string;
      dateFinal?: string | null;
   };
   company: string;
}) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiaryDetails/new`, data);
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getBeneficiaryDetailsById = async (beneficiaryId, companyId) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiaryDetails/get/${beneficiaryId}/${companyId}`
      );
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getBeneficiaryDetailsByCompany = async (companyId) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiaryDetails/${companyId}`);
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getBeneficiaryByCompanyId = async (companyId) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/get/company/${companyId}`
      );
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateBeneficiaryDetails = async (
   _id: string, // Identificador del beneficiario a actualizar
   companyId: string,
   data: {
      legalName?: string;
      acta?: any | null;
      contrato?: any | null;
      cdsf?: any | null;
      informationLegalRepresentative?: {
         userBeneficiaryId?: string;
         nameAndLastName?: string;
         alias?: string;
         dateBirthday?: string;
         dateRip?: string | null;
         nacionality?: string;
         country?: string;
         gender?: string;
         curp?: string;
         rfc?: string;
         phone?: string;
         countryAddress?: string;
         email?: string;
         stateCivil?: string;
      };
      informationControllerBeneficiary?: {
         identificacion?: string;
         autorityIdentificacion?: string;
         numberIdentificacion?: string;
      };
      informationConcubine?: {
         identificacion?: string;
         autorityIdentificacion?: string;
         numberIdentificacion?: string;
      };
      address?: {
         street?: string;
         outdoor?: string;
         indoor?: string;
         colonia?: string;
         municipality?: string;
         city?: string;
         state?: string;
         countryAddress?: string;
         zipCode?: string;
         sameAddress?: boolean;
      };
      fiscalAddress?: {
         street?: string;
         outdoor?: string;
         indoor?: string;
         colonia?: string;
         municipality?: string;
         city?: string;
         state?: string;
         countryAddress?: string;
         zipCode?: string;
      };
      relationMoralPerson?: {
         relationWithPerson?: string;
         porcentajeParticipation?: string;
         actions?: Array<{
            title?: string;
            nominalValue?: string;
            totalActions?: string;
         }>;
         placeDeposited?: string;
         dateInitial?: string;
         dateFinal?: string | null;
         identification?: string;
         identificationSub?: string;
         caseIdentification?: string;
      };
      company?: string;
   }
) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiaryDetails/update/${_id}/${companyId}`,
         data
      );
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getRequestChangesBeneficiaryById = async (requestId) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/requestChangesBeneficiary/get/${requestId}`
      );
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createRequestChangesBeneficiary = async (data) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.post(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/requestChangesBeneficiary/new`,
         data
      );
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createMultipleRequestChangesBeneficiary = async (data) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.post(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/requestChangesBeneficiary/new/multiple`,
         data
      );
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const finishRequestChangesBeneficiary = async (requestId, beneficiaryId) => {
   try {
      axios.defaults.withCredentials = true;
      const response = await axios.put(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/requestChangesBeneficiary/get/${requestId}/${beneficiaryId}`
      );
      return response.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getPendingRequestsByBeneficiaryId = async (beneficiaryId: string) => {
   try {
      axios.defaults.withCredentials = true;
      const { data } = await axios.get(
         `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/requestChangesBeneficiary/user/${beneficiaryId}`
      );
      return data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const createMultipleFolderBC = async (folderData) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/new/multiple/bc`, folderData);
   return data;
};

export const getBeneficiaryApplicationConfig = async (companyId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary-application/${companyId}`);
   return data;
};

export const updateBeneficiaryApplicationConfig = async (companyId: string, updateData) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary-application/${companyId}`,
      updateData
   );
   return data;
};

export const addBeneficiaryDocument = async (companyId: string, updateDocumentData) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary-application/${companyId}/new`,
      updateDocumentData
   );
   return data;
};

export const getBeneficiaryDashboardInfo = async (companyId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/get/company/${companyId}/dashboard`
   );
   return data;
};

export const updateBeneficiary = async (beneficiaryId: string, updateData) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/get/${beneficiaryId}/update`,
      updateData
   );
   return data;
};

export const getLastFileInFolder = async (folderId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/get/lastDocument/bc/${folderId}`);
   return data;
};

export const getMostRecentBeneficiaryDoc = async (folderId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/get/recentDocument/bc/${folderId}`);
   return data;
};

export const verifyRecentBeneficiaryDocs = async (foldersIds: string[]) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/file/get/recentDocument/bc/verify`, {
      foldersIds,
   });
   return data;
};

export const getUserByEmail = async (email) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/email/${email}`);
   return data;
};

export const removeBeneficiaryFromCompany = async (beneficiaryId, companyId) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.delete(
      `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary/delete/${beneficiaryId}/${companyId}`
   );
   return data;
};

//#endregion

//#region Borrowed from Intern

export const getInCompletedCompanies = async (ownerId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/companies/all/filtered/${ownerId}`);
   return data;
};

export const getInCompletedGroups = async (ownerId: string) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.get(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/groups/all/filtered/${ownerId}`);
   return data;
};

export const createNewCompany = async (company: any) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/companies/new`, company);
   return data;
};

export const updateCompanyPersonDetails = async (userInfo: any) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/companies/update/${userInfo.companyId}`,
      userInfo
   );
   return data;
};

export const createInvoiceDetails = async (details: any) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${INVOICE_DETAILS_ENDPOINT}/new`,
      details
   );
   return data;
};

export const updateInvoiceDetailsOffice = async (details: any) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${INVOICE_DETAILS_ENDPOINT}/update/${details.id}`,
      details.invoice_details
   );
   return data;
};

export const createBeneficiaryApplicationConfig = async (configData) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.post(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/beneficiary-application`, configData);
   return data;
};

export const updateServiceDisable = async (serviceUpdate: {
   service_id: string;
   companyDetails_id: string;
   disable: boolean;
}) => {
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/serviceUpdate/company_details/${serviceUpdate.companyDetails_id}${SERVICES_ENDPOINT}/${serviceUpdate.service_id}`,
      serviceUpdate
   );
   return data;
};

export const GetCompanyUsers = (company: any, group: { groupId?: any } = {}) => {
   return useQuery({
      queryKey: ["users", company],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const group_id = group.groupId;
         const { data } = await axios.get(
            group_id
               ? `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENDPOINT}/groupU/${group_id}/all`
               : `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENDPOINT}/${company._id}/all`
         );

         const processedData = data.map((row) => ({
            _id: row._id,
            firstName: row.firstName,
            lastName: row.lastName,
            phoneNumber: row.phoneNumber,
            role: row.role,
            email: row.email,
         }));
         return processedData;
      },
      enabled: !!company.company_details?.users,
   });
};

export const GetGroupUsers = (group: any) => {
   return useQuery({
      queryKey: ["users", group],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${USERS_ENDPOINT}/groupU/${group._id}/all`
         );
         const processedData = data.map((row) => ({
            _id: row._id,
            firstName: row.firstName,
            lastName: row.lastName,
            phoneNumber: row.phoneNumber,
            role: row.role,
            email: row.email,
         }));
         return processedData;
      },
   });
};

export const useCreateUserOffice = () => {
   return useMutation({
      mutationFn: async (userInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/new`, userInfo);
         return data;
      },
   });
};

export const GetAllUserRoles = () => {
   return useQuery({
      queryKey: ["userRoles"],
      queryFn: async () => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user-roleall`);
         return data;
      },
   });
};

export const updateCompanyAdminUser = async (
   uid: string,
   firstName?: string,
   lastName?: string,
   email?: string,
   phoneNumber?: string,
   role?: string
) => {
   try {
      let body = {};
      if (uid) body = { ...body, uid: uid };
      if (firstName) body = { ...body, firstName: firstName };
      if (lastName) body = { ...body, lastName: lastName };
      if (role) body = { ...body, role: role };
      if (email) body = { ...body, email: email };
      if (phoneNumber) body = { ...body, phoneNumber: phoneNumber };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/update/${uid}`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const updateClientUserRoles = async (
   _id: string,
   isCompany: boolean,
   isAdmin: boolean,
   idCompanyOrGroup: string,
   role?: any
) => {
   try {
      let body = {};
      if (_id) body = { ...body, _id: _id };
      if (isCompany) body = { ...body, isCompany: isCompany };
      if (isAdmin) body = { ...body, isAdmin: isAdmin };
      if (idCompanyOrGroup) body = { ...body, idCompanyOrGroup: idCompanyOrGroup };
      if (role) body = { ...body, role: role };
      axios.defaults.withCredentials = true;
      return await axios.put(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/compRoles`, body);
   } catch (error) {
      handleInvalidRequest(error);
   }
};

export const addUserRoleOffice = async (body, userId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}/master/user/new/role/${userId}`, body);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const removeRoleFromUser = async (userId, companyId, roleId, group: boolean = false) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/remove/${userId}/${companyId}/${roleId}`,
         { group }
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const getImplementationUsers = async () => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.get(`${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/user/implementation/users`);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const removeUserFromCompany = async (userId, companyId, roleId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.delete(
         `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/user/remove/implementation/${userId}/${companyId}/${roleId}`
      );
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const useCreateNewGroup = () => {
   return useMutation({
      mutationFn: async (group: { name: string; phoneNumber: string; owner?: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.post(`${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUP_ENDPOINT}/new`, group);
         return data;
      },
   });
};

export const useUpdateGroup = () => {
   return useMutation({
      mutationFn: async (groupInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUP_ENDPOINT}/update/${groupInfo._id}`,
            groupInfo
         );
         return data;
      },
   });
};

export const useUpdateCompanyPersonDetails = () => {
   return useMutation({
      mutationFn: async (userInfo: any) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}/companies/update/${userInfo.companyId}`,
            userInfo
         );
         return data;
      },
   });
};

export const UpdateGroupAdmin = async (userId: string, groupId: string) => {
   const body = {
      admin: userId,
   };
   axios.defaults.withCredentials = true;
   const { data } = await axios.put(
      `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUP_ENDPOINT}/update/${groupId}`,
      body
   );
   return data;
};

export const useDisableCompany = () => {
   return useMutation({
      mutationFn: async (companyId: string) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${COMPANIES_ENDPOINT}/disable/${companyId}`
         );
         return data;
      },
   });
};

export const useDisableGroup = () => {
   return useMutation({
      mutationFn: async (groupId: string) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}${GROUP_ENDPOINT}/disable/${groupId}`
         );
         return data;
      },
   });
};

export const useUpdateGroupInvoiceCompany = () => {
   return useMutation({
      mutationFn: async (invoiceCompany: {
         invoiceCompany?: string;
         groupId: string;
         invoiceData?: any;
         multiInvoice?: boolean;
      }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUP_ENDPOINT}/update/${invoiceCompany.groupId}`,
            invoiceCompany
         );
         return data;
      },
   });
};

export const useUpdateGroupCompanies = () => {
   return useMutation({
      mutationFn: async (companies: { companies: readonly string[]; groupId: string }) => {
         axios.defaults.withCredentials = true;
         const { data } = await axios.put(
            `${host}${BASE_ENDPOINT}${MASTER_ENDPOINT}${GROUP_ENDPOINT}/update/${companies.groupId}`,
            companies
         );
         return data;
      },
   });
};

export const addUserRoleGroup = async (body, userId) => {
   try {
      axios.defaults.withCredentials = true;
      const data = await axios.put(`${host}${BASE_ENDPOINT}/master/user/new/role/${userId}/group`, body);
      return data.data;
   } catch (error) {
      console.log(error);
      handleInvalidRequest(error);
   }
};

export const updateCompanyDetails = async (companyId: string, data: any) => {
   try {
      axios.defaults.withCredentials = true;
      const body = {
         businessName: data.businessName,
         primaryColor: data.primaryColor,
         secondaryColor: data.secondaryColor,
      };
      const response = await axios.put(`${host}${COMPANYDETAILS_ENDPOINT}/${companyId}`, body);
      return response;
   } catch (error) {
      handleInvalidRequest(error);
   }
};

//#endregion
