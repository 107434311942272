import { Info, PictureAsPdf, Visibility } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, CircularProgress, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { getCorporateData } from "../../lib/usersBEClient";
import { Form, useNavigate } from "react-router-dom";
import { CustomModalComponent } from "../CustomModalComponent";
import { UserContext } from "../../context/userContext";
import { SnackBarContext } from "../../context/snackBarContext";
import { CorporateData, documentType } from "../../context/governanceContext/corporateDataContext";
import { HeadCell, TableComponent } from "../TableComponent";
import { Formik } from "formik";
import { InputTextField } from "../Inputs/InputTextField";
import { employerRegistrationSchema } from "../../lib/validations/Repse/inputSchemas";

interface ModuleInfoReSiProps {
   data?: any;
   document?: documentType;
   shareholdersBodyId?: string;
   setBodiesSeed: Function;
   bodiesSeed: number;
   setCorporateDataCheck: Function;
}

const useFetchCorporateData = (bodiesSeed, setCorporateDataCheck) => {
   const [corporateData, setCorporateData] = useState<CorporateData>(null);
   const [isLoading, setIsLoading] = useState(false);
   const { companySelected } = useContext(UserContext);

   useEffect(() => {
      const checkData = async () => {
         setIsLoading(true);
         const data = await getCorporateData(companySelected._id);
         if (data) setCorporateDataCheck(true);
         setCorporateData(data);
         setIsLoading(false);
      };
      checkData();
   }, [companySelected._id, bodiesSeed]);

   return { corporateData, isLoading, companySelected };
};

export const ModuleInfoReSi = (props: ModuleInfoReSiProps) => {
   const navigate = useNavigate();
   const { showSnackBar } = useContext(SnackBarContext);
   const { corporateData, isLoading } = useFetchCorporateData(props.bodiesSeed, props.setCorporateDataCheck);
   const [openModal, setOpenModal] = useState(false);
   const [edition, setEdition] = useState(false);

   const [open, setOpen] = useState(false);

   useEffect(() => {
      if (
         (typeof corporateData?.incorporationYear === "string" && corporateData?.incorporationYear.length < 6) ||
         typeof corporateData?.socialObject === "string" ||
         typeof corporateData?.watchman === "string"
      ) {
         setEdition(true);
         setOpenModal(true);
         showSnackBar("Actualizar datos", true);
      }
   }, [corporateData]);

   return (
      <>
         {!isLoading && corporateData ? (
            <Stack
               spacing={1}
               sx={{
                  bgcolor: "#f5f5f5",
                  pt: 2,
                  boxShadow: 2,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
               }}
            >
               <ModuleInformation />

               <Box
                  sx={{
                     bgcolor: "#fff",
                     p: 1,
                     display: "flex",
                     justifyContent: "space-between",
                     alignItems: "center",
                     px: "30px",
                     height: "60px",
                     mt: "16px !important",
                  }}
               >
                  <Typography sx={{ fontWeight: 600 }}>Configuración documento</Typography>
                  <Button
                     sx={{
                        bgcolor: "#5d6d7c",
                        width: "100px",
                        height: "30px",
                        color: "#fff",
                        "&:hover": { bgcolor: "#5d6d7c" },
                     }}
                     onClick={() => {
                        navigate(`../../servicios/configuracion-documental`);
                     }}
                  >
                     Abrir
                  </Button>
               </Box>

               <RegisterTableComponent setOpen={setOpen} />
            </Stack>
         ) : (
            <Box
               sx={{
                  display: "flex",
                  width: "100%",
                  bgcolor: "#FFFFFF",
                  justifyContent: "center",
                  alignContent: "center",
                  boxShadow: 2,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
               }}
            >
               <CircularProgress sx={{ my: 3 }} />
            </Box>
         )}
         <CustomModalComponent title="Agregar registro patronal" open={open} setOpen={setOpen}>
            <RegisterModal />
         </CustomModalComponent>
      </>
   );
};

const handleSubmitFormmmmmmmm = (values) => {};

const RegisterModal = () => {
   return (
      <Formik
         initialValues={{ firstName: "" }}
         validationSchema={employerRegistrationSchema}
         onSubmit={handleSubmitFormmmmmmmm}
      >
         <Form>
            <Box sx={{ width: "700px", maxHeight: "500px", overflow: "auto", px: "50px" }}>
               <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: "30px" }}>
                  <Box>
                     <Typography sx={{ fontWeight: 600 }}># Registro</Typography>
                     <InputTextField
                        name="firstName"
                        id="firstName"
                        variant="outlined"
                        disabled
                        fullWidth
                        sx={{ mr: 1, width: "150px" }}
                        label="# Registro"
                        type="text"
                     />
                  </Box>
                  <Box>
                     <Typography sx={{ ml: 1, fontWeight: 600 }}>Tarjeta de identificación patronal (TIP)</Typography>
                     <InputTextField
                        name="phoneNumber"
                        id="phoneNumber"
                        variant="outlined"
                        fullWidth
                        sx={{ ml: 1, width: "400px" }}
                        label="Adjuntar archivo"
                        type="text"
                     />
                  </Box>
                  <InfoIcon sx={{ mt: "23px", ml: "5px" }} />
               </Box>
               <Box sx={{ mb: "30px" }}>
                  <Box>
                     <Typography sx={{ fontWeight: 600 }}>Número de registro patronal</Typography>
                     <InputTextField
                        name="firstName"
                        id="firstName"
                        variant="outlined"
                        fullWidth
                        sx={{ mr: 1, width: "400px" }}
                        label="Número"
                        type="text"
                     />
                  </Box>
               </Box>
               <Box sx={{ display: "flex", justifyContent: "space-between", mb: "30px" }}>
                  <Box>
                     <Typography sx={{ fontWeight: 600 }}>División</Typography>
                     <InputTextField
                        name="firstName"
                        id="firstName"
                        variant="outlined"
                        fullWidth
                        sx={{ mr: 1, width: "100px" }}
                        label="Ext dato"
                        type="text"
                     />
                  </Box>
                  <Box>
                     <Typography sx={{ fontWeight: 600 }}>Grupo</Typography>
                     <InputTextField
                        name="firstName"
                        id="firstName"
                        variant="outlined"
                        fullWidth
                        sx={{ mr: 1, width: "100px" }}
                        label="Ext dato"
                        type="text"
                     />
                  </Box>
                  <Box>
                     <Typography sx={{ fontWeight: 600 }}>Fracción</Typography>
                     <InputTextField
                        name="firstName"
                        id="firstName"
                        variant="outlined"
                        fullWidth
                        sx={{ mr: 1, width: "100px" }}
                        label="Ext dato"
                        type="text"
                     />
                  </Box>
                  <Box>
                     <Typography sx={{ fontWeight: 600 }}>Clase</Typography>
                     <InputTextField
                        name="firstName"
                        id="firstName"
                        variant="outlined"
                        fullWidth
                        sx={{ mr: 1, width: "100px" }}
                        label="Ext dato"
                        type="text"
                     />
                  </Box>
               </Box>
               <Box sx={{ display: "flex", justifyContent: "space-between", mb: "30px" }}>
                  <Box>
                     <Typography sx={{ fontWeight: 600 }}>Domicilio</Typography>
                     <InputTextField
                        name="firstName"
                        id="firstName"
                        variant="outlined"
                        fullWidth
                        sx={{ mr: 1, width: "150px" }}
                        label="Dirreción"
                        type="text"
                     />
                  </Box>
                  <Box>
                     <Typography sx={{ fontWeight: 600 }}>Lugar y fecha de expedición</Typography>
                     <InputTextField
                        name="phoneNumber"
                        id="phoneNumber"
                        variant="outlined"
                        fullWidth
                        sx={{ ml: 1, width: "400px" }}
                        label="Localidad"
                        type="text"
                     />
                  </Box>
               </Box>
               <Box sx={{ mb: "30px" }}>
                  <Typography sx={{ fontWeight: 600 }}>
                     Nombre, denominación o razón social del patrón o sujeto obligado
                  </Typography>
                  <InputTextField
                     name="firstName"
                     id="firstName"
                     variant="outlined"
                     fullWidth
                     sx={{ mr: 1, width: "100%" }}
                     label="Denominación o razón social"
                     type="text"
                  />
               </Box>
               <Box sx={{ display: "flex", mb: "30px", justifyContent: "space-between" }}>
                  <Box>
                     <Typography sx={{ fontWeight: 600 }}>Delegación</Typography>
                     <InputTextField
                        name="firstName"
                        id="firstName"
                        variant="outlined"
                        fullWidth
                        sx={{ mr: 1, width: "150px" }}
                        label="Delegación"
                        type="text"
                     />
                  </Box>
                  <Box>
                     <Typography sx={{ fontWeight: 600 }}>Subdelegación</Typography>
                     <InputTextField
                        name="phoneNumber"
                        id="phoneNumber"
                        variant="outlined"
                        fullWidth
                        sx={{ width: "400px" }}
                        label="Subdelegación"
                        type="text"
                     />
                  </Box>
               </Box>
            </Box>
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  bgcolor: "#f3f3f3",
                  height: "50px",
               }}
            >
               <Button type="submit">Cancelar</Button>
               <Button type="submit">Guardar</Button>
            </Box>
         </Form>
      </Formik>
   );
};

const ModuleInformation = () => {
   return (
      <Box sx={{ bgcolor: "#fff", p: 1 }}>
         <Box sx={{ mx: 3, my: 1, display: "flex", flexDirection: "column" }}>
            <Typography fontWeight={600} fontSize={17}>
               Información del módulo
            </Typography>
            <Divider sx={{ my: 1 }} />
         </Box>
         <Grid container sx={{ mx: 3, my: 2, rowGap: 1 }}>
            <Grid item xs={4}>
               <Typography fontWeight={600} fontSize={14} my={1}>
                  Campo de info:
               </Typography>
               <Typography fontSize={14}>Descripción</Typography>
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600} fontSize={14} my={1}>
                  Sector económico:
               </Typography>
               <Typography fontSize={14}>Servicios</Typography>
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600} fontSize={14} my={1}>
                  Fecha de registro:
               </Typography>
               <Typography fontSize={14}>13-06-23</Typography>
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600} fontSize={14} my={1}>
                  Formulario Inicial:
               </Typography>
               <Button sx={{ borderRadius: 1, height: 30, border: "1.8px solid #868fa0", color: "#868fa0" }}>
                  Ver información <PictureAsPdfIcon sx={{ width: "20px", height: "20px", ml: "5px" }} />
               </Button>
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600} fontSize={14} my={1}>
                  Estado:
               </Typography>
               <Typography fontSize={14}>Sonora</Typography>
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600} fontSize={14} my={1}>
                  Sucursales:
               </Typography>
               <Typography fontSize={14}>03</Typography>
            </Grid>
         </Grid>
         <Grid item xs={8}>
            <Divider />
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mx: "20px",
                  mt: "8px",
               }}
            >
               <Typography>
                  Última modificación: <span>16/03/2024</span>
               </Typography>
               <Button
                  sx={{
                     bgcolor: "#e9edf5",
                     width: "200px",
                     height: "30px",
                     "&:hover": { bgcolor: "#e9edf5" },
                  }}
               >
                  Actualizar información
               </Button>
            </Box>
         </Grid>
      </Box>
   );
};

const RegisterTableComponent = ({ setOpen }) => {
   const TableHeaders: HeadCell[] = [
      { field: "no", headerName: "NO.", type: "number", align: "center" },
      {
         field: "register",
         headerName: "Registro patronal dle IMSS",
         type: "string",
         align: "left",
      },
      { field: "delegation", headerName: "Delegación", type: "string", align: "left" },
      {
         field: "subdelegation",
         headerName: "Subdelegación",
         type: "string",
         align: "center",
      },
      { field: "date", headerName: "Fecha de expedición", type: "string", align: "center" },
      {
         field: "edit",
         headerName: "Modificar",
         type: "string",
         align: "center",
      },
   ];

   const formattedData = useMemo(() => {
      return [
         {
            no: 2,
            originalName: "hola mundo",
            edit: (
               <Button
                  onClick={() => {
                     // setOpenComplainerModalEditing(true);
                  }}
                  sx={{ borderRadius: "15px" }}
               >
                  <EditIcon />
               </Button>
            ),
         },
         {
            no: 1,
            originalName: "Diagnostico Inicial - Enero 2024",
            edit: (
               <Button
                  onClick={() => {
                     // setOpenComplainerModalEditing(true);
                  }}
                  sx={{ borderRadius: "15px" }}
               >
                  <EditIcon />
               </Button>
            ),
         },
         {
            no: 3,
            originalName: "Diagnostico Inicial - Noviembre 2023",
            edit: (
               <Button
                  onClick={() => {
                     // setOpenComplainerModalEditing(true);
                  }}
                  sx={{ borderRadius: "15px" }}
               >
                  <EditIcon />
               </Button>
            ),
         },
         {
            no: 4,
            originalName: "Diagnostico Inicial - Octubre 2023",
            edit: (
               <Button
                  onClick={() => {
                     // setOpenComplainerModalEditing(true);
                  }}
                  sx={{ borderRadius: "15px" }}
               >
                  <EditIcon />
               </Button>
            ),
         },
      ];
   }, []);

   return (
      <Box sx={{ bgcolor: "#fff", mt: "16px !important" }}>
         <Box
            sx={{
               height: "50px",
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
               mx: "30px",
            }}
         >
            <Typography sx={{ fontWeight: 600 }}>Registro patronal</Typography>
            <Button
               sx={{
                  bgcolor: "#5d6d7c",
                  width: "180px",
                  height: "30px",
                  color: "#fff",
                  "&:hover": { bgcolor: "#5d6d7c" },
               }}
               onClick={() => setOpen(true)}
            >
               <AddCircleIcon sx={{ mr: "10px" }} /> Agregar registro
            </Button>
         </Box>
         <TableComponent
            headers={TableHeaders}
            defaultColumnToOrder={"no"}
            defaultOrder={"asc"}
            defaultRowsPerPage={10}
            rowsPerPageOptions={[10, 15, 20]}
            rows={formattedData}
            loader={false}
            emptyDataText={"No se encontraron registros"}
            onClick={() => {}}
         />
      </Box>
   );
};
