import { Outlet, Route } from "react-router-dom";
import { DashboardScreen } from "../screens/dashboardScreen";
import { SnackBarComponent } from "../components/SnackBarComponent";
import { SupportDrawer } from "../components/Drawers/SupportDrawer";
import { ValidatePasswordScreen } from "../screens/ValidatePasswordScreen";
import { LoginScreenNew } from "../screens/LoginScreenNew";
import { ForgotPasswordScreen } from "../screens/ForgotPasswordScreen";
import AccountAdministration from "../screens/AccountAdministration";
import { UserConfiguration } from "../screens/UserInformation/UserConfiguration";
import { useComplaintChannelRoutes } from "./complaintChannelRoutes";
import { UserInfoScreen } from "../screens/Mi Lecosy/User/UserInfoScreen";
import { MyLecosyScreen } from "../screens/Mi Lecosy/MyLecosyScreen";
import { GroupScreen } from "../screens/Mi Lecosy/NewScreens/GroupScreen";
import { GroupMainScreen } from "../screens/Mi Lecosy/NewScreens/GroupMainScreen";
import { GroupUsersTable } from "../components/MiLecosy/Group/GroupUsersTable";
import { CompaniesScreen } from "../screens/Mi Lecosy/NewScreens/CompaniesScreen";
import { CompanyScreen } from "../screens/Mi Lecosy/NewScreens/CompanyScreen";
import { CompanyMainScreen } from "../screens/Mi Lecosy/NewScreens/CompanyMainScreen";
import { ClientScreen } from "../screens/Mi Lecosy/NewScreens/ClientScreen";
import { ServicesScreen } from "../screens/Mi Lecosy/NewScreens/ServicesScreen";
import { useGovernanceRoutes } from "./governanceRoutes";
import { ScheduleEvent } from "../screens/Gobierno corporativo/ScheduleEvent";
import CorporateDataScreen from "../screens/Gobierno corporativo/CorporateDataScreen";
import { NotFoundScreen } from "../screens/NotFoundScreen";
import { CorporateDataProvider } from "../context/governanceContext/corporateDataContext";
import { AddUserProvider } from "../context/addUserContext";
import { NotificationScreen } from "../screens/NotificationScreen";
import { ProtectedRoute } from "../components/RouteComponents/ProtectedRoute";
import { RootBoundary } from "../screens/ErrorScreen";
import { UserProvider } from "../context/userContext";
import { ComplaintProvider } from "../context/complaintContext";
import { SessionProvider } from "../context/sessionContext";
import { SnackBarProvider } from "../context/snackBarContext";
import { SupportDrawerProvider } from "../context/supportContext";
import { GovernanceProvider } from "../context/governanceContext/governanceContext";
import { CompaniesTable } from "../containers/companiesTable";
import GroupServicesScreen from "../components/MiLecosy/Group/GroupServicesScreen";
import { ExternalGovernanceSessionProvider } from "../context/governanceContext/externalSessionContext";
import ExternalSession from "../screens/Gobierno corporativo/ExternalSession";
import { useBeneficiaryControllerRoutes } from "./beneficiaryControllerRoutes";
import { BeneficiaryControllerExternal } from "../screens/Beneficiary controller/BeneficiaryControllerExternal";
import { BeneficiaryControllerProvider } from "../context/beneficiaryContext/beneficiaryContollerContext";
import { useExternPCManagementRoutes } from "./pcExternManageRoutes";
import { DocumentConfiguration } from "../screens/Mi Lecosy/NewScreens/DocumentConfiguration";
import ClientCompaniesScreen from "../screens/Mi Lecosy/NewScreens/ClientCompaniesScreen";
import { ClientCreationProvider } from "../context/officeContexts/clientCreationContext";
import LegalarioMobile from "../screens/Gobierno corporativo/Legalario/LegalarioMobile";
import { ExternalLegalarioContextProvider } from "../context/governanceContext/ExternalLegalarioContext";

export const useExternManagementRoutes = () => {
   const complaintChannelRoutes = useComplaintChannelRoutes();
   const governanceRoutes = useGovernanceRoutes();
   const beneficiaryRoutes = useBeneficiaryControllerRoutes();
   const externPCManagementRoutes = useExternPCManagementRoutes();

   return (
      <Route
         element={
            <UserProvider>
               <SessionProvider>
                  <SnackBarProvider>
                     <SupportDrawerProvider>
                        <Outlet />
                     </SupportDrawerProvider>
                  </SnackBarProvider>
               </SessionProvider>
            </UserProvider>
         }
      >
         <Route path="/recuperar-contrasena" element={<ForgotPasswordScreen />} />
         <Route path="/calendario/event/:userId/:eventId" element={<ScheduleEvent />} />
         <Route
            path="/validar-contrasena"
            element={
               <>
                  <SnackBarComponent />
                  <SupportDrawer toolbar={false} />
                  <ValidatePasswordScreen />
               </>
            }
         />
         <Route
            path="/cuentas/administracion"
            element={
               <>
                  <SnackBarComponent />
                  <AccountAdministration />
               </>
            }
         />
         <Route path="/sesion/:sessionId/:userId" element={<Outlet />} errorElement={<RootBoundary></RootBoundary>}>
            <Route
               path=""
               element={
                  <ExternalGovernanceSessionProvider>
                     <SnackBarComponent />
                     <ExternalSession />
                  </ExternalGovernanceSessionProvider>
               }
            />
            <Route
               path=":action"
               element={
                  <ExternalLegalarioContextProvider>
                     <SnackBarComponent />
                     <LegalarioMobile />
                  </ExternalLegalarioContextProvider>
               }
            />
         </Route>

         <Route
            path="/beneficiario-controlador/externo/:beneficiaryId/:requestChangesId/:action"
            element={<BeneficiaryControllerExternal />}
         />
         <Route
            path="/beneficiario-controlador/externo/:beneficiaryId/:requestChangesId"
            element={<BeneficiaryControllerExternal />}
         />
         <Route path="/" element={<ProtectedRoute />} errorElement={<RootBoundary></RootBoundary>}>
            <Route
               path="login"
               element={
                  <>
                     <SnackBarComponent />
                     <SupportDrawer toolbar={false} />
                     <LoginScreenNew />
                  </>
               }
            />
            {/* <Route
               path="charges"
               element={
                  <>
                     <SnackBarComponent />
                     <SupportDrawer toolbar={false} />
                     <InitialRequirements />
                  </>
               }
            /> */}
            <Route
               path="inicio"
               element={
                  <GovernanceProvider>
                     <ComplaintProvider>
                        <DashboardScreen />
                     </ComplaintProvider>
                  </GovernanceProvider>
               }
            />
            <Route path="notificaciones" element={<NotificationScreen />} />
            <Route path="configuracion" element={<UserConfiguration />} />
            <Route element={<Outlet />}>
               <Route
                  path="mi-lecosy"
                  element={
                     <CorporateDataProvider>
                        <BeneficiaryControllerProvider>
                           <MyLecosyScreen />
                        </BeneficiaryControllerProvider>
                     </CorporateDataProvider>
                  }
               >
                  <Route path="empresas/inicio" element={<CompaniesScreen />} />
                  <Route path="grupo/:groupId" element={<GroupScreen />}>
                     <Route path="inicio" element={<GroupMainScreen />} />
                     <Route
                        path="usuarios"
                        element={
                           <AddUserProvider>
                              <GroupUsersTable />
                           </AddUserProvider>
                        }
                     />
                     <Route
                        path="empresas"
                        element={
                           <ClientCreationProvider>
                              <CompaniesTable />
                           </ClientCreationProvider>
                        }
                     />
                     <Route path="servicios" element={<GroupServicesScreen />} />
                  </Route>
                  <Route
                     path="usuarios/:group/:userId"
                     element={
                        <AddUserProvider>
                           <UserInfoScreen />
                        </AddUserProvider>
                     }
                  />
                  <Route path=":companyId" element={<CompanyScreen />}>
                     <Route path="inicio" element={<CompanyMainScreen />} />
                     <Route path="usuarios" element={<ClientScreen />} />
                     <Route
                        path="clientes"
                        element={
                           <ClientCreationProvider>
                              <ClientCompaniesScreen />
                           </ClientCreationProvider>
                        }
                     />

                     <Route
                        path="servicios"
                        element={
                           <ClientCreationProvider>
                              <AddUserProvider>
                                 <ServicesScreen />
                              </AddUserProvider>
                           </ClientCreationProvider>
                        }
                     >
                        <Route path="configuracion-documental" element={<DocumentConfiguration />} />
                     </Route>
                     <Route path="informacion-societaria" element={<CorporateDataScreen />} />
                  </Route>
                  <Route
                     path="usuarios/:company/:userId"
                     element={
                        <AddUserProvider>
                           <UserInfoScreen />
                        </AddUserProvider>
                     }
                  />
               </Route>
            </Route>
            {complaintChannelRoutes}
            {governanceRoutes}
            {beneficiaryRoutes}
            {externPCManagementRoutes}
         </Route>
         <Route path="*" element={<NotFoundScreen />} />
      </Route>
   );
};
