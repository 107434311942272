import { useContext, useEffect } from "react";
import { legalario } from "../../../lib/Legalario/legalario";
import { useParams } from "react-router-dom";
import { ExternalLegalarioContext } from "../../../context/governanceContext/ExternalLegalarioContext";
import { uploadFileToS3, UseGetPresignedURLUploadExternal } from "../../../lib/s3Client";
import { base64ToBlob, extractBase64Data, getMimeType } from "../../../const/globalConst";
import { getUrlS3Token } from "../../../lib/usersBEClient";
import { SnackBarContext } from "../../../context/snackBarContext";

const MobileAuthentication = () => {
   const { action } = useParams();
   const { session, user, token, socket, isLoading, setCompletedProcess, fetchSignArrayList } =
      useContext(ExternalLegalarioContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const legalarioSDK = legalario();

   const handleAuthenticate = () => {
      let dataFormIdentity = [];

      const handleData = (data) => {
         dataFormIdentity = data;
      };
      const verifyUserInSession = async (data2) => {
         const verify = session.usersRegistry.find((userRe) => userRe.user === user.user._id);
         if (verify) {
            const verifyNameAndEmail =
               data2.fullname.toLowerCase() === (user.user.firstName + " " + user.user.lastName).toLowerCase() ||
               user.user.email === data2.email;

            if (verifyNameAndEmail) {
               const mimeType = getMimeType(dataFormIdentity[0]?.content);
               const base64Data = extractBase64Data(dataFormIdentity[0]?.content);
               const blob = base64ToBlob(base64Data, mimeType);

               const urlReceipt1 = await UseGetPresignedURLUploadExternal({
                  bucket: "files-lecosy",
                  folder: `gc/companies/${session.company}/governing-body/sessions/${session._id}`,
                  name: `join-${user.user._id}`,
                  token: token,
               });

               await uploadFileToS3(urlReceipt1.data, blob);

               const documentUrl = await getUrlS3Token(
                  "files-lecosy",
                  {
                     folder: `gc/companies/${session.company}/governing-body/sessions/${session._id}`,
                     token: token,
                  },
                  `join-${user.user._id}`
               );

               socket.emit("request-to-join", {
                  user: { id: user.user._id, ...user.user },
                  file: documentUrl,
                  request: "join",
                  sessionId: session?._id,
               });
               console.log("success");
               return setCompletedProcess(true);
            } else {
               console.log("error");
               showSnackBar(
                  "Los datos proporcionados son incorrectos o no coinciden con los datos dentro de la sesión.",
                  true
               );
            }
         } else {
            console.log("error");
            showSnackBar(
               "Los datos proporcionados son incorrectos o no coinciden con los datos dentro de la sesión.",
               true
            );
         }
      };

      legalarioSDK.onBoarding({
         // signerId: "66834a73c0c1ec14430b9452", // Agregar el ID del firmante creado
         modules: [
            {
               name: "identidad", // Identificado del módulo [Obligatorio]
               frontCardId: true, // Captura de la parte frontal de la identificación
               reverseCardId: true, // Captura del reverso de la identificación
               autoshoot: true, //Bandera para ver si se activa el autoshooting para las tomas de frente/reverso
               allowUploadFiles: true, // Permite al usuario seleccionar un archivo desde su dispositivo  //Opcion para poder dejar subir archivos a los usuarios
               useOCR: true, // Realizar la extracción de datos de la identificación via OCR
               selfie: true, // Toma manual solo Selfie
               useAudio: false, // Recabar el audio de la Prueba de vida activa (Sonreir)
               rekognition: true, // Realizar la comparación facial del documento de identificación vs la selfie
               audioRecord: false, // Prueba de vida activa con audio (Pronunciar un texto), Default false
               //ineValidation: "online",
               callback: handleData, // Función que se ejecutará al finalizar el proceso de captura y validación de biométricos [opcional]
            },
            // {
            //    name: "codigo-otp",
            // },
         ],
         signers: {
            create: false,
            sendInvite: false,
            authType: "NONE",
            initSignature: false,
         },
         callbacks: {
            onInit: () => {}, // Función que se ejecutará al iniciar el proceso [opcional]// Callback
            onFinish: verifyUserInSession, //Funcion que se ejecutara al terminar modulos
         },
      });
   };

   const handleSign = () => {
      const legalarioSDK = legalario();
      legalarioSDK.signature({
         signerId: action,
         callbacks: {
            onFinish: async (data) => {
               const mimeType = getMimeType(data.documents[0]?.base64);
               const base64Data = extractBase64Data(data.documents[0]?.base64);
               const blob = base64ToBlob(base64Data, mimeType);
               console.log(`gc/companies/${session.company}/governing-body/sessions/${session._id}`);
               console.log(`sign-${user.user._id}.png`);
               const urlReceipt1 = await UseGetPresignedURLUploadExternal({
                  bucket: "files-lecosy",
                  folder: `gc/companies/${session.company}/governing-body/sessions/${session._id}`,
                  name: `sign-${user.user._id}.png`,
                  token: token,
               });
               await uploadFileToS3(urlReceipt1.data, blob);
               const arrayList = await fetchSignArrayList();
               console.log({ arrayList });
               socket.emit("verify-sign", { sessionId: session._id, signArray: arrayList });
               console.log("success");
               return setCompletedProcess(true);
            },
         },
      });
   };

   const renderLegalario = () => {
      if (action === "auth") return handleAuthenticate();
      else return handleSign();
   };

   useEffect(() => {
      if (!!session && !!user && !isLoading) renderLegalario();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isLoading]);
   return null;
};

export default MobileAuthentication;
