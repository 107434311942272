import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import React, { useContext, useState } from "react";
import { ExternalGovernanceSessionContext } from "../../../../context/governanceContext/externalSessionContext";
import { InputCheckBox } from "../../../Inputs/InputCheckBox";
import { legalario } from "../../../../lib/Legalario/legalario";
import { base64ToBlob, extractBase64Data, getMimeType } from "../../../../const/globalConst";
import { uploadFileToS3, UseGetPresignedURLUploadExternal } from "../../../../lib/s3Client";
import { getUrlS3Token } from "../../../../lib/usersBEClient";

const ManifestoModalLegalExternal = ({ setManifiestoModalOpen }) => {
   const { setDocumentUrl, userId, externalSessionToken, socket, session, setIsWaitingToVerify } = useContext(
      ExternalGovernanceSessionContext
   );
   const [acceptWarning, setAcceptWarning] = useState(false);
   const [loadingButton, setLoadingButton] = useState(false);

   const handleSubmit = async (values) => {
      if (!values["privacy"]) {
         setAcceptWarning(true);
         return;
      }
      setManifiestoModalOpen(false);

      const legalarioSDK = legalario();

      let dataFormIdentity = [];
      const handleData = (data) => {
         dataFormIdentity = data;
      };

      const verifyUserInSession = async (data2) => {
         const fullName = data2.fullname
            .toLowerCase()
            .split(" ")
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join(" ");

         const mimeType = getMimeType(dataFormIdentity[0]?.content);
         const base64Data = extractBase64Data(dataFormIdentity[0]?.content);
         const blob = base64ToBlob(base64Data, mimeType);

         const urlReceipt1 = await UseGetPresignedURLUploadExternal({
            bucket: "files-lecosy",
            folder: `gc/companies/${session.company}/governing-body/sessions/${session._id}`,
            name: `join-${userId}`,
            token: externalSessionToken,
         });

         await uploadFileToS3(urlReceipt1.data, blob);
         const documentUrl: string = await getUrlS3Token(
            "files-lecosy",
            {
               folder: `gc/companies/${session.company}/governing-body/sessions/${session._id}`,
               token: externalSessionToken,
            },
            `join-${userId}`
         );

         const userFound = session.externs.find((extern) => extern.user === userId);
         socket.emit("request-to-join-external", {
            ...userFound,
            name: fullName,
            folder: `gc/companies/${session.company}/governing-body/${session._id}`,
            file: documentUrl,
            request: "join",
            sessionId: session._id,
            external: true,
         });

         setDocumentUrl(documentUrl);
         setManifiestoModalOpen(false);
         setIsWaitingToVerify(true);
         setLoadingButton(false);
      };

      legalarioSDK.onBoarding({
         // signerId: "66834a73c0c1ec14430b9452", // Agregar el ID del firmante creado
         modules: [
            {
               name: "identidad", // Identificado del módulo [Obligatorio]
               frontCardId: true, // Captura de la parte frontal de la identificación
               reverseCardId: true, // Captura del reverso de la identificación
               autoshoot: true, //Bandera para ver si se activa el autoshooting para las tomas de frente/reverso
               allowUploadFiles: true, // Permite al usuario seleccionar un archivo desde su dispositivo  //Opcion para poder dejar subir archivos a los usuarios
               useOCR: true, // Realizar la extracción de datos de la identificación via OCR
               selfie: true, // Toma manual solo Selfie
               useAudio: false, // Recabar el audio de la Prueba de vida activa (Sonreir)
               rekognition: true, // Realizar la comparación facial del documento de identificación vs la selfie
               audioRecord: false, // Prueba de vida activa con audio (Pronunciar un texto), Default false
               //ineValidation: "online",
               callback: handleData, // Función que se ejecutará al finalizar el proceso de captura y validación de biométricos [opcional]
            },
            // {
            //    name: "codigo-otp",
            // },
         ],
         signers: {
            create: false,
            sendInvite: false,
            authType: "NONE",
            initSignature: false,
         },
         callbacks: {
            onInit: () => {}, // Función que se ejecutará al iniciar el proceso [opcional]// Callback
            onFinish: verifyUserInSession, //Funcion que se ejecutara al terminar modulos
         },
      });
   };

   return (
      <Stack sx={{ maxWidth: { xs: 500, lg: 680 } }}>
         <Formik initialValues={{ privacy: false }} onSubmit={handleSubmit}>
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box
                     sx={{
                        py: 2,
                        bgcolor: "#152c44",
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                  >
                     <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>Manifiesto</Typography>
                  </Box>
                  <Stack
                     spacing={2}
                     sx={{
                        bgcolor: "background.paper",
                        py: 3,
                        px: 6,
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15,
                     }}
                  >
                     <Typography sx={{ textAlign: "justify" }}>
                        Consiento de manera expresa e informada que se recabará mi identificación oficial a través de
                        los procesos definidos para la validación de mi identidad, así como otros documentos
                        correspondientes para continuar con este acto, siendo yo el titular de dichos datos personales
                        sensibles conforme a lo que establece la legislación aplicable.
                     </Typography>
                     <Typography sx={{ textAlign: "justify" }}>
                        Adjunto evidencia de mi identificación, aceptando los términos de tratamiento de datos
                        personales que tiene la organización.
                     </Typography>
                     {acceptWarning && (
                        <Typography sx={{ textAlign: "justify", fontSize: 12, color: "red" }}>
                           Favor de aceptar el aviso de privacidad
                        </Typography>
                     )}
                     <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <InputCheckBox
                           label={"Acepto aviso de privacidad"}
                           id={"privacy"}
                           name={"privacy"}
                           size="medium"
                        />
                        <Button
                           onClick={() => {
                              formProps.submitForm();
                           }}
                           sx={{ bgcolor: "#2D4357", color: "white", borderRadius: 1, px: 5, py: 0.4 }}
                           variant="contained"
                        >
                           {!loadingButton ? `Continuar` : <CircularProgress sx={{ color: "white" }} size={24} />}
                        </Button>
                     </Box>
                  </Stack>
               </Form>
            )}
         </Formik>
      </Stack>
   );
};

export default ManifestoModalLegalExternal;
