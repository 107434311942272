import { Box, Button, Drawer, Tab, Tabs, Typography } from "@mui/material";
import { TabPanel } from "../ClientInfoScreenNew";
import { AddCircle } from "@mui/icons-material";
import { AdditionalContacts } from "../../../components/MiLecosy/AdditionalContacts";
import { InvoiceClientInfoComponent } from "../../../components/MiLecosy/InvoiceClientInfoComponent";
import ClientStatusAccount from "../../../components/MiLecosy/ClientStatusAccount";
import { a11yProps } from "../ServiceInfoScreen";
import { useContext, useState } from "react";
import { AddAditionalsContacts } from "../../../components/Drawers/AddAditionalsContacts";
import { CreateAditionalContact } from "../../../lib/usersBEClient";
import { SnackBarContext } from "../../../context/snackBarContext";
import { UserContext } from "../../../context/userContext";
import { Navigate } from "react-router-dom";
import { CompanyBranchesTable } from "../../../components/ClientInfo/CompanyBranchesTable";
import { GroupInformationComponent } from "../../../components/MiLecosy/Group/GroupInformationComponent";

export const CompanyMainScreen = () => {
   const { companySelected, isLoadingCompany, isImplementationUser } = useContext(UserContext);
   const [value, setValue] = useState(0);
   const [openDrawer, setOpenDrawer] = useState(false);
   const [seed, setSeed] = useState(0);
   const { mutate, isLoading: isLoadingAdditionalContact } = CreateAditionalContact(
      !isLoadingCompany ? companySelected?._id : ""
   );
   const { showSnackBar } = useContext(SnackBarContext);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   const createAditionalContact = (values) => {
      mutate(
         {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
         },
         {
            onError: (error: any) => {
               showSnackBar("Error al agregar contacto a la empresa.", true);
            },
            onSuccess: async () => {
               showSnackBar("El contacto fue agregado correctamente.", false);
               setSeed(seed + 1);
               setOpenDrawer(false);
            },
         }
      );
   };

   return (
      <>
         <Box
            sx={{
               flex: 1,
               display: "flex",
               flexDirection: "column",
            }}
         >
            <Box
               display="flex"
               sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  boxShadow: 2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
               }}
               bgcolor="white"
            >
               <Tabs value={value} onChange={handleChange}>
                  <Tab sx={{ ml: 2, fontSize: 14, fontWeight: 600 }} label="Información general" {...a11yProps(0)} />
                  <Tab sx={{ ml: 2, fontSize: 14, fontWeight: 600 }} label="Facturas" {...a11yProps(1)} />
               </Tabs>
            </Box>
            <Box sx={{ flex: 1 }}>
               {value === 0 ? (
                  <TabPanel value={value} index={0}>
                     <GroupInformationComponent companyData={companySelected} />
                     <CompanyBranchesTable />
                     <Box key={seed} sx={{ bgcolor: "white", boxShadow: 2, mt: 2, borderRadius: 1 }}>
                        <Box
                           sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                           }}
                        >
                           <Tabs value={0} onChange={handleChange} aria-label="basic tabs example">
                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                 }}
                              >
                                 <Tab
                                    sx={{ ml: 2, fontSize: 14, fontWeight: 600 }}
                                    label="Contactos adicionales"
                                    {...a11yProps(0)}
                                 />
                              </Box>
                           </Tabs>
                           <Box sx={{ mr: 3 }}>
                              <Button
                                 variant="contained"
                                 onClick={() => setOpenDrawer(true)}
                                 sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
                              >
                                 <AddCircle />
                                 <Typography fontSize={14}>Añadir</Typography>
                              </Button>
                           </Box>
                        </Box>
                        <AdditionalContacts />
                     </Box>
                  </TabPanel>
               ) : (
                  <TabPanel value={value} index={1}>
                     <InvoiceClientInfoComponent invoiceData={companySelected.invoice_details} />
                     <ClientStatusAccount company={companySelected ? companySelected : null} />
                  </TabPanel>
               )}
            </Box>
         </Box>
         <Drawer
            anchor={"right"}
            open={openDrawer}
            hideBackdrop={true}
            onClose={() => setOpenDrawer(false)}
            sx={{ maxWidth: 450 }}
         >
            <AddAditionalsContacts
               setDrawerOpen={setOpenDrawer}
               open={openDrawer}
               onConfirm={createAditionalContact}
               isLoading={isLoadingAdditionalContact}
            />
         </Drawer>
      </>
   );
};
