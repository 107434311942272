import { Box, Button, Stack, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FilterTabsComponent } from "./SubComponents/FilterTabsComponent";
import { AssignedTaskComponent } from "./AssignedTaskComponent";
import { TaskModalComponent } from "./SubComponents/Modals/TaskModalComponent";
import { KanbanBoardComponent } from "./KanbanBoardComponent";
import {
   getCommitmentsByGovernanceBody,
   getCommitmentsByManyGovernancedAndUserId,
   getCommitmentsByUserIdAndCompanyId,
   getTagById,
   getTasksByUserId,
} from "../../../lib/gobCorpBEClient";
import { UserContext } from "../../../context/userContext";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import { TextFilterComponent } from "./SubComponents/TextFilterComponent";
import { getNameUserData, getUrlS3, getUserProfilePics } from "../../../lib/usersBEClient";
import { useParams } from "react-router-dom";
import { GovernanceTheSequelContext } from "../../../context/governanceContext/governanceTheSequelContext";

const CustomTabPanel = ({ children, value, index, ...other }) => (
   <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
   >
      {value === index && children}
   </div>
);

const useGetTasksByUser = () => {
   const { refetchTasks, companySelected } = useContext(GovernanceContext);
   const { myGovBody } = useContext(GovernanceTheSequelContext);
   const { isLoadingUser, user } = useContext(UserContext);
   const [tasks, setTasks] = useState([]);
   const [commitments, setCommitments] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const { id: governanceId } = useParams();

   const fetchTasksAndCommitments = useCallback(async () => {
      if (isLoadingUser || !companySelected || !user) return;
      setIsLoading(true);
      try {
         const fetchedTasks = governanceId ? [] : await getTasksByUserId(user.id, companySelected);
         const fetchedCommitments = governanceId
            ? await getCommitmentsByGovernanceBody(governanceId)
            : await getCommitmentsByManyGovernancedAndUserId(
                 myGovBody.map((g) => g.bodiesData._id),
                 user.id
              );
         setTasks(fetchedTasks);
         setCommitments(fetchedCommitments);
      } catch (error) {
         console.error("Error fetching tasks and commitments:", error);
      } finally {
         setIsLoading(false);
      }
   }, [governanceId, companySelected, user, isLoadingUser, myGovBody]);

   useEffect(() => {
      fetchTasksAndCommitments();
   }, [fetchTasksAndCommitments, refetchTasks]);

   return { tasks, commitments, isLoading };
};

const useGetFilteredTasks = (textFiltered, tasks) => {
   const filteredTasks = useMemo(
      () =>
         textFiltered.trim()
            ? tasks.filter((task) =>
                 [task?.title, task?.description].some((field) =>
                    field?.toLowerCase()?.includes(textFiltered.toLowerCase())
                 )
              )
            : tasks,
      [textFiltered, tasks]
   );

   return { filteredTasks };
};

const useGetFilteredTasksByCommitment = (governanceSelected, allCommitments, alltasks) => {
   const { id: governanceId, companyId } = useParams();
   const { consultiveGroup, selectedGovernance } = useContext(GovernanceContext);
   const isConsultiveGroup =
      governanceId &&
      consultiveGroup?._id === companyId &&
      selectedGovernance?.title !== "Asamblea de accionistas" &&
      selectedGovernance?.company === consultiveGroup?._id;
   const filteredTaskValue = useMemo(() => {
      if (!isConsultiveGroup) {
         if (governanceSelected.title === "Mis actividades") return alltasks;
         if (selectedGovernance?.title === "PANEL DE USUARIO") {
            return allCommitments.filter((c) => c.session?.governance._id.includes(governanceSelected?._id));
         }
         return allCommitments;
      }
      const filteredByGovernance = allCommitments.filter((c) => c.session?.governance._id.includes(governanceId));

      return filteredByGovernance.filter((c) => c?.companies?.includes(governanceSelected?._id));
   }, [isConsultiveGroup, governanceSelected, allCommitments, alltasks, governanceId]);

   return filteredTaskValue;
};

const useUserData = (tasks) => {
   const [processedData, setProcessedUsers] = useState([]);
   const { user } = useContext(UserContext);
   const [isLoadingUsers, setIsLoadingUsers] = useState(false);

   const uniqueTaskUsers: any = useMemo(() => {
      if (!tasks?.length) return [];
      return [
         ...new Set(
            tasks
               .flatMap((task) => [...task.users, ...(task.comments?.map((comment) => comment.user) || []), task.owner])
               .filter(Boolean)
         ),
      ];
   }, [JSON.stringify(tasks)]);

   const fetchUserData = useCallback(async () => {
      if (!uniqueTaskUsers.length) {
         setProcessedUsers([]);
         setIsLoadingUsers(false);
         return;
      }
      setIsLoadingUsers(true);
      const validUserIds = uniqueTaskUsers.filter((id) => !id.includes("-"));
      if (!uniqueTaskUsers.includes(user.id)) validUserIds.push(user.id);
      try {
         const [userData, profilePicsResponse] = await Promise.all([
            getNameUserData(validUserIds),
            getUserProfilePics(uniqueTaskUsers),
         ]);
         const newUserList = userData.map((user) => ({
            user: `${user.firstName} ${user.lastName}`,
            id: user._id,
            profilePicture: profilePicsResponse.find((u) => u.user === user._id)?.url,
         }));
         setProcessedUsers(newUserList);
      } catch (error) {
         setProcessedUsers([]);
      } finally {
         setIsLoadingUsers(false);
      }
   }, [uniqueTaskUsers, user.id]);

   useEffect(() => {
      fetchUserData();
   }, [fetchUserData]);

   return { processedData, isLoadingUsers };
};

export const AgreementsAndCommitmentsComponent = () => {
   const [value, setValue] = useState(0);
   const [open, setOpen] = useState<boolean>(false);
   const [filterValue, setFilterValue] = useState("");
   const [filterText, setFilterText] = useState("");
   const { tasks, commitments } = useGetTasksByUser();
   const filteredTaskByGovernance = useGetFilteredTasksByCommitment(filterValue, commitments, tasks);
   const { filteredTasks } = useGetFilteredTasks(filterText, filteredTaskByGovernance);
   const { processedData: allUsers, isLoadingUsers } = useUserData([...tasks, ...commitments]);
   const { id: governanceId, companyId } = useParams();
   const { consultiveGroup, selectedGovernance } = useContext(GovernanceContext);

   const isConsultiveGroup =
      governanceId &&
      consultiveGroup?._id === companyId &&
      selectedGovernance?.title !== "Asamblea de accionistas" &&
      selectedGovernance?.company === consultiveGroup?._id;

   const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

   return (
      <>
         <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
            <TextFilterComponent setFilterText={setFilterText} />
            <Tabs value={value} onChange={handleChange}>
               <Tab
                  label={
                     <Stack direction={"row"} sx={{ alignItems: "center", columnGap: 0.5, color: "#64748B" }}>
                        <FormatListBulletedIcon sx={{ fontSize: 15 }} />
                        <Typography>LISTA</Typography>
                     </Stack>
                  }
               />
               <Tab
                  label={
                     <Stack direction={"row"} sx={{ alignItems: "center", columnGap: 0.5, color: "#64748B" }}>
                        <ViewKanbanIcon sx={{ fontSize: 15 }} />
                        <Typography>TABLERO</Typography>
                     </Stack>
                  }
               />
            </Tabs>
            {!governanceId && (
               <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                     setOpen(true);
                  }}
                  sx={{ flex: 1 }}
               >
                  Agregar tarea
               </Button>
            )}
         </Box>
         <Box
            sx={{
               display: "flex",
               my: 2,
               columnGap: 2,
            }}
         >
            {(!governanceId || isConsultiveGroup) && <FilterTabsComponent setFilterValue={setFilterValue} />}
            <Box sx={{ flex: 1 }}>
               <CustomTabPanel value={value} index={0}>
                  <AssignedTaskComponent tasks={filteredTasks} allUsers={allUsers} isLoadingUsers={isLoadingUsers} />
               </CustomTabPanel>
               <CustomTabPanel value={value} index={1}>
                  <KanbanBoardComponent tasks={filteredTasks} allUsers={allUsers} isLoadingUsers={isLoadingUsers} />
               </CustomTabPanel>
            </Box>
         </Box>
         <TaskModalComponent open={open} setOpen={setOpen} />
      </>
   );
};
