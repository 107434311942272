import { Box, Button, Stack, Typography } from "@mui/material";
import { Formik, FormikProps } from "formik";
import React, { useContext, useState } from "react";
import { Form } from "react-router-dom";
import { ExternalGovernanceSessionContext } from "../../../../context/governanceContext/externalSessionContext";
import { legalario } from "../../../../lib/Legalario/legalario";
import { base64ToBlob, extractBase64Data, getMimeType } from "../../../../const/globalConst";
import { uploadFileToS3, UseGetPresignedURLUploadExternal } from "../../../../lib/s3Client";
import { InputCheckBox } from "../../../Inputs/InputCheckBox";

const SignModalLegalExternal = () => {
   const [alertFrom, setAlertFrom] = useState(false);
   const { socket, valuesFromBill, externalSessionToken, userId, session, fetchSignArrayList, setOpenModalToSign } =
      useContext(ExternalGovernanceSessionContext);

   const userCommitments = valuesFromBill["commitmentsArray"].filter((commitments) =>
      commitments.users.some((userInCommitment) => userInCommitment._id === userId)
   );

   const commitments = userCommitments.map((commitment) => commitment.activity);

   const handleSubmit = async (values) => {
      let allCommitmentsAccepted = true;
      commitments.forEach((commitment, index) => {
         if (values["commitmentSign" + index] === false) {
            allCommitmentsAccepted = false;
         }
      });
      if (!allCommitmentsAccepted) setAlertFrom(true);
      else {
         const legalarioSDK = legalario();

         const signerKeyId = Object.keys(valuesFromBill).find((key) => key === `${userId}-signerId`);
         const signerId = valuesFromBill[signerKeyId];
         legalarioSDK.signature({
            signerId: signerId,
            callbacks: {
               onFinish: async (data) => {
                  const mimeType = getMimeType(data.documents[0]?.base64);
                  const base64Data = extractBase64Data(data.documents[0]?.base64);
                  const blob = base64ToBlob(base64Data, mimeType);

                  const urlReceipt1 = await UseGetPresignedURLUploadExternal({
                     bucket: "files-lecosy",
                     folder: `gc/companies/${session.company}/governing-body/sessions/${session._id}`,
                     name: `sign-${userId}.png`,
                     token: externalSessionToken,
                  });

                  await uploadFileToS3(urlReceipt1.data, blob);
                  const arrayList = await fetchSignArrayList();
                  socket.emit("verify-sign", { sessionId: session._id, signArray: arrayList });
                  setOpenModalToSign(false);
               },
            },
         });
      }
   };

   let initialValues = {};

   commitments.forEach((commitment, index) => {
      initialValues = { ...initialValues, ["commitmentSign" + index]: false };
   });

   return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
         {(formProps: FormikProps<any>) => (
            <Form>
               <Box
                  sx={{
                     py: 1,
                     bgcolor: "#152c44",
                     borderTopLeftRadius: 15,
                     borderTopRightRadius: 15,
                     display: "flex",
                     justifyContent: "space-between",
                     alignItems: "center",
                     minWidth: 400,
                  }}
               >
                  <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>Firmar minuta</Typography>
               </Box>
               <Stack
                  spacing={3}
                  sx={{
                     bgcolor: "background.paper",
                     py: 3,
                     px: 6,
                     borderBottomLeftRadius: 15,
                     borderBottomRightRadius: 15,
                  }}
               >
                  <Stack spacing={1}>
                     {commitments?.length > 0 && (
                        <>
                           <Stack>
                              <Box sx={{ bgcolor: "#EBEBEB", py: 1 }}>
                                 <Typography sx={{ fontWeight: 600, textAlign: "center" }}>
                                    Acuerdos y compromisos establecidos
                                 </Typography>
                              </Box>
                              <Stack spacing={1} sx={{ border: 1, borderColor: "#EBEBEB", py: 1 }}>
                                 {commitments.map((commitment, index) => (
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                       <InputCheckBox
                                          id={"commitmentSign" + index}
                                          name={"commitmentSign" + index}
                                          label={""}
                                          sx={{ p: 0, ml: 3 }}
                                          size="medium"
                                       />
                                       <Typography>{commitment}</Typography>
                                    </Box>
                                 ))}
                              </Stack>
                           </Stack>
                           {alertFrom && (
                              <Typography sx={{ color: "red", fontSize: 12 }}>
                                 Favor de aceptar todos los compromisos y adjuntar la firma
                              </Typography>
                           )}
                        </>
                     )}
                     <Button
                        type="submit"
                        variant="contained"
                        sx={{ bgcolor: "#2D4357", color: "white", alignSelf: "center" }}
                        onClick={() => formProps.submitForm()}
                     >
                        Firma minuta
                     </Button>
                  </Stack>
               </Stack>
            </Form>
         )}
      </Formik>
   );
};

export default SignModalLegalExternal;
