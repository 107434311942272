import { useContext, useState, useMemo, useEffect } from "react";
import { getUrlS3GC, updateBeneficiaryFile } from "../../../lib/usersBEClient";
import { HeadCell, TableComponent } from "../../TableComponent";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FileMenu } from "./SubComponents/FileMenu";
import { pdfjs } from "react-pdf";
import { FileVisualizer } from "./SubComponents/FileVisualizer";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { MoreVert } from "@mui/icons-material";
import { openProtectedBlog } from "../DashboardScreen/SubComponent/InsightsComponent";
import { Menu, MenuItem } from "@mui/material";
import { FilesContext } from "../../../context/governanceContext/filesContext";
import { ConfirmationModal } from "../../ConfirmationModal";

interface IMyFiles {
   files: any[];
   isLoading: boolean;
   reload: boolean;
   setReload: Function;
   move?: boolean;
   setSelectedFolder?: Function;
   beneficiary?: boolean;
}

export const MyFiles = (props: IMyFiles) => {
   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
   const open = Boolean(anchorEl);
   const [selected, setSelected] = useState(null);
   const [openPDF, setOpenPDF] = useState(false);
   const [openImage, setOpenImage] = useState(false);
   const [openVideo, setOpenVideo] = useState(false);
   const [openOther, setOpenOther] = useState(false);
   const [openZip, setOpenZip] = useState(false);
   const [pdfUrl, setPdfUrl] = useState("");
   const [fileNames, setFileNames] = useState("");
   const [audio, setAudio] = useState(false);
   const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
   const [verify, setVerify] = useState(false);
   const { companySelected, setDocumentSelected } = useContext(GovernanceContext);
   const { setDocumentSelected: setDocumentFromLecosy } = useContext(FilesContext);
   const { id } = useParams();
   const location = useLocation();

   const lastPathSegment = useMemo(() => {
      return location.pathname.split("/").pop() || "";
   }, [location.pathname]);

   const getFileSignedUrl = async (folder: string, fileKey: string, fileType: string) => {
      const imagesUrlResponse = await getUrlS3GC("files-lecosy", { folder: folder }, fileKey);
      if (fileType === "application/pdf") {
         setOpenPDF(true);
      } else if (fileType.startsWith("image")) {
         setOpenImage(true);
      } else if (fileType.startsWith("video")) {
         setOpenVideo(true);
         setAudio(false);
      } else if (fileType.startsWith("audio")) {
         setOpenVideo(true);
         setAudio(true);
      } else if (fileType.startsWith("application/zip")) {
         setOpenZip(true);
      } else if (fileType !== "application/url") {
         setOpenOther(true);
      }
      setFileNames(fileKey);
      setPdfUrl(imagesUrlResponse);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const usersColumns: HeadCell[] = [
      { field: "name", headerName: "Nombre del archivo", type: "string", align: "left" },
      { field: "updatedAt", headerName: "Fecha de modificación", type: "string", align: "center" },
      { field: "type", headerName: "Tipo de archivo", type: "string", align: "center" },
      { field: "owner", headerName: "Propietario", type: "string", align: "center" },
      { field: "metadata", headerName: "Metadatos", type: "chips" },
      { field: "size", headerName: "Tamaño", type: "string", align: "center" },
      {
         field: "action",
         headerName: "Acciones",
         type: "button",
         align: "center",
         icon: <MoreVert />,
         onClick: (e, row) => {
            setAnchorEl(e.currentTarget);
            setSelected(row);
         },
      },
   ];

   const moveColumns: HeadCell[] = [
      { field: "name", headerName: "Nombre del archivo", type: "string", align: "left" },
      { field: "updatedAt", headerName: "Fecha de modificación", type: "string" },
      { field: "type", headerName: "Tipo de archivo", type: "string" },
      { field: "owner", headerName: "Propietario", type: "string" },
   ];

   const handleVerifyBeneficiaryFile = async () => {
      await updateBeneficiaryFile(selected._id, verify);
      props.setReload(!props.reload);
      handleClose();
      setOpenConfirmationModal(false);
   };

   const navigate = useNavigate();
   return (
      <>
         <TableComponent
            headerColor
            defaultColumnToOrder=""
            defaultOrder="asc"
            defaultRowsPerPage={props.move ? 5 : 10}
            rowsPerPageOptions={props.move ? [5] : [5, 10, 20, 50]}
            headers={props.move ? moveColumns : usersColumns}
            rows={props.files}
            onClick={(_e, r) => {
               if (props.beneficiary && !r.fileDirection) {
                  setDocumentFromLecosy(r);
                  navigate(`${location.pathname.replace(/[^/]+$/, r._id)}`);
               } else if (id && !r.fileDirection) {
                  setDocumentSelected(r);
                  navigate(`/gobierno-corporativo/${companySelected}/${id}/documentos/folder/${r._id}`);
               } else if (r.toVerified) {
                  setDocumentSelected(r);
                  navigate(
                     `/gobierno-corporativo/${companySelected}/mi-cuenta/archivos/folder/${lastPathSegment}/${r.toVerified}`
                  );
               } else if (props.move) {
                  if (r.type === "carpeta") {
                     setDocumentSelected(r);
                     props.setSelectedFolder((prev) => {
                        const newValue = [...prev, r];
                        return newValue;
                     });
                  }
                  return;
               } else {
                  let type;
                  console.log(r);
                  if (r.type.includes("/")) type = r.type.split("/")[1];
                  else type = r.type;
                  if (type.toUpperCase() === r.originalType) {
                     type = type.toUpperCase();
                  }
                  if (r.type.endsWith("mpeg")) {
                     type = "mp3";
                  }
                  if (r.type.endsWith("jpeg") && r.originalType !== "jpeg") {
                     type = "jpg";
                  }
                  if (r.type.endsWith("vnd.ms-excel")) {
                     type = "xls";
                  }
                  if (r.type.endsWith("svg+xml")) {
                     type = "svg";
                  }
                  if (r.type.endsWith("zip")) {
                     type = "zip";
                  }
                  if (r.type.endsWith("url")) {
                     if (r.metadata.includes("Formularios y herramientas"))
                        window.open(openProtectedBlog(r.fileDirection));
                     else window.open(r.fileDirection);
                  }
                  setSelected(r);
                  if (r.fileDirection) {
                     getFileSignedUrl(r.fileDirection, `${r.originalName}.${type}`, r.type);
                  } else {
                     setDocumentSelected(r);
                     navigate(`/gobierno-corporativo/${companySelected}/mi-cuenta/archivos/folder/${r._id}`);
                  }
               }
            }}
            loader={props.isLoading}
            emptyDataText="No existen archivos en esta carpeta"
            cursor={"default"}
         />
         {props.beneficiary && selected?.type !== "carpeta" && selected?.beneficiary ? (
            <Menu
               id="demo-positioned-menu"
               aria-labelledby="demo-positioned-button"
               anchorEl={anchorEl}
               open={open}
               onClose={handleClose}
               anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
               }}
               transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
               }}
            >
               <MenuItem
                  onClick={() => {
                     setVerify(true);
                     setOpenConfirmationModal(true);
                  }}
               >
                  Aceptar
               </MenuItem>
               <MenuItem
                  onClick={() => {
                     setVerify(false);
                     setOpenConfirmationModal(true);
                  }}
               >
                  Rechazar
               </MenuItem>
            </Menu>
         ) : props.beneficiary && selected?.originalName === "Control" ? null : (
            <FileMenu
               anchorEl={anchorEl}
               open={open}
               setOpenModal={null}
               handleClose={handleClose}
               selectedFile={selected}
               setReload={props.setReload}
               reload={props.reload}
            />
         )}
         <ConfirmationModal
            open={openConfirmationModal}
            setOpen={setOpenConfirmationModal}
            onConfirm={handleVerifyBeneficiaryFile}
            generic
         />
         <FileVisualizer
            audio={audio}
            fileNames={fileNames}
            pdfUrl={pdfUrl}
            setOpenImage={setOpenImage}
            setOpenOther={setOpenOther}
            setOpenPDF={setOpenPDF}
            setOpenZip={setOpenZip}
            setOpenVideo={setOpenVideo}
            openImage={openImage}
            openOther={openOther}
            openPDF={openPDF}
            openZip={openZip}
            openVideo={openVideo}
            selectedFile={selected}
         />
      </>
   );
};
