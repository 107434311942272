import * as yup from "yup";
import { textValidation } from "../inputSchemas";
// import { expression, longTextValidation, mediumTextValidation, textValidation } from "../inputSchemas";

export const employerRegistrationSchema = yup.object().shape({
   // firstName: yup.string().required("Mensaje de error").
   register: textValidation,
   identificationCard: textValidation,
   registerNumber: textValidation,
   division: textValidation,
   group: textValidation,
   fraction: textValidation,
   class: textValidation,
   address: textValidation,
   placeAndDate: textValidation,
   denomination: textValidation,
   delegation: textValidation,
   subdelegation: textValidation,
});
